<template>
    <div style="background:#E0E0E0;display: contents!important;">

        <div style=" justify-content: space-around" class="">
            <v-row style="background:#F7F7F7;box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 0, 0, 0.4);">
                <v-col lg="6" md="6">
                  <h5 class="text-h5 font-weight-bold text-typo mb-0 mt-1 " style="padding-left:25px">
                    {{ company.year }} {{company.make}} {{company.model}} 
                  </h5>
                </v-col>
                <v-col lg="6" md="6" class="text-right">
                
                                   <v-btn
                                    color="#4CAF50"
                                    outlined
                                    @click="saveProfile(2)"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      px-8
                                      mr-2
                                      ms-auto
                                    "
                                    
                                  >
                                   Save and Close
                                  </v-btn>
                                  <v-btn
                                    color="#4CAF50"
                                    @click="saveProfile(1)"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Save
                                  </v-btn>
                                    <v-btn
                                    color="blue"
                                    outlined
                                    @click="$emit('closeDetailmodal')"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      px-8
                                      mr-2
                                      ms-auto
                                    "
                                    
                                  >
                                   Close
                                  </v-btn>
                                  <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                                color="blue"
                                                outlined
                                                v-bind="attrs"
                                                v-on="on"
                                                width="30px"
                                                class="
                                                  font-weight-bolder
                                                  btn-default
                                                  px-8
                                                  mr-2
                                                  ms-auto
                                                "
                                                
                                              >
                                              <v-icon class="material-icons-round" style="font-size: 26px;">
                                            more_vert
                                          </v-icon>
                                              </v-btn>
                                        </template>
                                        <v-list>
                                          <v-list-item
                                            @click="deleteItemInventory"
                                          >
                                            <v-list-item-title>Mark as Deleted</v-list-item-title>
                                          </v-list-item>
                                        </v-list>
                                      </v-menu>
                                  
                                  
                              </v-col>
                </v-row>
              <v-row>
                <v-col lg="4" md="4" class="mt-4" style="background:#F7F7F7;margin-bottom: 12px;">
                  <div class="text-center mt-4">
                  <img
                    v-if="company.images.length > 0"
                    class="w-50 border-radius-lg shadow-lg mx-auto "
                    :src="company.images[0].image"
                  >
                  </img>
                  <img v-else :class="this.$vuetify.breakpoint.xs ? '':'image-logo2 w-50 mb-5 border-radius-lg shadow-lg mx-auto'" src="@/assets/img/no-vehicle.jpg">
                  </div>
                  <v-row class="mt-3 ml-3">
                  <v-col cols="6">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/sedan.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.body_style ? company.body_style : 'N/A'}}</span>
                    </v-col>
                    <v-col cols="6" >
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/car-engine.png"
                        >
                        </img><span class="text-typo font-weight-bold word-break-text" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.engine ? company.engine : 'N/A'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/manual-transmission.png"
                        >
                        </img><span class="text-typo font-weight-bold word-break-text" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.transmission ? company.transmission : 'N/A'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/car.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.wheel_drive ? company.wheel_drive : 'N/A'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/piston.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.cyclider ? company.cyclider+' Cylinder' : '0 Cylinder'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/car-door.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.doors ? company.doors+ ' Doors' : '0 Doors'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/gas-station.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.fuel_type ? company.fuel_type : 'N/A'}}</span>
                    </v-col>
                    <v-col cols="6" style="margin-top: -10px">
                        <img
                          style="height: 20px;margin-right: 20px;"
                          src="@/assets/img/icons/engine.png"
                        >
                        </img><span class="text-typo font-weight-bold" style="margin-left: 3px;padding-top: 0px !important;position: absolute;">{{company.engine_power ? company.engine_power + ' Horse Power': '0 Horse Power'}}</span>
                    </v-col>
                    
                

                    
                  </v-row>
                  
                  <div class="">
                  <Photoswipe v-if="company.images.length > 0">
                      <draggable style="padding: 35px" v-model="company.images" class="v-tabs__container row mt-2" v-if="company.images " @change="sortChange">
                      <img
                      v-for="(file,n ) in company.images"
                        class="me-4 mb-2"
                        style="object-fit: cover;"
                        :src="file.image"
                        v-if="n < 8"
                        @click="openGallery(file)"
                        
                      />
                     
                      </draggable>
                     
                                  
                    </Photoswipe>
                    </div>
                </v-col>
                <!-- <v-col lg="6" class="mx-auto">
                  <h3
                    class="text-h3 text-typo font-weight-bold mt-lg-0 mt-4 "
                  >
                    {{company.make}} {{company.model}} {{ company.year }}
                  </h3>
                  <br />
                  <h6 class="text-h6 text-typo mb-0 font-weight-bold">
                    Price
                  </h6>
                  <h5 class="text-h4 text-typo mb-1 font-weight-bold">
                    {{currencyFormat(company.amount)}}
                  </h5>
                  <v-btn
                    elevation="0"
                    small
                    :ripple="false"
                    height="21"
                    class="
                      border-radius-md
                      font-weight-bolder
                      px-3
                      py-3
                      badge-font-size
                      ms-auto
                      text-success
                    "
                    color="#bce2be"
                    >{{ company.category_cow ? 'Car Of The Week' : company.category_cl ? 'Clearance' : 'New' }}</v-btn
                  >
                  <br />
                  <br />
                  <label class="text-sm text-body ms-1 mt-6">Description</label>
                  <ul class="text-body font-weight-light mt-2">
                    <li><strong style="color:black">VIN:</strong> {{company.vin}}</li>
                 
                    <li>
                      <strong style="color:black">Odometer:</strong> {{company.odometer}} miles
                    </li>
                    <li>
                      <strong style="color:black">Trasmission:</strong> {{company.transmission}}
                    </li>
                    <li><strong style="color:black">Interior Color:</strong> {{company.interior_color}}</li>
                    <li><strong style="color:black">Exterior Color:</strong> {{company.exterior_color}}</li>
                    <li><strong style="color:black">Dealership:</strong> Round Tables Auto Sales</li>
                    <li>
                      <strong style="color:black">Location:</strong> {{company.address}} {{ company.city }} {{ company.state }} {{ company.zip }}
                    </li>
                  </ul>
                  <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogEditDetail = true"
                                    class="
                                      btn-default
                                      mt-5
                                    "
                                    
                                  >
                                   Edit Details
                                  </v-btn>
                                  <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetail = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Add Images
                                  </v-btn>
                                  <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetailURL = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Add Images From URL
                                  </v-btn>
                                  <v-btn
                                    color="red"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetailDelete = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Mass Delete Images
                                  </v-btn>
                                  <v-btn
                                    color="blue"
                                    large
                                    outlined
                                    :loading="downloadLoader"
                                    style="text-transform: none;"
                                    @click="downloadImages"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Download Images
                                  </v-btn>
                </v-col> -->
                <v-col cols="8"  class="mt-1" >
                <v-tabs
                        style="background:#F7F7F7"  
                          v-model="tab"
                          color="green"
                        icons-and-text
                      >
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab :key="'info'" href="#tab-info" >
                          Vehicle Info
                          <v-icon></v-icon>
                        </v-tab>
                        <v-tab :key="'media'" href="#tab-media" >
                          Media
                          <v-icon></v-icon>
                        </v-tab>
                        <v-tab :key="'logs'" href="#tab-logs" >
                          Logs
                          <v-icon></v-icon>
                        </v-tab>
                  </v-tabs>
                   <v-tabs-items v-model="tab" style="margin-bottom:0px!important;height: auto!important;">
                        <v-tab-item
                          :key="'info'"
                          style="height: 100%!important;background:#F7F7F7;"
                          :value="'tab-info'"
                        >
                        <v-row class="ml-3 mt-2" style="padding: 10px;">
                        <v-col cols="12" md="4" >
                          <v-col cols="12" md="12">
                            <h5 class="text-h5 font-weight-bold text-typo pseudo_border">Summary</h5>

                          </v-col>
                          <div style="height: 600px;overflow-y: auto;">
                          
                   
                      <v-col cols="12" md="12" style="margin-top: -30px;" >
                        <vuetify-google-autocomplete
                        id="map"
                        v-model="company.address"
                        placeholder="Enter vehicle location"
                        class="font-size-input input-style"
                        v-on:placechanged="getAddressData"
                        country="us"
                    ></vuetify-google-autocomplete>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="City"
                                v-model="company.city"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                       <v-select
                                color="#e91e63"
                                 item-text="name"
                                  item-value="abbreviation"
                                :items="stateOptions"
                                label="State"
                                v-model="company.state"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Zip"
                                v-model="company.zip"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Phone"
                                v-model="company.phone"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                     
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="VIN"
                                v-model="company.vin"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Make"
                                v-model="company.make"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Model"
                                v-model="company.model"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Year"
                                v-model="company.year"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Trim"
                                v-model="company.trim"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Odometer"
                                v-model="company.odometer"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Mileage(MPG)"
                                v-model="company.mileage"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Exterior Color"
                                v-model="company.exterior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Interior Color"
                                v-model="company.interior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                        <v-col cols="12" md="12" style="margin-top: -30px;">
                      
                            <v-select
                            color="#e91e63"
                            :items="[
                              'Autometic',  // Standard fuel for most cars
                              'Manual',    // Used in many trucks and some cars
                            
                            ]"
                            label="Trasmission"
                            v-model="company.transmission"
                            class="font-size-input input-style"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Seats"
                                v-model="company.seats"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
  
                            <v-select
                            color="#e91e63"
                            :items="[
                              '4',  // Standard fuel for most cars
                              '2',    // Used in many trucks and some cars
                            
                            ]"
                            label="Doors"
                            v-model="company.doors"
                            class="font-size-input input-style"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">

                            <v-select
                                color="#e91e63"
                                :items="[
                                  'Gas',  // Standard fuel for most cars
                                  'Diesel',    // Used in many trucks and some cars
                                  'Electric',  // Battery-powered vehicles
                                  'Hybrid',    // Combination of gasoline and electric
                                  'E85',       // Ethanol blend (85% ethanol, 15% gasoline)
                                  'CNG',       // Compressed Natural Gas
                                  'LPG',       // Liquefied Petroleum Gas (Propane)
                                  'Hydrogen'   // Fuel cell vehicles
                                ]"
                                label="Fuel Type"
                                v-model="company.fuel_type"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Engine"
                                v-model="company.engine"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Engine Power"
                                v-model="company.engine_power"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Cylinders"
                                v-model="company.cyclider"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Wheel Drive"
                                v-model="company.wheel_drive"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                     
                       <v-col cols="12" md="12" style="margin-top: -30px;">
                        <v-select
                                color="#e91e63"
                                :items="['Coupe','Convertible','Sedan','Hybrid/Electric','Crossover/SUV','Truck','4WD/AWD','Van','Wagon']"
                                label="Body Style"
                                v-model="company.body_style"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                      </div>
                      </v-col>

                          <v-col cols="4" md="4">
                            <h5 class="text-h5 font-weight-bold text-typo pseudo_border mt-4">Price Info</h5>
                            <v-col cols="12" md="12" class="mt-2">
                        <v-text-field
                                color="#e91e63"
                                
                                label="Rental Price"
                                prefix="$"
                                v-model="company.rent_price_per_day"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                   
                          </v-col>
                          <v-col cols="4" md="4">
                            <h5 class="text-h5 font-weight-bold text-typo pseudo_border mt-4">More Info</h5>
                            <v-col cols="12" md="12" class="mt-2">
                            Listed At <span class="float-right mr-2">{{dateFormat(company.created_at)}}</span>
                            </v-col>
                            <v-col cols="12" md="12" class="mt-2">
                              Updated At <span class="float-right mr-2">{{dateFormat(company.updated_at)}}</span>
                            </v-col>
                             <v-col cols="12" md="12" >
                            <v-divider></v-divider>
                            </v-col>
                            <v-row class="text-center mt-5">
                             <v-col md="12" style="border-right: 1px solid;border-color: rgba(0,0,0,.12);">
                                    <p class="font-weight-bold">Rental Price</p><p class="text-h5 font-weight-bold" style="color: #1867c0">{{currencyFormat(company.rent_price_per_day)}}</p>
                             </v-col>
                             
                             </v-row>
                          </v-col>
                        </v-row>
                        </v-tab-item>
                         <v-tab-item
                          :key="'media'"
                          style="height: auto!important;background:#F7F7F7;"
                          :value="'tab-media'"
                        >
                        <v-row class="ml-3 mt-2" style="padding: 10px">
                          <v-col cols="12" md="12">
                            <h5 class="text-h5 font-weight-bold text-typo">Photos ({{company.images.length}})</h5>

                          </v-col>
                          <v-col cols="12" md="12" style="margin-top: -20px;">
                            <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetail = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Add Images
                                  </v-btn>
                                  <v-btn
                                    color="grey"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetailURL = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Add Images From URL
                                  </v-btn>
                                  <v-btn
                                    color="red"
                                    large
                                    outlined
                                    style="text-transform: none;"
                                    @click="dialogDetailDelete = true"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Mass Delete Images
                                  </v-btn>
                                  <v-btn
                                    color="blue"
                                    large
                                    outlined
                                    :loading="downloadLoader"
                                    style="text-transform: none;"
                                    @click="downloadImages"
                                    class="
                                      btn-default
                                     mt-5
                                     ml-2
                                    "
                                    
                                  >
                                   Download Images
                                  </v-btn>
                          <Photoswipe v-if="company.images.length > 0">
                                  <draggable style="padding: 15px" v-model="company.images" class="v-tabs__container row mt-2" v-if="company.images " @change="sortChange">
                                  <img
                                  v-for="(file,n ) in company.images"
                                    class="me-4 mb-2"
                                    style="object-fit: cover;height: 200px;width: 200px;"
                                    :src="file.image"
                                    @click="openGallery(file)"
                                    
                                  />
                                
                                  </draggable>
                                
                                              
                                </Photoswipe>
                          
                          </v-col>
                          </v-row>
                          </v-tab-item>
                    </v-tabs-items>
                </v-col>
              </v-row>
            </div>

           
        <v-dialog
              persistent
            v-model="dialogEditDetail"
            width="700"
          >
          <v-card class="card-shadow border-radius-xl mt-2" id="basic">
                    <v-card-text style="margin-bottom: -30px;">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                        <h5 class="text-h4 font-weight-bold text-typo">Edit Vehicle Detail </h5>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                      </v-col>
                        <v-row style="padding:10px">
                        <v-col
                          cols="12"
                        >
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="Account ID"
                                v-model="company.account_id"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                                color="#e91e63"
                                label="DCID"
                                v-model="company.dcid"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Dealer Name"
                                v-model="company.dealer_name"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;" >
                        <v-text-field
                                color="#e91e63"
                                label="Address"
                                v-model="company.address"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="City"
                                v-model="company.city"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                       <v-select
                                color="#e91e63"
                                 item-text="name"
                                  item-value="abbreviation"
                                :items="stateOptions"
                                label="State"
                                v-model="company.state"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Zip"
                                v-model="company.zip"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Phone"
                                v-model="company.phone"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Stock Number"
                                v-model="company.stock_number"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="VIN"
                                v-model="company.vin"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Make"
                                v-model="company.make"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Model"
                                v-model="company.model"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Year"
                                v-model="company.year"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Trim"
                                v-model="company.trim"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Odometer"
                                v-model="company.odometer"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Asking Price"
                                v-model="company.amount"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Vehicle Cost"
                                v-model="company.vehicle_cost"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Exterior Color"
                                v-model="company.exterior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Interior Color"
                                v-model="company.interior_color"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-text-field
                                color="#e91e63"
                                label="Trasmission"
                                v-model="company.transmission"
                                class="font-size-input input-style"
                            ></v-text-field>
                      </v-col>
                       <v-col cols="12" md="6" style="margin-top: -30px;">
                        <v-select
                                color="#e91e63"
                                :items="['Coupe','Convertible','Sedan','Hybrid/Electric','Crossover/SUV','Truck','4WD/AWD','Van','Wagon']"
                                label="Body Style"
                                v-model="company.body_style"
                                class="font-size-input input-style"
                            ></v-select>
                      </v-col>
                    </v-row>
                              </v-col>
                              <v-col
                                cols="12"
                                md="12"
                                class="mt-3"
                                >
    
                            <v-card-actions >
                              <v-row>
                                <v-col>
                                  <v-btn
                                    color="grey"
                                    large
                                    @click="dialogEditDetail = false"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      px-8
                                      mr-3
                                      ms-auto
                                    "
                                    
                                  >
                                   Close
                                  </v-btn>
                                  <v-btn
                                    color="#4CAF50"
                                    large
                                    @click="saveProfile(1)"
                                    class="
                                      font-weight-bolder
                                      btn-default
                                      mb-5
                                      mr-2
                                      px-8
                                      ms-auto
                                    "
                                    
                                  >
                                    Save
                                  </v-btn>
                               
                                  </v-col>
                                  </v-row>
                                  </v-card-actions>
                                  </v-col>
                              </v-row>                        
    
                        
                      </v-row>
                    </v-card-text>
                    <!-- <v-card-actions class="justify-center ">
                      <v-btn
                        tile  dark
                        style="height: 49px;width:95px;"
                        color="primary"
                        class="mb-10"
                        @click="saveProfile"
                      >Save</v-btn>
                    </v-card-actions> -->
                  </v-card>
          </v-dialog>
        <v-dialog
              persistent
            v-model="dialogDetail"
            width="700"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Upload Files
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                
        <v-card
          @drop.prevent="onDrop($event)"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false"
          :class="{ 'grey lighten-2': dragover }"
        >
          <v-card-text>
            <v-row class="d-flex flex-column" dense align="center" justify="center">
              <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
                fa-cloud-upload-alt
              </v-icon>
              <p class="mt-5">
                Drop your file(s) here
              </p>
              <p>
                Or
              </p>
              <v-btn
                color="blue"
                large
                @click="onButtonClickProof()"
                class="
                  font-weight-bolder
                  btn-default
                "
                
              >
                Browse
              </v-btn>
              <input
                  :ref="'file_proof'"
                  class="d-none"
                  type="file"
                  multiple
                  accept="image/*, video/*"
                  @change="onFileChangedProof"
                >
            </v-row>
            <v-virtual-scroll
              v-if="uploadedFiles.length > 0"
              :items="uploadedFiles"
              height="150"
              item-height="50"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.name">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <span class="ml-3 text--secondary">
                        {{ item.size }} bytes</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
    
                  <v-list-item-action>
                    <v-btn @click.stop="removeFile(item.name)" icon>
                      <v-icon style="color: red;">fas fa-times </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
    
                <v-divider></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
    
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="submit()"
                >
                  upload
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogDetail = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              persistent
            v-model="dialogDetailURL"
            width="700"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Images from URL
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                <v-select 
                label="Import from"
                  v-model="import_image_type"
                  :items="['Website','URL']"
                  >
                </v-select>
                <v-text-field
                                id="name"
                                name="name"
                                label="URL"
                                class="font-size-input input-style"
                                width="300px"
                                ref="name"
                                
                                v-model="import_image_url"
                                />
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="submitImportImage()"
                >
                  submit
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogDetailURL = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
              persistent
            v-model="dialogDetailImages"
            width="100%"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Images from URL
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                
                <draggable v-model="fetched_images" class="v-tabs__container row mt-2" v-if="fetched_images.length > 0">
                                      <v-col
                                        v-for="(file,n ) in fetched_images"
                                        :key="n"
                                        class="d-flex child-flex"
                                        cols="12"
                                        md="2"
                                      >
                                      <v-card color="#E2ECF7" width="100">
                                        <v-img
                                          :src="file"
                                          aspect-ratio="1"
                                          :style="'width: 100%;'"
                                          
                                          class="grey lighten-2 white--text align-end float-right"
                                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height ma-0"
                                              align="center"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                          <v-card-text style="color:#344767 !important;text-align: center;margin-bottom: -18px;font-family: inherit;" v-if="file.type == 'audio'">
                                            <b>{{ file.song_title }}</b>
                                          </v-card-text>
                                          <v-card-text style="color:white">
                                            {{n+1}}
                                          </v-card-text>
                                        </v-img>
                                        <v-card-actions>
                                                    <!-- <v-btn icon x-small @click="dialogPreviewFile = true,selectedFile = file">
                                                      <v-icon class="material-icons-round me-sm-1" size="20">
                                                        visibility
                                                      </v-icon>
                                                    </v-btn> -->
                                                    <!-- <v-btn icon x-small @click="downloadFile(file)" v-if="file.type !='audio'" >
                                                      <v-icon class="material-icons-round me-sm-1" size="20">
                                                        download
                                                      </v-icon>
                                                    </v-btn> -->
                                                    <v-spacer></v-spacer>
                                      
                                                    <v-btn icon x-small @click="fetched_images.splice(n, 1)">
                                                      <v-icon class="material-icons-round me-sm-1" size="20">
                                                        delete
                                                      </v-icon>
                                                    </v-btn>
                                                    
                                      
                                                  </v-card-actions>
                                                  </v-card>
                                                                        </v-col>
                                                                      
                                                                  </draggable>
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="saveURLImages()"
                >
                  submit
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogDetailImages = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog
              persistent
            v-model="dialogDetailDelete"
            width="100%"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Delete Images
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                 <v-checkbox
                                      style="margin-top: 29px;
"
                                                        v-model="selectAll"
                                                        :value="1"
                                                        @change="selectAllDelete()"
                                                        label="Select All"
                                                      ></v-checkbox>
                <draggable v-model="company.images" class="v-tabs__container row mt-2" v-if="company.images.length > 0">
                                      <v-col
                                        v-for="(file,n ) in company.images"
                                        :key="n"
                                        class="d-flex child-flex"
                                        cols="12"
                                        md="1"
                                        style=""
                                      >
                                      <div>
                                      <v-checkbox
                                      style="margin-top: 29px;
"
                                                        v-model="imageDeleteCheck"
                                                        :value="file.id"
                                                      ></v-checkbox>
                                                      </div>
                                      <v-card color="#E2ECF7" width="100">
                                       
                                        <v-img
                                          :src="file.image"
                                          aspect-ratio="1"
                                          :style="'width: 100%;'"
                                          
                                          class="grey lighten-2 white--text align-end float-right"
                                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height ma-0"
                                              align="center"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                          <v-card-text style="color:#344767 !important;text-align: center;margin-bottom: -18px;font-family: inherit;" v-if="file.type == 'audio'">
                                            <b>{{ file.song_title }}</b>
                                          </v-card-text>
                                          <v-card-text style="color:white">
                                            {{n+1}}
                                          </v-card-text>
                                        </v-img>
                                        <!-- <v-card-actions>
                                                                                         
                                                    
                                                    <v-checkbox
                                                        v-model="imageDeleteCheck"
                                                        :value="file.id"
                                                      ></v-checkbox>
                                      
                                                  </v-card-actions> -->
                                                  </v-card>
                                                                        </v-col>
                                                                      
                                                                  </draggable>
    
              </v-card-text>
              
              <v-divider></v-divider>
      
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue"
                  text
                  :loading="loadingUpload"
                  @click="removeItem('all')"
                >
                  submit
                </v-btn>
                 <v-btn
                  color="primary"
                  text
                  @click="dialogDetailDelete = false"
                >
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              persistent
            v-model="dialogFileAudio"
            width="700"
          >
      
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Add song
              </v-card-title>
      
              <v-card-text style="margin-top:30px;"> 
                <v-text-field
                                id="name"
                                name="name"
                                label="Title"
                                class="font-size-input input-style"
                                width="300px"
                                ref="name"
                                placeholder="What is the name of the song?"
                                v-model="file_audio.song_title"
                                />
                                <v-text-field
                                id="name"
                                name="name"
                                label="Artist"
                                class="font-size-input input-style"
                                width="300px"
                                ref="name"
                                placeholder="Who performed it?"
                                v-model="file_audio.song_artist"
                                />
                </v-card-text>
                <v-divider></v-divider>
      
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue"
                    text
                    :loading="loadingUpload"
                    @click="submitAudio()"
                  >
                    Add
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogFileAudio = false"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
              </v-dialog>
          <avatar-cropper
                        :cropper-options="{autoCropArea: 1,aspectRatio: NaN,}"
                        v-model="showCropper"
                        :file="selectedFileRaw"
                        :upload-handler="handleUploaded"
                      />
          <v-dialog
              v-model="dialogPreviewFile"
              width="1200"
            >
              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Preview
                </v-card-title>
                <div  class="mt-5 mb-5">
                 <v-card-text >
                  <v-row>
                    <v-col cols="12">
                      
                  <v-img
                          :src="selectedFile.image"
                          :lazy-src="selectedFile.image"
                          :style="'width: 100%;height:500px'"
                          
                          class="grey lighten-2 white--text align-end float-right"
                        >
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height ma-0"
                                              align="center"
                                              justify="center"
                                            >
                                              <v-progress-circular
                                                indeterminate
                                                color="grey lighten-5"
                                              ></v-progress-circular>
                                            </v-row>
                                          </template>
                                      
                                        </v-img>
                                        </v-col>
                                        </v-row>
                </v-card-text>
                </div>
                <v-divider></v-divider>
    
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogPreviewFile = false"
                  >
                    close
                  </v-btn>
                  <v-btn
                    color="red"
                    text
                    @click="removeItem(selectedFile.id)"
                  >
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
    </div>
    
    </template>
    
    <script>
    import { VueTelInput } from 'vue-tel-input'
    import draggable from 'vuedraggable'
    import AvatarCropper from 'vue-avatar-cropper'
    import {Circle8} from 'vue-loading-spinner'
import ClipLoader from 'vue-spinner/src/PulseLoader.vue';
    import moment from 'moment'
    export default {
    components: {
          VueTelInput,draggable,AvatarCropper,'RotateLoader': ClipLoader,
      },
      props: {
        id: Number
      },
      data () {
    
          return {
            fname : '',
            lname : '',
            email : '',
            name:'',
            e1 : 3,
            phone:'',
            selectAll : 0,
            loading : false,
            password : '',
            is_admin:0,
            showCropper: false,
            import_image_url : '',
            import_image_type : 'Website',
            dialogDetailURL: false,
            imageDeleteCheck: [],
            dialogDetailDelete: false,
            dialogDetailImages: false,
            dialogFileAudio: false,
            fetched_images: [],
            confpassword : '',
            dialogDetail: false,
            selectedFile : '',
            downloadLoader: false,
            role : '',
            dialogEditDetail: false,
            value: 0,
            query: false,
            stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
            parents: [
                {
                    id: 1,
                    title: "john doe",
                    active: true
                },
                {
                    id: 3,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 4,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 5,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 6,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 7,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 8,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 9,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 10,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 11,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 12,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 13,
                    title: "scarlett",
                    active: false
                },
                {
                    id: 14,
                    title: "scarlett",
                    active: false
                }
                ],
                messages: [
                {
                    content: "lorem ipsum",
                    me: true,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
                {
                    content: "dolor",
                    me: false,
                    created_at: "11:11am"
                },
            ],
            messageForm: {
            content: "",
            me: true,
            created_at: "11:11am"
            },
            show: true,
            interval: 0,
            valid : true,
            companyname : '',
            sector : '',
            menu : false,
            no_of_employee : '',
            showPassword : false,
            loadingAssign : true,
            phoneFlag:0,
            emailFlag:0,
            country :'',
            dialogPreviewFile: false,
            otp_email:'',
            image_url :process.env.VUE_APP_WASBI_URL,
            otp_phone:'',
            dragover: false,
            uploadedFiles: [],
            loadingUpload : false,
            stateOptions: [
                    { name: 'ALABAMA', abbreviation: 'AL'},
                    { name: 'ALASKA', abbreviation: 'AK'},
                    { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                    { name: 'ARIZONA', abbreviation: 'AZ'},
                    { name: 'ARKANSAS', abbreviation: 'AR'},
                    { name: 'CALIFORNIA', abbreviation: 'CA'},
                    { name: 'COLORADO', abbreviation: 'CO'},
                    { name: 'CONNECTICUT', abbreviation: 'CT'},
                    { name: 'DELAWARE', abbreviation: 'DE'},
                    { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                    { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                    { name: 'FLORIDA', abbreviation: 'FL'},
                    { name: 'GEORGIA', abbreviation: 'GA'},
                    { name: 'GUAM', abbreviation: 'GU'},
                    { name: 'HAWAII', abbreviation: 'HI'},
                    { name: 'IDAHO', abbreviation: 'ID'},
                    { name: 'ILLINOIS', abbreviation: 'IL'},
                    { name: 'INDIANA', abbreviation: 'IN'},
                    { name: 'IOWA', abbreviation: 'IA'},
                    { name: 'KANSAS', abbreviation: 'KS'},
                    { name: 'KENTUCKY', abbreviation: 'KY'},
                    { name: 'LOUISIANA', abbreviation: 'LA'},
                    { name: 'MAINE', abbreviation: 'ME'},
                    { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                    { name: 'MARYLAND', abbreviation: 'MD'},
                    { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                    { name: 'MICHIGAN', abbreviation: 'MI'},
                    { name: 'MINNESOTA', abbreviation: 'MN'},
                    { name: 'MISSISSIPPI', abbreviation: 'MS'},
                    { name: 'MISSOURI', abbreviation: 'MO'},
                    { name: 'MONTANA', abbreviation: 'MT'},
                    { name: 'NEBRASKA', abbreviation: 'NE'},
                    { name: 'NEVADA', abbreviation: 'NV'},
                    { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                    { name: 'NEW JERSEY', abbreviation: 'NJ'},
                    { name: 'NEW MEXICO', abbreviation: 'NM'},
                    { name: 'NEW YORK', abbreviation: 'NY'},
                    { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                    { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                    { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                    { name: 'OHIO', abbreviation: 'OH'},
                    { name: 'OKLAHOMA', abbreviation: 'OK'},
                    { name: 'OREGON', abbreviation: 'OR'},
                    { name: 'PALAU', abbreviation: 'PW'},
                    { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                    { name: 'PUERTO RICO', abbreviation: 'PR'},
                    { name: 'RHODE ISLAND', abbreviation: 'RI'},
                    { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                    { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                    { name: 'TENNESSEE', abbreviation: 'TN'},
                    { name: 'TEXAS', abbreviation: 'TX'},
                    { name: 'UTAH', abbreviation: 'UT'},
                    { name: 'VERMONT', abbreviation: 'VT'},
                    { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                    { name: 'VIRGINIA', abbreviation: 'VA'},
                    { name: 'WASHINGTON', abbreviation: 'WA'},
                    { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                    { name: 'WISCONSIN', abbreviation: 'WI'},
                    { name: 'WYOMING', abbreviation: 'WY' }
                ],
            company : {
              id : '',
              project_name: "",
              deadline:  "",
              status : "",
              title : "",
              sub_title : "",
              closing_text : "",
              files :[],
    
            },
            otp_phone_input:"",
            otp_email_input:"",
            user_phone:'',
            selectedFileRaw: '',
            settings: [],
            cropperOutputMime : '',
            tab : 1,
            starter_price : 0,
            starter_qty :0,
            song_qty : 0,
            song_price : 0,
            user_email:'',
            conversations: [],
            conversation : {
            sender : '',
            receiver_user_id : '',
           
            receiver : '',
            subject : '',
            body : '',
            type : ''
            },
            status:0,
            file_audio :{
              song_artist : "",
              song_title : "",
              id :"",
            },
    
            tab: null,
            headers: [
    
              { text: '', value: 'CompanyURL' },
              { text: '', value: 'CompanyName' },
    
            ],
            history: [
    
            ],
    
            setting: [
            ],
    
            items: [
                  'Admin',
                  'Viewer',
          ],
          select_role : 'Admin',
           sectors: [
    
          ],
          employeeNumber: [
                  '10-50',
                  '50-100',
                  'More than 100',
    
          ],
    
          }
        },
      watch: {
                // company: {
                //     handler () {
                //         this.company.company_url = this.company.name.replace(/\s/g, '-').toLowerCase()
                //     },
                //     deep: true,
                // },
            },
        methods: {
          currencyFormat(value){
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value);
          },
          openGallery(item){
            this.dialogPreviewFile = true
            this.selectedFile = item
          },
          getCount(type){
            let count_file = 0;
            let count_audio = 0;
            
            // this.company.images.forEach(element => {
            //   if(element.type !='audio'){
            //     count_file = count_file +1;
            //   }else{
            //     count_audio = count_audio  +1;
            //   }
            // });
            
            // if(type == 'audio')
            //   return count_audio;
            // else
            //   return count_file;
          },
          queryAndIndeterminate () {
            this.query = true
            this.show = true
            this.value = 0
    
            setTimeout(() => {
              this.query = false
    
              this.interval = setInterval(() => {
                this.value += 10
              }, 1000)
            }, 2500)
          },
          dateFormat(date){
              return moment(date).format("MM/DD/YYYY HH:mm:ss");
            },
          getGHLChat(){
            axios({
                method: "POST",
                url: "/api/get-ghl-conversation",
                data : {client_id : this.company.client_id,ref_id : this.company.id},
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                    this.conversations = data;
                    }
                    })
            },
            downloadImages(){
              this.downloadLoader = true
            axios({
                method: "GET",
                url: "/api/download-images/"+this.company.id,
                headers: {Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {                  
                     // Assuming the response contains the base64-encoded ZIP content
                      const base64ZipContent = response.data.zipContent;  // Adjust based on your API response structure
                      console.log(base64ZipContent);
                      
                      // Decode the base64 string to binary
                      const binaryString = window.atob(base64ZipContent); // atob decodes base64 to a binary string

                      // Convert the binary string to a byte array
                      const len = binaryString.length;
                      const bytes = new Uint8Array(len);
                      for (let i = 0; i < len; i++) {
                          bytes[i] = binaryString.charCodeAt(i);
                      }

                      // Create a Blob from the byte array
                      const blob = new Blob([bytes], { type: 'application/zip' });

                      // Create a link element to download the blob
                      const link = document.createElement('a');
                      link.href = window.URL.createObjectURL(blob);
                      link.download = 'downloaded_file.zip';  // The file name for the downloaded file

                      // Programmatically trigger the download
                      document.body.appendChild(link);
                      link.click();

                      // Clean up
                      document.body.removeChild(link);
                      window.URL.revokeObjectURL(link.href);
                      this.downloadLoader = false
                    })
            },
            getDateTime(date){
            return moment(date).format('LLLL');
            },
          sendConversation(flag){
            this.conversation.type = flag;
            this.conversation.receiver_user_id = this.company.client_id;
            this.conversation.ref_id = this.company.id;
            let self = this;
            if(!this.conversation.body){
                    Vue.$toast.error("Message is required! ", {
                    position: 'top-right'
                    });
                }else{
            self.loadingChat = true;
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                } 
            axios
                .post("/api/send-conversation",this.conversation,header)
                .then(response => {
                self.loadingChat = false;
                self.getGHLChat();
                Vue.$toast.success('Message Sent', {
                        position: 'top-right'
                        });
                        this. conversation =  {
                            sender : '',
                            receiver_user_id : '',
                            receiver : '',
                            subject : '',
                            body : '',
                            type : ''
                            }
                })
                .catch(err => {
                self.loadingChat = false;
                    Vue.$toast.error('Unable to send', {
                    position: 'top-right'
                    });
                })
            }
            },
            submitImportImage(){
            let self = this;
           self.loadingUpload= true;
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                } 
            axios
                .post("/api/import-images",{url:this.import_image_url,type: this.import_image_type},header)
                .then(response => {
                  response.data.data.forEach(image => {
                    if(!self.fetched_images.includes(image))
                      self.fetched_images.push(image);
                  })
                
                self.dialogDetailURL = false;
                self.dialogDetailImages = true;
                self.loadingUpload = false;
                })
                .catch(err => {
                self.loadingChat = false;
                    Vue.$toast.error('Unable to send', {
                    position: 'top-right'
                    });
                })
            },
            selectAllDelete(){
              if(this.selectAll){
                this.company.images.forEach(element => {
                  this.imageDeleteCheck.push(element.id)
                })
              }else
                this.imageDeleteCheck = [];
            },
            getAddressData: function (addressData, placeResultData, id) {      
            this.company.street = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
            this.company.latitude = addressData.latitude;
            this.company.longitude = addressData.longitude;
        },
            saveURLImages(){
            let self = this;
            self.loadingUpload= true;
            let header = { headers : {
                        Authorization:"Bearer " + localStorage.getItem('token')
                        }
                } 
            axios
                .post("/api/import-images-save",{images:this.fetched_images,ref_id:this.company.id},header)
                .then(response => {
                  
                
                self.dialogDetailImages = false;
                self.loadingUpload = false;
                self.getUserProfile(this.id);
                })
                .catch(err => {
                self.loadingChat = false;
                    Vue.$toast.error('Unable to send', {
                    position: 'top-right'
                    });
                })
            },
          deleteItemInventory(){
                var r = confirm("Are you sure, You went to delete?");
                if (r == true) {
                  axios
                    .post("/api/delete-vehicle",{ids : [this.company]},this.header)
                    .then(response => {
                        let data = response.data;

                    if (response.data) {
                        Vue.$toast.success('Deleted successfully', {
                            position: 'top-right'
                            });
                          this.closeDetailmodal();
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }

                    })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                  })
                }
              },
          getSettings() {
            let flag = 1;
            let url = process.env.VUE_APP_WASBI_URL
            var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
            axios({
                method: "POST",
                url: "/api/get-company-settings",
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    let data = response.data.data;
                    if(data){
                        this.settings = data.settings
                    localStorage.setItem('settings', JSON.stringify(data.settings));
                    localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
                    this.getUserProfile(this.id);
                    }

                })
                .catch(err => {
                    // Vue.$toast.error(err, {
                    // position: 'top-right'
                    // });

                });

            },
          loaderOff(){
                  clearInterval(this.interval)
                  this.show = false
          },
          onButtonClickProof(id) {
            this.$refs['file_proof'].click()
          },
          removeItem(id){
            var r = confirm("Are you sure, You went to delete?");
                if (r == true) {
                  this.queryAndIndeterminate()
                  axios
                    .post("/api/delete-vehicle-image/"+id,{items : this.imageDeleteCheck},this.header)
                    .then(response => {
                        let data = response.data;
      
                    if (response.data) {
                        Vue.$toast.success('Deleted successfully', {
                            position: 'top-right'
                            });
                          this.getUserProfile(this.id);
                          this.dialogPreviewFile = false
                        }else {
                            Vue.$toast.error(data.message, {
                            position: 'top-right'
                            });
                        }
      
                    })
                  .catch(err => {
                    Vue.$toast.error(err, {
                    position: 'top-right'
                    });
                  })
                }
          },
          removeFile(fileName) {
          // Find the index of the
          const index = this.uploadedFiles.findIndex(
            file => file.name === fileName
          );
          // If file is in uploaded files remove it
          if (index > -1) this.uploadedFiles.splice(index, 1);
        },
        async convertUrlToFile(imageUrl, fileExt) {
          try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const file = new File([blob], 'image.'+fileExt, { type: blob.type });
            // Now you have the 'file' object that represents the image file
            this.selectedFileRaw = file;
            this.showCropper = true
          } catch (error) {
            console.error('Error converting URL to File:', error);
          }
        },
        onFileChangedProof(e) {
          e.target.files.forEach(element =>
              this.uploadedFiles.push(element)
            );
        },
        onDrop(e) {
          this.dragover = false;
          // If there are already uploaded files remove them
          if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
          // If user has uploaded multiple files but the component is not multiple throw error
          if (!this.multiple && e.dataTransfer.files.length > 1) {
            this.$store.dispatch("addNotification", {
              message: "Only one file can be uploaded at a time..",
              colour: "error"
            });
          }
          // Add each file to the array of uploaded files
          else
            e.dataTransfer.files.forEach(element =>
              this.uploadedFiles.push(element)
            );
        },
        submit(editFlag = 0,editFlagId='') {
          // If there aren't any files to be uploaded throw error
          if (!this.uploadedFiles.length > 0) {
            Vue.$toast.error('There are no files to upload', {
              position: 'top-right'
          });
          } else {
            let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            // Send uploaded files to parent component
            const formData = new FormData();
              // Append each file to the form data
              for (let i = 0; i < this.uploadedFiles.length; i++) {
                formData.append('files[]', this.uploadedFiles[i]);
              }
              this.loadingUpload = true;
              axios
              .post("/api/upload-vehicle-files/"+this.company.id,formData,header)
              .then(response => {
                  let data = response.data;
    
                if (data.flag == 1) {
                    
                  this.dialogDetail = false;
                  this.uploadedFiles = [];
                  this.getUserProfile(this.id)
                  this.loadingUpload = false;
                  }else {
                    console.log(data.response);
                      // Vue.$toast.error(data.message, {
                      // position: 'top-right'
                      // });
                  }
    
              })
                .catch(err => {
                  if(err.response.data.error == 'validation.mimetypes')
                    Vue.$toast.error('Invalid file type', {
                    position: 'top-right'
                    });
                  else
                    Vue.$toast.error('Unable to upload', {
                      position: 'top-right'
                      });
    
                });
            // Close the dialog box
          }
        },
        downloadFile(file){
        let self = this;
        window.open(file.image, '_blank');
        },
        submitAudio(editFlag = 0,editFlagId='') {
          if(this.file_audio.id){
            editFlag = 1;
            editFlagId = this.file_audio.id;
          }
          // If there aren't any files to be uploaded throw error
          if (!this.file_audio.song_title) {
            Vue.$toast.error('Title is required', {
              position: 'top-right'
          });
          }else if(!this.file_audio.song_artist){
            Vue.$toast.error('Artist is required', {
              position: 'top-right'
          });
          } else {
            let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            // Send uploaded files to parent component
            // this.$emit("filesUploaded", this.uploadedFiles);
            const formData = new FormData();
            formData.append('type', 'audio');
            formData.append('edit_flag', editFlag);
            formData.append('edit_flag_id', editFlagId);
            formData.append('song_title', this.file_audio.song_title);
            formData.append('song_artist', this.file_audio.song_artist);
              // Append each file to the form data
              this.loadingUpload = true;
              this.queryAndIndeterminate()
              axios
              .post("/api/upload-slideshow-files-audio/"+this.company.id,formData,header)
              .then(response => {
                  let data = response.data;
    
                if (data.flag == 1) {
                    
                  this.dialogFileAudio = false;
                  this.uploadedFiles = [];
                  this.getUserProfile(this.id)
                  this.loadingUpload = false;
                  }else {
                    console.log(data.response);
                      // Vue.$toast.error(data.message, {
                      // position: 'top-right'
                      // });
                  }
    
              })
                .catch(err => {
                  if(err.response.data.error == 'validation.mimetypes')
                    Vue.$toast.error('Invalid file type', {
                    position: 'top-right'
                    });
                  else
                    Vue.$toast.error('Unable to upload', {
                      position: 'top-right'
                      });
    
                });
            // Close the dialog box
          }
        },
        dataURLtoFile(dataurl, filename) {
     
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
        },
        handleUploaded(cropper) {
              let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
              let img = this.dataURLtoFile(image,this.selectedFile.file_name);
              this.uploadedFiles.push(img);
              this.submit(1,this.selectedFile.id);
              this.showCropper = false;
    
            },
        addPhone(){
            this.phoneFlag = 1;
        },
        addEmail(){
            this.emailFlag = 1;
        },
        countryChanged(country) {
            this.country = '+' + country.dialCode
        },
    
    
        add: function () {
             // for setting tab
        },
    
        getUserProfile(id) {
        let flag = 1;
        const domain = window.location.host;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            this.queryAndIndeterminate();
          axios
            .post("/api/get-vehicle/"+id,{domain : domain},header)
            .then(response => {
                let data = response.data;
    
               if (flag == 1) {
                  this.company = data.data;
                  this.loaderOff();
                  this.loadingAssign = false
                  //this.getGHLChat()
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
    
         },
         sortChange(){
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
          axios
            .post("/api/change-viehicle-image-order",this.company.images,header)
            .then(response => {
                let data = response.data;
    
               if (data.flag == 1) {
                  this.getUserProfile(this.id)
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
         },
    
    
    
        saveProfile: function(submit=0) {
          let self = this;
          let flag = 1;
          let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
    
            if (flag) {
              self.loading = true;
              
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/save-vehicle",
              data: self.company
            })
              .then(response => {
                let data = response.data.data;
                if (response.data) {
                      
                      self.loading = false;
                      if(!this.company.id){
                        this.$router.push('/add-slideshow/'+data.id)
                      }else if(submit == 1){
                        Vue.$toast.success('Data Saved', {
                        position: 'top-right'
                      })
                      
                      }else if(submit == 2){
                        Vue.$toast.success('Data Saved', {
                        position: 'top-right'
                      })
                      this.$emit('closeDetailmodal')
                      }else
                        this.e1 = 2;
    
                } else {
                    Vue.$toast.error('Failed To Add', {
                    position: 'top-right'
                    });
                    self.loading = false;
                }
              })
              .catch(err => {
                console.log(err.response);
                
                  if(err.response.data.errors.email)
                    Vue.$toast.error(err.response.data.errors.email[0], {
                    position: 'top-right'
                    });
                  if(err.response.data.errors.user_name)
                    Vue.$toast.error(err.response.data.errors.user_name[0], {
                    position: 'top-right'
                    });     
                  if(err.response.data.errors.company_url)
                    Vue.$toast.error(err.response.data.errors.company_url[0], {
                    position: 'top-right'
                    });             
              });
            }
        },
    
     },
    
    created() {

          console.log('created called.');
          this.getSettings();
          
        },
    
    }
    </script>
    
    <style>
    .v-stepper__step__step{
      background: #4CAF50;
    }
    .stepper-slide  {
      box-shadow: none!important;
    }
    .text-gray-400 {
      --tw-text-opacity: 1;
      color: #9ca3af;
      font-family: inherit;
      font-size: 1.5rem;
      color: rgba(156,163,175,var(--tw-text-opacity));
    }
    .v-tabs-slider-wrapper
    {
      height: 46px!important;
    }
    .w-50{
      width: 50%;
      height: 200px;
      object-fit: cover;
    }
    .v-dialog{
      background-color: #E0E0E0;
    }
    .word-break-text{
      white-space: nowrap; 
    width: 150px; 
    overflow: hidden;
    text-overflow: ellipsis; 
    }
    .pseudo_border {
    position:relative;
    display:inline-block;
    }
    .pseudo_border:after {
        content:'';
        position:absolute;
        left:0;right:0;
        top:100%;
        margin: 5px auto;
        width:100%;
        height:4px;
        background:#4C86B6;
        border-bottom-width: 2px;
        border-width: 8px;
    }
    </style>