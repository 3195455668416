<template>
  <v-navigation-drawer
    width="100%"
    height="calc(100% - 2rem)"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    class="my-4 ms-4 border-radius-xl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme"
  >
    <!-- <v-list-item >
      <v-list-item-content >
        <v-toolbar-title class=" font-weight-black" style="color:white;margin-top: 50px;font-size: 1.50rem;margin-left:10px">
            <img :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2" src="@/assets/img/Quantum Disputes.png">
          </v-toolbar-title>
      </v-list-item-content>
    </v-list-item> -->
        <v-list-item>
        <v-list-item-content style="margin-top:50px;">
         <img v-if="role.name == 'super_admin' || role.name == 'company_admin'" :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2 mt-5" src="@/assets/img/logo.png" style="height: 100%;
width: 1px;">

        <img v-if="role.name != 'super_admin' && role.name != 'company_admin' && company_logo" :height="this.$vuetify.breakpoint.xs ? 150 : 100" class="image-logo2 mt-3" :src="company_logo" style="height: 100%;
width: 1px;"> 
        </v-list-item-content>
      </v-list-item>

    <hr
      class="horizontal"
      style="margin-top:70px;"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>
      <v-list-group
        :ripple="false"
        v-model="userInfo_active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-avatar size="30" class="my-3 ms-2">
            <img v-if="!avatar" src="@/assets/img/avatar.png" alt="Brooklyn" />
            <img v-else :src="avatar" alt="Brooklyn" />
          </v-avatar>

          <v-list-item-content>
            <v-list-item-title class="ms-2 ps-1 font-weight-light">
              {{name}}
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="'has-children'"
          v-if="role.name == 'super_admin' || role.name == 'company_admin'"
          v-for=" child in userInfo"
          :key="child.title"
          @click="child.title == 'Logout' ? logout() : ''"
          :to="child.link"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>
          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="'has-children'"
          v-if="role.name == 'company_admins'"
          v-for=" child in userInfoCompany"
          :key="child.title"
          @click="child.title == 'Logout' ? logout() : ''"
          :to="child.link"
        >
          <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>
          <v-list-item-content class="ms-1 ps-1 py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="pa-0">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <v-list-item-content class="py-0">
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

              <v-list-item
                v-for="child2 in child.items"
                :ripple="false"
                :key="child2.title"
                :to="child2.link"
                @click="listClose($event)"
              >
                <span class="v-list-item-mini" v-text="child2.prefix"></span>
                <v-list-item-content>
                  <v-list-item-title v-text="child2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

    <hr
      class="horizontal"
      style="margin-top:11px;"
      :class="sidebarTheme == 'dark' ? 'light' : 'dark'"
    />

    <v-list nav dense>

      <v-list-item-group v-if="checkPermission('dashboard')">
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/dashboard'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">dashboard</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Dashboard'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      
      <!-- <v-list-item-group v-if="role.name =='super_admin'">
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/grant-dashboard'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">price_check</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Grant Dashboard'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group> -->
      <!-- <v-list-item-group >
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/cards'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">badge</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Cards'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      <v-list-item-group >
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/contacts'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">contact_page</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'VCard Contacts'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      <v-list-item-group >
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/video-links'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">videocam</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'VCard Videos'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group> -->
      <v-list-item-group v-if="checkPermission('shares')">
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/shares'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <img :height="this.$vuetify.breakpoint.xs ? 30 : 30" class="" src="@/assets/img/share-drawer.png" style="margin: 4px 0px 0px -3px;">
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Shares'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      <v-list-item-group v-if="checkPermission('conversations')">
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/conversations'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <v-badge
              v-if="countMessage > 0"
          color="green"
          :content="countMessage"
          class="badge-chat"
         
        >
              <img :height="this.$vuetify.breakpoint.xs ? 25 : 25" class="" src="@/assets/img/bubble-chat.png" style="margin: 4px 0px 0px -3px;">
              </v-badge>
              <img v-else :height="this.$vuetify.breakpoint.xs ? 25 : 25" class="" src="@/assets/img/bubble-chat.png" style="margin: 4px 0px 0px -3px;">

            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Conversations'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      <v-list-item-group v-if="checkPermission('products')">
        <div >
          <v-list-item
            link
            v-model="dashboard_active"
            :to="'/admin/products'"
            class="pb-1 mx-2 no-default-hover py-2"
            :ripple="false"
            active-class="item-active"
          >
            <v-list-item-icon class="me-2 align-center">
              <i class="material-icons-round opacity-10">paid</i>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="'Products'"
                class="ms-1"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          
        </div>
      </v-list-item-group>
      <v-list-group
        :ripple="false"
        v-for="item in itemsPages"
        :key="item.title"
        v-if="checkPermission(item.title.toLowerCase().replace(/ /g,'_'))"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="ms-1"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          v-if="checkPermission(child.title.toLowerCase().replace(/ /g,'_'))"
          @click="item.title == 'Custom Links' ? gotolink(child.id,child) : false"
          :to="item.title == 'Custom Links' ? '' : child.link"
        >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>

          <v-list-item-content class="py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="py-2 ps-5 pe-2">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <div class="w-100 d-flex align-center">
                        <span
                          class="v-list-item-mini ms-1"
                          v-text="child.prefix"
                        ></span>

                        <v-list-item-content class="py-0 ms-4">
                          <v-list-item-title
                            class="ms-2"
                            v-text="child.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
 <v-list-group
        :ripple="false"
        v-for="item in itemsPagesCompany"
        :key="item.title"
        v-if="role.name == 'company_admins'"
        v-model="item.active"
        append-icon="fas fa-angle-down"
        class="pb-1 mx-2"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-icon class="me-2 align-center">
            <i class="material-icons-round opacity-10">{{ item.action }}</i>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="ms-1"
            ></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-1 no-default-hover px-0"
          :class="child.items ? 'has-children' : ''"
          v-for="child in item.items"
          :key="child.title"
          @click="item.title == 'Custom Links' ? gotolink(child.id,child) : false"
          :to="item.title == 'Custom Links' ? '' : child.link"
        >
            <div class="w-100 d-flex align-center pa-2 border-radius-lg">
            <span class="v-list-item-mini" v-text="child.prefix"></span>

          <v-list-item-content class="ms-2 ps-1" v-if="!child.items">
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
          </div>

          <v-list-item-content class="py-0" v-if="child.items">
            <v-list-group
              prepend-icon=""
              :ripple="false"
              sub-group
              no-action
              v-model="child.active"
            >
              <template v-slot:activator>
                <v-list nav dense class="py-2 ps-5 pe-2">
                  <v-list-group
                    :ripple="false"
                    append-icon="fas fa-angle-down me-auto ms-1"
                    active-class="item-active"
                    class="mb-0"
                  >
                    <template v-slot:activator class="mb-0">
                      <div class="w-100 d-flex align-center">
                        <span
                          class="v-list-item-mini ms-1"
                          v-text="child.prefix"
                        ></span>

                        <v-list-item-content class="py-0 ms-4">
                          <v-list-item-title
                            class="ms-2"
                            v-text="child.title"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </div>
                    </template>
                  </v-list-group>
                </v-list>
              </template>

            </v-list-group>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      
    </v-list>



    <v-dialog
      v-model="dialog"
      persistent
      max-width="890"
    >
      <v-card>
        <v-card-title class="text-h5" style="text-align: center;
display: block ruby;">
          <img  :height="this.$vuetify.breakpoint.xs ? 50 : 60" class="image-logo2 mt-10" src="@/assets/img/logo.png" style="height: 100%;
width: 200px; "> 
        </v-card-title>
        <v-card-text><h1 class="text-h2 text-typo font-weight-bold text-center mt-1" v-if="e1 != 5">Welcome to 10K Cards!</h1><h1 class="text-h2 text-typo font-weight-bold text-center mt-5" v-else>Ready to Rock!</h1></v-card-text>
        <v-card-text><h1 class="text-h3 text-typo font-weight-bold text-center " style="color:#4CAF50 !important;" v-if="e1 != 5">Let's get started</h1><h1 class="text-h3 text-typo font-weight-bold text-center " style="color:#4CAF50 !important;" v-else>Initial Setup Complete</h1></v-card-text>
        <v-card-text>

          <v-stepper
            alt-labels
            elevation="0"
            v-model="e1"
            class="bg-transparent overflow-visible mt-10"
          >
            <div class="pt-1">
              <v-stepper-header
                class="
                  shadow-green
                  border-radius-lg
                  mx-4
                  mt-n10
                "
              >
                <v-stepper-step step="1" color="green">
                  <span
                    class="
                      text-black
                      text-xs
                      font-weight-normal
                    "
                    >Create Account</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="2" color="green">
                  <span
                    class="
                      text-black
                      text-xs
                      font-weight-normal
                    "
                    >Company Details</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3" color="green">
                  <span
                    class="
                      text-black
                      text-xs
                      font-weight-normal
                    "
                    >Branding Setup</span
                  >
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="4" color="green">
                  <span
                    class="
                      text-black
                      text-xs
                      font-weight-normal
                    "
                    >Merchant Settings</span
                  >
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="5" color="green">
                  <span
                    class="
                      text-black
                      text-xs
                      font-weight-normal
                    "
                    > Start Crushing!</span
                  >
                </v-stepper-step>
              </v-stepper-header>
            </div>
            <v-stepper-items class="border-radius-xl overflow-hidden mt-5">
              <v-stepper-content
                step="2"
                class="bg-white border-radius-xl px-7 pt-2"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                      Business Details
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Mandatory informations
                    </p>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Business Name*"
                        width="300px"
                        ref="name"
                        v-model="company.name"
                        />
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Contact Email*"
                        width="300px"
                        ref="name"
                        v-model="company.contact_email"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        id="name"
                        name="name"
                        class="font-size-input input-style"
                        label="Contact Phone*"
                        v-mask="'(###) ###-####'"
                        width="300px"
                        ref="name"
                        v-model="company.contact_phone"
                        />
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                        id="name"
                        name="name"
                        label="Full Name"
                        class="font-size-input input-style"
                        width="300px"
                        ref="name"
                        v-model="company.details"
                        />
                      </v-col>
                    </v-row>

                    <v-row class="mt-2">
                      <v-col sm="12" cols="12" class="py-0">
                        <vuetify-google-autocomplete
                                  id="map"
                                  label="Address"
                                  v-model="company.address_1"
                                  placeholder="Enter a location"
                                  class="font-size-input input-style"
                                  v-on:placechanged="getAddressData"
                                  country="us"
                              ></vuetify-google-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="City"
                          v-model="company.city"
                          class="font-size-input input-style" />
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-select
                          label="State"
                          :items="stateOptions"
                          item-text="name"
                          item-value="abbreviation"
                          placeholder="Select State"
                          v-model="company.state"
                          class="font-size-input input-style"
                    ></v-select>
                      </v-col>
                      <v-col sm="6" cols="12" class="py-0">
                        <v-text-field
                          label="Zip"
                          v-model="company.zip"
                          class="font-size-input input-style"
                    ></v-text-field>
                      </v-col>
                     
                      </v-row>
                  </div>
                  <div class="text-end">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                      "
                      color="primary"
                      @click="saveProfile('3')"
                    >
                      Save & Continue
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="3"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo">
                    Logo
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Your logo should be HORIZONTAL and have a WHITE background
                    </p>
                    <v-row class="mt-2">
                      <v-col cols="12" class="py-0">
                        
                            <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
                            <div class="border p-2 mt-3">
                                <p>Preview Here:</p>
                                <template v-if="preview">
                                  <v-img contain :src="preview" class="img-fluid" />
                                </template>
                              </div>
                             
                              <v-btn
                                dark
                              color="blue"
                              class=" mt-2"
                              @click="uploadLogo"
                            >Upload</v-btn>
                            <p>(Only jpeg, jpg and png)</p>
                      </v-col>
                    </v-row>
                   
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        placeholder-lighter
                        text-xs text-dark
                        btn-outline-secondary
                        bg-transparent
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="skipStartUp()"
                    >
                      Skip for now
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                      "
                      color="primary"
                      @click="e1 = 4"
                    >
                      Save & Continue
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="4"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h5 class="font-weight-bolder text-h5 text-typo">
                        Credit Card Processing Details
                    </h5>
                    <p class="mb-0 text-sm text-body font-weight-light">
                      Enter your Merchant Account details below to enable customer billing
                    </p>
                    <v-row class="mt-2">
                      <v-col cols="12" class="py-0">
                        <v-text-field
                              v-model="promo.name"
                              label="Merchant Gateway Name*"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <v-select
                            placeholder="Merchant Gateway Provider*"
                            label="Merchant Gateway Provider*"
                            :disabled="loadingAssign"
                            v-model="promo.type"
                            :items="['Paypal','Stripe','Authorize.net','Jotform']"
                            ></v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                     
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          label="API User*"
                            v-if="promo.type == 'Paypal'"
                                v-model="promo.credentials.api_user"
                          ></v-text-field>
                          <v-text-field
                          label="API Login ID*"
                            v-if="promo.type == 'Authorize.net'"
                                v-model="promo.credentials.api_user"
                          ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="mt-2">
                      <v-col cols="12" class="py-0">
                        <v-text-field
                          v-if="promo.type != 'Jotform' && promo.type != 'Authorize.net' "
                          label="Secret*"
                                v-model="promo.credentials.api_password"
                          ></v-text-field>
                          <v-text-field
                            v-if="promo.type == 'Authorize.net' "
                            label="Transaction Key*"
                                  v-model="promo.credentials.api_password"
                            ></v-text-field>
                            <v-text-field
                            v-if="promo.type != 'Jotform' && promo.type != 'Authorize.net' && promo.type != 'Paypal'"
                            label="Publisher Key*"
                                  v-model="promo.credentials.api_signature"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="py-0">
                        <p style="text-align:left;">Test Mode</p>
                        <v-switch
                        v-model="company.payment_gateway_mode"
                        label="True"
                        style="margin-bottom: 30px;"
                        color="green"
                        value="1"
                        hide-details
                      >
                      <template #prepend>
                        <v-label>False</v-label>
                      </template>
                      </v-switch>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="d-flex">
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        placeholder-lighter
                        text-xs text-dark
                        btn-outline-secondary
                        bg-transparent
                        py-5
                        px-6
                        mt-6
                        mb-2
                        ms-2
                      "
                      @click="skipStartUp()"
                    >
                      Skip for now
                    </v-btn>
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        btn-default
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-0
                        ms-auto
                      "
                      color="#4CAF50"
                      @click="addPromo()"
                    >
                      Save & Continue
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-content
                step="5"
                class="bg-white border-radius-xl px-7 pt-4"
              >
                <v-card>
                  <div>
                    <h1 class="text-h3 text-typo font-weight-bold text-center " >Congratulations, your account is all </h1><h1 class="text-h3 text-typo font-weight-bold text-center " >
                      setup and ready to go!</h1>
                    <p class="mb-0 text-sm text-body font-weight-light text-center">
                      If you need to make changes to any of the setup items, you can find them in the Company settings tab.
                    </p>
                    
                  </div>
                  <div class="d-flex mt-5">
                    
                    <v-btn
                      :ripple="false"
                      :elevation="0"
                      class="
                        font-weight-bold
                        text-xs
                        bg-gradient-success
                        py-5
                        px-6
                        mt-6
                        mb-2
                        me-2
                        ms-auto
                        shadow-none
                      "
                      color="primary"
                      @click="compleStep()"
                    >
                      Finish
                    </v-btn>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>


        </v-card-text>
       
        <v-card-actions>

        </v-card-actions>
      </v-card>
    </v-dialog>




    <v-card
      class="pa-0 border-radius-lg mt-7 mb-9 mx-4"
      :style="`background-image: url(${require('../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
    >
      <span
        class="mask opacity-8 border-radius-lg"
        :class="`bg-gradient-` + sidebarColor"
      ></span>
    </v-card>
  </v-navigation-drawer>
</template>
<script>
import { isValidElement } from 'preact';

export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "dark",
    },
  },
  created () {
        let self = this;
        let token = localStorage.getItem('token');
        let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        // if(this.role.name != 'super_admin' && role.name != 'company_admin')
        //   this.getUserProfile();
        this.getLinks();
        this.menuActive()
        const body = document.getElementsByTagName("body")[0];
        // if(this.role.name != 'super_admin' || role.name == 'company_admin' && localStorage.getItem('agreement_flag') == 1 && localStorage.getItem('skip_startup') != 1 &&  localStorage.getItem('completed_startup') != 1)
        //   this.dialog = true;
      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }

  },
  data: () => ({
    mini: false,
    e1: 2,
    togglerActive: false,
    userInfo_active : false,
    dialog: false,
    company: '',
    countMessage: 0,
    preview: '',
    previewbg: '',
    dashboard_active : false,
    promo: {
          name : '',
          type : 'Paypal',
          credentials : {
            api_user :  "",
            api_password : "",
            api_signature : ""
          },
        },
    company_name : localStorage.getItem('company_name'),
    company_id: localStorage.getItem('company_id'),
    plan : localStorage.getItem('plan') ,
    name : localStorage.getItem('name'),
    email : localStorage.getItem('email'),
    user_permissions : localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : [],
    avatar : localStorage.getItem('avatar') ? localStorage.getItem('avatar') : '',
    company_logo : localStorage.getItem('company_logo'),
    credit : localStorage.getItem('credit') ? localStorage.getItem('credit') : 0,
    userDetails :[],
    links : [],
    role: JSON.parse(localStorage.getItem('role')),
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    stateOptions: [
                { name: 'ALABAMA', abbreviation: 'AL'},
                { name: 'ALASKA', abbreviation: 'AK'},
                { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
                { name: 'ARIZONA', abbreviation: 'AZ'},
                { name: 'ARKANSAS', abbreviation: 'AR'},
                { name: 'CALIFORNIA', abbreviation: 'CA'},
                { name: 'COLORADO', abbreviation: 'CO'},
                { name: 'CONNECTICUT', abbreviation: 'CT'},
                { name: 'DELAWARE', abbreviation: 'DE'},
                { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
                { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
                { name: 'FLORIDA', abbreviation: 'FL'},
                { name: 'GEORGIA', abbreviation: 'GA'},
                { name: 'GUAM', abbreviation: 'GU'},
                { name: 'HAWAII', abbreviation: 'HI'},
                { name: 'IDAHO', abbreviation: 'ID'},
                { name: 'ILLINOIS', abbreviation: 'IL'},
                { name: 'INDIANA', abbreviation: 'IN'},
                { name: 'IOWA', abbreviation: 'IA'},
                { name: 'KANSAS', abbreviation: 'KS'},
                { name: 'KENTUCKY', abbreviation: 'KY'},
                { name: 'LOUISIANA', abbreviation: 'LA'},
                { name: 'MAINE', abbreviation: 'ME'},
                { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
                { name: 'MARYLAND', abbreviation: 'MD'},
                { name: 'MASSACHUSETTS', abbreviation: 'MA'},
                { name: 'MICHIGAN', abbreviation: 'MI'},
                { name: 'MINNESOTA', abbreviation: 'MN'},
                { name: 'MISSISSIPPI', abbreviation: 'MS'},
                { name: 'MISSOURI', abbreviation: 'MO'},
                { name: 'MONTANA', abbreviation: 'MT'},
                { name: 'NEBRASKA', abbreviation: 'NE'},
                { name: 'NEVADA', abbreviation: 'NV'},
                { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
                { name: 'NEW JERSEY', abbreviation: 'NJ'},
                { name: 'NEW MEXICO', abbreviation: 'NM'},
                { name: 'NEW YORK', abbreviation: 'NY'},
                { name: 'NORTH CAROLINA', abbreviation: 'NC'},
                { name: 'NORTH DAKOTA', abbreviation: 'ND'},
                { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
                { name: 'OHIO', abbreviation: 'OH'},
                { name: 'OKLAHOMA', abbreviation: 'OK'},
                { name: 'OREGON', abbreviation: 'OR'},
                { name: 'PALAU', abbreviation: 'PW'},
                { name: 'PENNSYLVANIA', abbreviation: 'PA'},
                { name: 'PUERTO RICO', abbreviation: 'PR'},
                { name: 'RHODE ISLAND', abbreviation: 'RI'},
                { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
                { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
                { name: 'TENNESSEE', abbreviation: 'TN'},
                { name: 'TEXAS', abbreviation: 'TX'},
                { name: 'UTAH', abbreviation: 'UT'},
                { name: 'VERMONT', abbreviation: 'VT'},
                { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
                { name: 'VIRGINIA', abbreviation: 'VA'},
                { name: 'WASHINGTON', abbreviation: 'WA'},
                { name: 'WEST VIRGINIA', abbreviation: 'WV'},
                { name: 'WISCONSIN', abbreviation: 'WI'},
                { name: 'WYOMING', abbreviation: 'WY' }
            ],
    userInfo: [
      {
        title: "My Profile",
        link : '/admin/my-profile'
      },
      {
        title: "Logout",
        link : '#'
      },
    ],
    userInfoCompany: [
      {
        title: "My Profile",
        link : '/admin/my-profile'
      },
      {
        title: "Logout",
        link : '#'
      },
    ],

   
    itemsPages: [
      {
        action: "domain",
        active: false,
        title: "Company",
        items: [
          // {
          //   title: "Company Profiles",
          //   link: "/admin/company-profiles",
          // },
          // {
          //   title: "Add New Company",
          //   link: "/admin/add-company",
          // },
          {
            title: "Templates",
            link : '/admin/contract-templates'
          },
          {
            title: "Subscription Settings",
            link : '/admin/subscription-settings'
          },
          {
            title: "Payment Gateway",
            link : '/admin/payment-gateway'
          },
          {
            title: "Settings",
            link : '/admin/settings'
          },
          {
            title: "Conversation Settings",
            link : '/admin/conversation-settings'
          },
          
        ],
      },
      {
        action: "groups",
        active: false,
        title: "Client",
        items: [
          // {
          //   title: "Client Settings",
          //   link: "/admin/client-settings",
          // },
          {
            title: "Manage Client",
            link: "/admin/client-profiles",
          },
          {
            title: "Add New Client",
            link: "/admin/add-client",
          },
          {
            title: "Manage Rental Booking",
            link: "/admin/rental-bookings",
          },
          {
            title: "Agreements",
            link: "/admin/agreements",
          },
          {
            title: "Reviews",
            link: "/admin/reviews",
          },
          // {
          //   title: "Requests",
          //   link: "/admin/requests",
          // },
          // {
          //   title: "Certified Letters",
          //   link: "/admin/certified-letters",
          // },
          // {
          //   title: "Prodigy Work Area",
          //   link: "/admin/prodigy-letters",
          // },
        ],
      },
      // {
      //   action: "email",
      //   active: false,
      //   title: "Mailing",
      //   items: [
      //     {
      //       title: "Inbox",
      //       link: "/admin/mailing",
      //     },
      //     {
      //       title: "Cart",
      //       link: "/admin/cart",
      //     },
          
      //   ],
      // },
      // {
      //   action: "fax",
      //   active: false,
      //   title: "Faxes",
      //   items: [
      //     {
      //       title: "Sent Faxes",
      //       link: "/admin/fax",
      //     },
      //     {
      //       title: "Queued Faxes",
      //       link: "/admin/queued-fax",
      //     },
          
      //   ],
      // },
      // {
      //   action: "share",
      //   active: false,
      //   title: "Affiliates",
      //   items: [
      //     {
      //       title: "Affiliate Links",
      //       link: "/admin/affiliates",
      //     },
          
          
      //   ],
      // },
       {
        action: "public",
        active: false,
        title: "Custom Links",
        items: [
        
        ],
      },
     
    ],
    itemsPagesCompany: [
      {
        action: "domain",
        active: false,
        title: "Company",
        items: [
          {
            title: "Users",
            link: "/admin/company-users",
          },
          // {
          //   title: "Templates",
          //   link: "/admin/contract-templates",
          // },
          // {
          //   title: "Manage Subscription",
          //   link : '/admin/manage-subscription'
          // },
          // {
          //   title: "Transaction History",
          //   link : '/admin/credit-history'
          // },
          {
            title: "Payment Gateway",
            link : '/admin/payment-gateway-company'
          },
          {
            title: "Settings",
            link : '/admin/company-settings'
          },
          // {
          //   title: "Conversation Settings",
          //   link : '/admin/conversation-settings'
          // },
          {
            title: "Custom Domain",
            link : '/admin/domain'
          },
        ],
      },
      {
        action: "groups",
        active: false,
        title: "Client",
        items: [
          
          {
            title: "Manage Client",
            link: "/admin/client-profiles",
          },
          {
            title: "Add New Client",
            link: "/admin/add-client",
          },
          
          
        ],
      },
     
     
    ],
  }),
  methods: {
    compleStep(){
      localStorage.setItem('completed_startup',1)
      this.dialog = false
    },
    menuActive(){
      let self = this;
      if(this.$route.path=='/admin/my-profile' || this.$route.path=='/admin/settings'){
        this.userInfo_active = true;
      }else if(this.$route.path=='/admin/dashboard'){
        this.dashboard_active = true;
      }else{
        if(this.role.name =='super_admin' || role.name == 'company_admin'){
          this.itemsPages.forEach(val => {
            val.items.forEach(val2 => {
              if(val2.link == self.$route.path){
                val.active = true;
              }
            })
          })
        }else{
          this.itemsPagesCompany.forEach(val => {
            val.items.forEach(val2 => {
              if(val2.link == self.$route.path){
                val.active = true;
              }
            })
          })
        }
      }
    },
    addPromo(){
            let self = this;
              this.loadingAssign = true;
              
              axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/add-payment-gateway",
          data: this.promo
        })
        
          .then(response => {
              let data = response.data;

              if (response.data) {
                  Vue.$toast.success('Payment gateway added successfully', {
                      position: 'top-right'
                      });
                      self.company.payment_gateway_id = data.id
                      
                      self.saveProfile(5);
                  }else {
                      Vue.$toast.error(data.message, {
                      position: 'top-right'
                      });
                  }

              })
              .catch(err => {
              Vue.$toast.error(err.message, {
              position: 'top-right'
              });
              this.loadingAssign = false;
              })
          
            
      },
      checkPermission(module){
        if(this.role.name =='super_admin')
          return true;
        if(this.user_permissions.length > 0){
          let flag = 0;
          this.user_permissions.forEach(val => {
            if(val.module.name == module)
              flag = true;
          })
          return flag;
        }else{
          return false;
        }
      },
    saveProfile(step) {
      let self = this;
      let flag = 1;
      let id  = localStorage.getItem('company_id');
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.company.name) {
            Vue.$toast.error('Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_email && flag == 1) {
            Vue.$toast.error('Contact email is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.company.contact_phone && flag == 1) {
            Vue.$toast.error('Contact phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        
        if (flag == 1) {
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/edit-company/"+id,
          data: self.company
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
              self.e1 = step
                  // Vue.$toast.success('Company edited', {
                  //   position: 'top-right'
                  // })
                  // this.$router.push('/company-profiles')
                

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },
    skipStartUp(){
      localStorage.setItem('skip_startup',1)
      this.dialog = false
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        this.company.logo = input.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        }
        this.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    logout() {
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("is_admin");
            this.$router.push("/admin");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            this.$router.push("/admin");
            location.reload();
        });
    },
    getLinks(){
      let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .post("/api/get-all-link",{user_id : localStorage.getItem('user_id')}, {headers: headers})
        .then(response => {
           this.countMessage = response.data.data

           
        })
    },
    uploadLogo(){
      const data = new FormData();
        data.append('file_logo', this.company.logo);
        axios({
          method: "POST",
          url: "/api/upload-logo",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Logo uploaded", {
              position: 'top-right'
              });
              localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+response.data.data)
          this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      
    },
    getAddressData: function (addressData, placeResultData, id) {
            this.company.address_1 = addressData.name;
            this.company.city = addressData.locality;
            this.company.state = addressData.administrative_area_level_1;
            this.company.zip = addressData.postal_code
        },
    getUserProfile() {
    let flag = 1;
    let id  = localStorage.getItem('company_id');
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/get-company/"+id,header)
        .then(response => {
            let data = response.data.data;
            
           if (flag == 1) {
             console.log(data);
                this.company = data
                this.preview = process.env.VUE_APP_WASBI_URL+data.logo
                this.previewbg = process.env.VUE_APP_WASBI_URL+data.logo_background
                localStorage.setItem('ghl_flag',this.company.ghl_flag)
                if(data.logo)
                  localStorage.setItem('company_logo',process.env.VUE_APP_WASBI_URL+data.logo)
                else
                  localStorage.setItem('company_logo','')
                localStorage.setItem('company_logo_bg',process.env.VUE_APP_WASBI_URL+data.logo_background)
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },
    gotoProfile: function () {
        this.$router.push({ name: "Profile" });
      },
      gotolink(id,item){
        if(item.is_iframe == 1)
          this.$router.push('/admin/custom-link/'+id)
        else
          window.open(item.link, "_blank");
        
      },
    gotodashboard(id){

    this.$router.push('/admin/'+id)

    },
    gotomail(){

    this.$router.push('/admin/mailing')

    },
    gotocart(){

    this.$router.push('/admin/cart')

    },
    gotofax(){

    this.$router.push('/admin/fax')

    },
    gotoqfax(){

    this.$router.push('/admin/queued-fax')

    },
    gotoaffi(){

    this.$router.push('/admin/affiliates')

    },
    
    gotosettings(){
      this.$router.push('/admin/settings')
  },gotoremove(){
      this.$router.push('/remove-info')
    },
    checkLink(item){
      console.log(item);
      if(item.sidebar == 1){
        
        if(this.role.name == 'super_admin' || role.name == 'company_admin' || item.all_account == 1){
          return true;
        }else{
          console.log(item.list_account);
          let arr = JSON.parse(item.list_account);
          console.log(arr);
          if(arr.includes(this.company_id))
            return true;
          else
            return false;
        }
      }
      return false;
    },
    selectSection(index) {
        // this.selectedSection = item;
        if(index==0){
           console.log('EMAIL');
        }
        if(index==1){
          this.$router.push('/profile');
        }
        if(index==2){
          this.logout();
        }

    },

  },
    listClose(event) {
      let items;
      let headers;
      let groups;
      let currentEl;

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        )
      ) {
        items = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link.item-active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        )
      ) {
        headers = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group__header.v-list-item--active"
        );
      }

      if (
        document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        )
      ) {
        groups = document.querySelectorAll(
          ".mb-0.v-list-item.v-list-item--link .v-list-group .v-list .v-list-group.v-list-group--active, .mb-0.v-list-item.v-list-item--link .v-list-group.v-list-group--active"
        );
      }

      if (
        event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        )
      ) {
        currentEl = event.target.closest(
          ".mb-0.v-list-item.v-list-item--link .v-list-item__content.ms-6 .v-list-group"
        );
      }

      if (items != null) {
        for (var i = 0; i < items.length; i++) {
          items[i].classList.remove("item-active");
        }
      }

      if (headers != null) {
        for (var j = 0; j < headers.length; j++) {
          headers[j].classList.remove(
            "v-list-item--active",
            "item-active",
            "primary--text"
          );
          headers[j].setAttribute("aria-expanded", false);
        }
      }

      if (groups != null) {
        for (var k = 0; k < groups.length; k++) {
          groups[k].classList.remove("v-list-group--active", "primary--text");
        }
      }

      if (event.target.closest(".mb-0.v-list-item.v-list-item--link")) {
        event.target
          .closest(".mb-0.v-list-item.v-list-item--link")
          .classList.add("item-active");
      }

      if (currentEl != null) {
        currentEl
          .querySelector(".v-list-group__header")
          .classList.add("v-list-item--active", "item-active");
      }
  },
};
</script>
<style>
.v-stepper--alt-labels .v-stepper__step__step {
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
  height: 25px;
  width: 25px;
}
.v-stepper__step {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 10px;
  position: relative;
}
.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 52px -65px 0!important;
  align-self: flex-start;
}
.badge-chat .v-badge__badge{
  margin: 0px 0px 0px 13px!important;position: initial!important;
}
</style>