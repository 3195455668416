<template>
    <v-app class="bg-white">
    <v-main class="auth-pages"  >
        <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 10px;" >
                  <img :height="this.$vuetify.breakpoint.xs ? 200 : 200" class="image-logo2" src="@/assets/img/logo.png">
            </v-col>
            <div class="terms-conditions container">
              <h1 class="text-center">AutoSyncX™️ Terms and Conditions</h1>
              <p class="last-updated">Last Updated: February 1st 2025</p>
              <p>
                Welcome to AutoSyncX™️! These Terms and Conditions govern your use of our platform, which enables users to earn commissions by referring individuals to purchase or rent vehicles. By signing up and using AutoSyncX™️, you agree to comply with these terms. If you do not agree, please do not use our platform.
              </p>
          
              <section>
                <h2>1. Introduction</h2>
                <p>
                  AutoSyncX™️ ("Company," "we," "our," or "us") provides a referral platform where users can earn commissions by referring individuals to buy or rent vehicles. Each referrer is provided with a unique digital card (e.g., <code>david.autosyncx.com</code>) that tracks their referrals and commissions through a personal dashboard.
                </p>
              </section>
          
              <section>
                <h2>2. Eligibility</h2>
                <ul>
                  <li>Be 18 years or older (or the legal age in your jurisdiction).</li>
                  <li>Have the legal capacity to enter into agreements.</li>
                  <li>Provide accurate and up-to-date information during registration.</li>
                  <li>Comply with all applicable laws regarding referrals, car sales, and rentals.</li>
                </ul>
                <p>
                  AutoSyncX™️ reserves the right to suspend or terminate accounts if we suspect fraudulent activity, misrepresentation, or misuse of the platform.
                </p>
              </section>
          
              <section>
                <h2>3. Referral Program & Commissions</h2>
                <ul>
                  <li>Each registered user receives a unique digital referral card linked to a custom subdomain (e.g., <code>david.autosyncx.com</code>).</li>
                  <li>Users earn commissions when someone buys or rents a car through their referral link.</li>
                  <li>All transactions are tracked and recorded in the referrer’s dashboard.</li>
                  <li>Commissions are determined by AutoSyncX™️ and subject to change at any time.</li>
                  <li>Payouts are processed on a [frequency, e.g., bi-weekly/monthly] basis, with a minimum withdrawal amount of [$X].</li>
                  <li>Referrers are responsible for any applicable taxes on their earnings.</li>
                </ul>
              </section>
          
              <section>
                <h2>4. Payments & Payouts</h2>
                <ul>
                  <li>Payouts are made via [payment method, e.g., direct deposit, PayPal, check].</li>
                  <li>Processing times vary, but payments are typically completed within [X] business days.</li>
                  <li>Users must provide accurate payment information to avoid payout delays.</li>
                  <li>AutoSyncX™️ is not liable for issues related to incorrect payment details or banking errors.</li>
                </ul>
              </section>
          
              <section>
                <h2>5. Account Usage & Termination</h2>
                <ul>
                  <li>Users must maintain accurate and updated account information.</li>
                  <li>Any attempt to manipulate referrals or engage in fraudulent activities will result in account suspension or termination.</li>
                  <li>AutoSyncX™️ reserves the right to terminate accounts at our discretion for violating these Terms.</li>
                </ul>
                <p>
                  If an account is terminated, unpaid commissions may be forfeited.
                </p>
              </section>
          
              <section>
                <h2>6. Liability & Disclaimer</h2>
                <ul>
                  <li>AutoSyncX™️ only facilitates referrals; we are not responsible for any issues related to the sale, rental, or delivery of vehicles.</li>
                  <li>We do not guarantee that every referral will result in a commission.</li>
                  <li>Users assume all risks related to promoting and referring customers.</li>
                  <li>AutoSyncX™️ is not liable for lost earnings, technical errors, or third-party service failures.</li>
                </ul>
              </section>
          
              <section>
                <h2>7. Modifications & Updates</h2>
                <p>
                  AutoSyncX™️ reserves the right to update these Terms and Conditions at any time. Users will be notified of major changes, but it is their responsibility to review updates. Continued use of the platform after updates signifies acceptance of the new terms.
                </p>
              </section>
          
              <section>
                <h2>8. Contact Information</h2>
                <p>
                  For any questions or concerns regarding these Terms and Conditions, please contact us:
                </p>
                <ul>
                  <li><strong>Phone (Toll-Free):</strong> 888-404-2675</li>
                  <li><strong>Phone (Local):</strong> 602-714-7770</li>
                  <li>
                    <strong>Email:</strong>
                    <a href="mailto:support@autosyncx.com">support@autosyncx.com</a>
                  </li>
                </ul>
              </section>
            </div>
    </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'TermsAndConditions',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .terms-conditions h1, .terms-conditions h2 {
    color: #333;
    margin-bottom: 10px;
  }
  
  .terms-conditions p {
    margin-bottom: 20px;
    line-height: 1.6;
    color: #555;
  }
  
  .terms-conditions a {
    color: #1a73e8;
    text-decoration: none;
  }
  
  .terms-conditions a:hover {
    text-decoration: underline;
  }
  </style>
  <style scoped>
.privacy-policy {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

.privacy-policy h1 {
  text-align: center;
  margin-bottom: 10px;
}

.last-updated {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
}

.privacy-policy section {
  margin-bottom: 20px;
}

.privacy-policy ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>