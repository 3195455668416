<template>
<v-app id="inspire" style="background:#F9F9F9;">

<vue-header-admin></vue-header-admin>
  <v-main >
    <v-container style="margin-bottom: 72px;" fluid class="px-6 py-6">
      <Chat />
     
    </v-container>
    <vue-footer-admin></vue-footer-admin>
    </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import HtmlEditor from "../HtmlEditor.vue";
import Sortable from 'sortablejs';
import Chat from '../Forms/Chat.vue';
export default {
components: {
      VueTelInput,HtmlEditor,Chat
  },
  data () {

      return {
       

      }
    },
    directives: {
    sortableDataTable: {
      bind (el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            vnode.child.$emit('sorted', event)
          }
        }
        Sortable.create(el.getElementsByTagName('tbody')[0], options)
      }
    }
  },
    methods: {
      

 },
 
watch: {
  dialogDeleteBasic (val) {
        val || this.closeDeleteBasic()
      },
    searchIcon: function(oldSearchIcon, newSearchIcon) {
    let filteredIcons = [];
    if (this.searchIcon.length === 0) {
      filteredIcons = this.allIcons;
    } else {
      filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
    }
    this.allIcons = filteredIcons;
  },
  datatable_options: {
                handler () {
                    console.log(this.datatable_options);
                this.getProducts()
                },
                deep: true,
            },
  datatable_options_coupons : {
    handler () {
                this.getCoupons()
                },
                deep: true,
  },
  datatable_options_fa : {
    handler () {
                this.getFA()
                },
                deep: true,
  },
  datatable_options_l : {
    handler () {
                this.getL()
                },
                deep: true,
  }
},
created() {
  if(!localStorage.getItem('token')){
    this.$router.push('/login')
    }else{
      this.getSettings()
      this.getAllClients()
      this.fetchChats()
    }
      
    },

}
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}

</style>