<template>
<v-app id="inspire">

<v-main style="padding:0px!important;">
  <v-col cols="12" style="display: flex; justify-content: space-around;margin-top: 10px;" >
    <img :height="this.$vuetify.breakpoint.xs ? 200 : 200" class="image-logo2" src="@/assets/img/logo.png">
</v-col>
    <v-container style="margin-top:10px;margin-bottom: 72px;">
      <div class="privacy-policy ">
        <h1 class="text-center">AutoSyncX™️ Privacy Policy</h1>
        <p class="last-updated text-center mt-5">Last Updated: February 1st 2025</p>
        <p class="mt-5">
          At AutoSyncX™️, we value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data when you use our platform.
        </p>
    
        <section>
          <h2>1. Information We Collect</h2>
          <p>When you sign up and use AutoSyncX™️, we may collect the following types of information:</p>
          <ul>
            <li>
              <strong>Personal Information:</strong> Name, email address, phone number, payment details.
            </li>
            <li>
              <strong>Referral Data:</strong> Transactions, earnings, and referral history linked to your unique digital card.
            </li>
            <li>
              <strong>Usage Information:</strong> Website interactions, login activity, and engagement with AutoSyncX™️ services.
            </li>
            <li>
              <strong>Device & Technical Data:</strong> IP address, browser type, and cookies for tracking referrals.
            </li>
          </ul>
        </section>
    
        <section>
          <h2>2. How We Use Your Data</h2>
          <p>We collect and process your data to:</p>
          <ul>
            <li>Track and record referral activity.</li>
            <li>Process commission payouts.</li>
            <li>Improve platform security and user experience.</li>
            <li>Communicate with you about updates, support, or promotions.</li>
            <li>Comply with legal and financial obligations.</li>
          </ul>
        </section>
    
        <section>
          <h2>3. Data Protection & Security</h2>
          <p>
            AutoSyncX™️ implements security measures to protect user data from unauthorized access, disclosure, or breaches. However, users should also take precautions by keeping their login credentials secure.
          </p>
        </section>
    
        <section>
          <h2>4. Sharing of Information</h2>
          <p>
            We do not sell your data to third parties. However, we may share information with:
          </p>
          <ul>
            <li>Payment processors to complete commission payouts.</li>
            <li>Service providers for technical support and platform operations.</li>
            <li>Legal authorities when required by law.</li>
          </ul>
        </section>
    
        <section>
          <h2>5. User Rights & Data Control</h2>
          <p>As an AutoSyncX™️ user, you have the right to:</p>
          <ul>
            <li>Access, update, or delete your personal data.</li>
            <li>Request a copy of your referral and earnings history.</li>
            <li>Opt-out of marketing emails or data tracking.</li>
          </ul>
          <p>
            To make a request, contact <a href="mailto:support@autosyncx.com">support@autosyncx.com</a>.
          </p>
        </section>
    
        <section>
          <h2>6. Cookies & Tracking Technologies</h2>
          <p>AutoSyncX™️ uses cookies to:</p>
          <ul>
            <li>Track referral activity.</li>
            <li>Improve user experience.</li>
            <li>Analyze platform performance.</li>
          </ul>
          <p>
            Users can manage cookie preferences in their browser settings.
          </p>
        </section>
    
        <section>
          <h2>7. Policy Updates</h2>
          <p>
            We may update this Privacy Policy as needed. Users will be notified of significant changes, and continued use of AutoSyncX™️ means acceptance of the updated policy.
          </p>
        </section>
    
        <section>
          <h2>8. Contact Information</h2>
          <p>For privacy-related questions or requests, contact us:</p>
          <ul>
            <li><strong>Phone (Toll-Free):</strong> 888-404-2675</li>
            <li><strong>Phone (Local):</strong> 602-714-7770</li>
            <li>
              <strong>Email:</strong>
              <a href="mailto:support@autosyncx.com">support@autosyncx.com</a>
            </li>
          </ul>
        </section>
      </div>
    </v-container>
    
     <vue-footer></vue-footer>
     </v-main>
</v-app>

</template>

<script>
import { VueTelInput } from 'vue-tel-input'
import AvatarCropper from 'vue-avatar-cropper'
export default {
components: {
      VueTelInput,AvatarCropper
  },
  data () {

      return {
        fname : '',
        lname : '',
        url: '',
        email : '',
        name:'',
        html :'',
        phone:'',
        password : '',
        is_admin:0,
        confpassword : '',
        cropperOutputMime : '',
        changeTrigger : false,
        preview: '',
        role : '',
        loadingUpload: false,
        user: [],
        companyname : '',
        sector : '',
        company :'',
        isDark: true,
        loading:false,
        isDisable :false,
        no_of_employee : '',
        showPassword : false,
        file_flag : {
          agreement : {
                          name : '',
                          id :''
                        },
          id :{
                          name : '',
                          id :''
                        },
          bill :{
                          name : '',
                          id :''
                        },
          letter_a : {
                          name : '',
                          id :''
                        },
          letter_b : {
                          name : '',
                          id :''
                        },
           letter_c : {
                          name : '',
                          id :''
                        },
        },
        phoneFlag:0,
        emailFlag:0,
        files : {
          agreement : [],
          id : [],
          bill:[],
          letter_a : [],
          letter_b : [],
          letter_c : []
        },
        country :'',
        otp_email:'',
        otp_phone:'',
        company : {
          name: "",
          contact_email: "",
          contact_phone: "",
          details:"",
          email: "",
          password: "",
          password_confirmation: ""

        },
        otp_phone_input:"",
        otp_email_input:"",
        user_phone:'',
        user_email:'',
        status:0,

        tab: null,
        headers: [

          { text: '', value: 'CompanyURL' },
          { text: '', value: 'CompanyName' },

        ],
        history: [

        ],

        setting: [
        ],

        items: [
              'Admin',
              'Viewer',
      ],
      select_role : 'Admin',
       sectors: [

      ],
      employeeNumber: [
              '10-50',
              '50-100',
              'More than 100',

      ],

      }
    },

    methods: {
      getCompanyInfo(url){
  axios({
          method: "POST",
           
          url: "/api/get-company-info",
          data: {
            url: url
          }
        })
          .then(response => {
            console.log(response.data.company);
            // this.company = response.data.company;
            if(response.data.company){
              if(response.data.company.privacy_template)
                this.html = response.data.company.privacy_template
              else
                this.html = `<p>&nbsp;</p>
<div id="__nuxt">
<div>
<div class="bgCover bg-fixed">&nbsp;</div>
<!--[-->
<div>
<div>
<div id="nav-menu-popup" class="hide" style="display: none;">
<div class="nav-menu-body">
<ul class="nav-menu"><!--[--><!--]--></ul>
</div>
</div>
</div>
<div id="overlay" class="hide" style="display: none; background-color: rgba(0, 0, 0, 0.5);"></div>
<div id="preview-container" class="preview-container hl_page-preview--content">
<div data-v-0bad29cf=""><!--[--><!--[-->
<div id="section-vBbNog7qm" class="fullSection none noBorder radius0 none c-section c-wrapper section-vBbNog7qm" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="row-rMZ02OgOgw" class="row-align-center none noBorder radius0 none c-row c-wrapper row-rMZ02OgOgw" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-XpfmHWfw04" class="c-column c-wrapper col-XpfmHWfw04" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-OS0Z2LcqUu" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-OS0Z2LcqUu text-output cheading-OS0Z2LcqUu none noBorder radius0 none">
<div>
<h1><strong>TERMS AND CONDITIONS</strong></h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="divider-gC6AJZLS0" class="c-divider c-wrapper divider-gC6AJZLS0" data-v-0bad29cf="">
<div id="divider-gC6AJZLS0" class="divider-element cdivider-gC6AJZLS0"></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--[-->
<div id="row-MslS67eTG3" class="row-align-center none noBorder radius0 none c-row c-wrapper row-MslS67eTG3" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-rtGjwWF9Mb" class="c-column c-wrapper col-rtGjwWF9Mb" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-MnPTHJF6HW" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-MnPTHJF6HW text-output cheading-MnPTHJF6HW none noBorder radius0 none">
<div>
<p><strong><u>Last Revised: March 27, 2022</u></strong></p>
<h1>&nbsp;</h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="heading-4NMhoydBW" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-4NMhoydBW text-output cheading-4NMhoydBW none noBorder radius0 none">
<div>
<h1><strong>E-SIGN, the Electronic Signatures in Global and National Commerce Act (15 U.S.C. &sect; 7001, et seq.), requires that you consent to entering into an electronic agreement with us before an online version of our software Please read the following information carefully.</strong></h1>
<p>&nbsp;</p>
<p><strong>Should you enter into an online Member Agreement (&ldquo;the Agreement&rdquo;), you will not be required to submit a paper application. The entire agreement between you and us will be evidenced by an electronic record. To enter into the Agreement, you must additionally electronically acknowledge that you agree to the Agreement Terms and Conditions, and the General Service Agreement. To access these documents and enter into the Agreement, you will need the following hardware and software: A Personal Computer (&ldquo;PC&rdquo;) with a modem or other Internet access device, operational Internet browser software (e.g., Mozilla Firefox, Google Chrome, Safari, or Internet Explorer), and Adobe Acrobat Reader. Should there ever be a change in the equipment or software necessary to access the terms of the Agreement, we will advise you of the same and will provide you with a list of the equipment and software that is necessary. Upon such an event, you may withdraw your consent.</strong></p>
</div>
</div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--[-->
<div id="row-Fbj37cn7-I" class="row-align-center none noBorder radius0 none c-row c-wrapper row-Fbj37cn7-I" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-eFRSEzZQz" class="c-column c-wrapper col-eFRSEzZQz" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-3KhsG4GtC" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-3KhsG4GtC text-output cheading-3KhsG4GtC none noBorder radius0 none">
<div>
<h1><strong>General Service Agreement</strong></h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="heading-9YJcrbxU_" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-9YJcrbxU_ text-output cheading-9YJcrbxU_ none noBorder radius0 none">
<div>
<p>This <strong>General Service Agreement</strong> (the "Agreement") is in full agreement with YOU. When YOU agree and sign, we mach your date and time stap with this aggrent.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-5d21GLi8k" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-5d21GLi8k text-output csub-heading-5d21GLi8k none noBorder radius0 none">
<div>
<h2><strong>BACKGROUND</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-adDn1WpnH" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-adDn1WpnH text-output cparagraph-adDn1WpnH none noBorder radius0 none">
<div>
<p><strong>A.</strong> The Client is of the opinion that the Contractor has the necessary qualifications, experience, and abilities to provide services to the Client.</p>
<p>&nbsp;</p>
<p><strong>B.</strong> The Contractor is agreeable to providing such services to the Client on the terms and conditions set out in this Agreement.</p>
<p>&nbsp;</p>
<p><strong>IN CONSIDERATION OF</strong> the matters described above and of the mutual benefits and obligations set forth in this Agreement, the receipt and sufficiency of which consideration is hereby acknowledged, the Client and the Contractor (individually the "Party" and collectively the "Parties" to this Agreement) agree as follows:</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-J8hadJfNU" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-J8hadJfNU text-output csub-heading-J8hadJfNU none noBorder radius0 none">
<div>
<h2><strong>SERVICES PROVIDED</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-PJG3Kavyp" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-PJG3Kavyp text-output cparagraph-PJG3Kavyp none noBorder radius0 none">
<div>
<p><strong>1</strong>. The client hereby agrees to engage the contractor to provide the client with the following services (the "Services"):</p>
<ul>
<li><strong>Grant Writing Services.</strong></li>
<li><strong>Grant Portal to track your grants</strong></li>
</ul>
<p>&nbsp;</p>
<p><strong>2.</strong> The Services will also include any other tasks which the Parties may agree on. The Contractor hereby agrees to provide such Services to the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-oBJiXdJ8e" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-oBJiXdJ8e text-output csub-heading-oBJiXdJ8e none noBorder radius0 none">
<div>
<h2><strong>TERM OF AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-DK5xA7dFi" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-DK5xA7dFi text-output cparagraph-DK5xA7dFi none noBorder radius0 none">
<div>
<p><strong>3.</strong> The term of this Agreement (the "Term") will begin on the date of this Agreement and will remain in full force and effect until the completion of the Services, subject to earlier termination as provided in this Agreement. The Term may be extended with the written consent of the Parties.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-wWXsgrbi6" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-wWXsgrbi6 text-output csub-heading-wWXsgrbi6 none noBorder radius0 none">
<div>
<h2><strong>PERFORMANCE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-78PcXc7yi" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-78PcXc7yi text-output cparagraph-78PcXc7yi none noBorder radius0 none">
<div>
<p><strong>4. </strong>The Parties agree to do everything necessary to ensure that the terms of this Agreement take effect.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-c6O7nzqCV" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-c6O7nzqCV text-output csub-heading-c6O7nzqCV none noBorder radius0 none">
<div>
<h2><strong>CURRENCY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-Vtl1H1PGY" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-Vtl1H1PGY text-output cparagraph-Vtl1H1PGY none noBorder radius0 none">
<div>
<p>5. Except as otherwise provided in this Agreement, all monetary amounts referred to in this Agreement are in USD (US Dollars).</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-C3zlfbcgZ" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-C3zlfbcgZ text-output csub-heading-C3zlfbcgZ none noBorder radius0 none">
<div>
<h2><strong>COMPENSATION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-WyYtY-IAS" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-WyYtY-IAS text-output cparagraph-WyYtY-IAS none noBorder radius0 none">
<div>
<p><strong>6.</strong> The Contractor will charge the client a flat fee of <strong>$350.00</strong> for portal access and tracking of grant services Services (the "Compensation").</p>
<p>&nbsp;</p>
<p><strong>7.</strong> The Contractor will invoice the Client when the full portal access.</p>
<p>&nbsp;</p>
<p><strong>8.</strong> Invoices submitted by the Contractor to the Client are due upon receipt.</p>
<p>&nbsp;</p>
<p><strong>9.</strong> The Contractor will not be reimbursed for any expenses incurred in connection with providing the Services under this Agreement.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-V72eIPNZ2" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-V72eIPNZ2 text-output csub-heading-V72eIPNZ2 none noBorder radius0 none">
<div>
<h2><strong>CONFIDENTIALITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-kQFnDX1uP" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-kQFnDX1uP text-output cparagraph-kQFnDX1uP none noBorder radius0 none">
<div>
<p><strong>10.</strong> Confidential information (the "Confidential Information") refers to any data or information relating to the Client, whether business or personal, which would reasonably be considered to be private or proprietary to the Client and that is not generally known and where the release of that Confidential Information could reasonably be expected to cause harm to the Client.</p>
<p>&nbsp;</p>
<p><strong>11</strong>. The Contractor agrees that they will not disclose, divulge, reveal, report or use, for any purpose, any Confidential Information which the Contractor has obtained, except as authorized by the Client or as required by law. The obligations of confidentiality will apply during the Term and will survive indefinitely upon termination of this Agreement.</p>
<p>&nbsp;</p>
<p><strong>12.</strong> All written and oral information and material disclosed or provided by the Client to the Contractor under this Agreement is Confidential Information regardless of whether it was provided before or after the date of this Agreement or how it was provided to the Contractor.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-nuPNi9r_b" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-nuPNi9r_b text-output csub-heading-nuPNi9r_b none noBorder radius0 none">
<div>
<h2><strong>OWNERSHIP OF INTELLECTUAL PROPERTY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-C55u7slOp" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-C55u7slOp text-output cparagraph-C55u7slOp none noBorder radius0 none">
<div>
<p><strong>13</strong>. All intellectual property and related material (the "Intellectual Property") that is developed or produced under this Agreement will be the property of the Contractor. The Client is granted a non-exclusive limited-use license of this Intellectual Property.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>14.</strong> Title, copyright, intellectual property rights and distribution rights of the Intellectual Property remain exclusively with the Contractor.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-kTSAHBrzk" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-kTSAHBrzk text-output csub-heading-kTSAHBrzk none noBorder radius0 none">
<div>
<h2><strong>RETURN OF PROPERTY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-_4NChGxW5" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-_4NChGxW5 text-output cparagraph-_4NChGxW5 none noBorder radius0 none">
<div>
<p><strong>15.</strong> Upon the expiration or termination of this Agreement, the Contractor will return to the Client any property, documentation, records, or Confidential Information which is the property of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-iVuWtaVSU" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-iVuWtaVSU text-output csub-heading-iVuWtaVSU none noBorder radius0 none">
<div>
<h2><strong>CAPACITY/INDEPENDENT CONTRACTOR</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-2hUd_mzVz" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-2hUd_mzVz text-output cparagraph-2hUd_mzVz none noBorder radius0 none">
<div>
<p><strong>16.</strong> In providing the Services under this Agreement it is expressly agreed that the Contractor is acting as an independent contractor and not as an employee. The Contractor and the Client acknowledge that this Agreement does not create a partnership or joint venture between them, and is exclusively a contract for service. The Client is not required to pay, or make any contributions to, any social security, local, state or federal tax, unemployment compensation, workers' compensation, insurance premium, profit-sharing, pension or any other employee benefit for the Contractor during the Term. The Contractor is responsible for paying, and complying with reporting requirements for, all local, state and federal taxes related to payments made to the Contractor under this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Twtv_2TbH" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Twtv_2TbH text-output csub-heading-Twtv_2TbH none noBorder radius0 none">
<div>
<h2><strong>RIGHT OF SUBSTITUTION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-Q0vj2w3U_" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-Q0vj2w3U_ text-output cparagraph-Q0vj2w3U_ none noBorder radius0 none">
<div>
<p><strong>17. </strong>Except as otherwise provided in this Agreement, the Contractor may, at the Contractor's absolute discretion, engage a third party sub-contractor to perform some or all of the obligations of the Contractor under this Agreement and the Client will not hire or engage any third parties to assist with the provision of the Services.</p>
<p>-</p>
<p><strong>18.</strong> In the event that the Contractor hires a sub-contractor:</p>
<p>&nbsp;</p>
<p>✔The Contractor will pay the sub-contractor for its services and the Compensation will remain payable by the Client to the</p>
<p>contractor.</p>
<p>&nbsp;</p>
<p>✔For the purposes of the indemnification clause of this Agreement, the sub-contractor is an agent of the Contractor.contractor.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Ruq5XocbT" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Ruq5XocbT text-output csub-heading-Ruq5XocbT none noBorder radius0 none">
<div>
<h2><strong>AUTONOMY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-VlxUesti6" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-VlxUesti6 text-output cparagraph-VlxUesti6 none noBorder radius0 none">
<div>
<p><strong>19.</strong> Except as otherwise provided in this Agreement, the Contractor will have full control over working time, methods, and decision making in relation to provision of the Services in accordance with the Agreement. The Contractor will work autonomously and not at the direction of the Client. However, the Contractor will be responsive to the reasonable needs and concerns of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-BPXXGx9lH" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-BPXXGx9lH text-output csub-heading-BPXXGx9lH none noBorder radius0 none">
<div>
<h2><strong>EQUIPMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-8_LonhAsI" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-8_LonhAsI text-output cparagraph-8_LonhAsI none noBorder radius0 none">
<div>
<p><strong>20</strong>. Except as otherwise provided in this Agreement, the Contractor will provide at the Contractor &rsquo;s own expense, any and all tools, machinery, equipment, raw materials, supplies, workwear and any other items or parts necessary to deliver the Services in accordance with the Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-zdnl8xk_m" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-zdnl8xk_m text-output csub-heading-zdnl8xk_m none noBorder radius0 none">
<div>
<h2><strong>NO EXCLUSIVITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-ZO1KpLI3h" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-ZO1KpLI3h text-output cparagraph-ZO1KpLI3h none noBorder radius0 none">
<div>
<p><strong>21.</strong> The Parties acknowledge that this Agreement is non-exclusive and that either Party will be free, during and after the Term, to engage or contract with third parties for the provision of services similar to the Services.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-O9nZqmWW5" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-O9nZqmWW5 text-output csub-heading-O9nZqmWW5 none noBorder radius0 none">
<div>
<h2><strong>NOTICE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-snkDLAREe" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-snkDLAREe text-output cparagraph-snkDLAREe none noBorder radius0 none">
<div>
<p><strong>22</strong>. All notices, requests, demands, or other communications required or permitted by the terms of this agreement will be given through Email and delivered to the Parties at the following Email addresse: <strong>Support@superiorsolutions.com</strong></p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Euj4y75Hs" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Euj4y75Hs text-output csub-heading-Euj4y75Hs none noBorder radius0 none">
<div>
<h2><strong>INDEMNIFICATION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-pqQRS_kN1" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-pqQRS_kN1 text-output cparagraph-pqQRS_kN1 none noBorder radius0 none">
<div>
<p><strong>23.</strong> Except to the extent paid in settlement from any applicable insurance policies, and to the extent permitted by applicable law, each Party agrees to indemnify and hold harmless the other Party, and its respective affiliates, officers, agents, employees, and permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from or arise out of any act or omission of the indemnifying party, its respective affiliates, officers, agents, employees, and permitted successors and assigns that occurs in connection with this Agreement. This indemnification will survive the termination of this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-9B0oI0puM" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-9B0oI0puM text-output csub-heading-9B0oI0puM none noBorder radius0 none">
<div>
<p><strong>ADDITIONAL CLAUSES</strong></p>
<h2><strong>24. </strong>No Refunds.<strong> </strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-KgmhkJVnc" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-KgmhkJVnc text-output cparagraph-KgmhkJVnc none noBorder radius0 none">
<div>
<p><strong>25</strong>. Grant writing services include:</p>
<p>✔ Grant Searching</p>
<p>✔ {National/Local} Grant applying</p>
<p>✔ Grant data entry (Remember, this is the information you give. (The more informative you are, the better.)</p>
<p>✔ Grant maintaining (upload docs and send requested info needed for approval.) Deadlines of each Grant.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-j3viZnkr3" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-j3viZnkr3 text-output csub-heading-j3viZnkr3 none noBorder radius0 none">
<div>
<h2><strong>MODIFICATION OF THE AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-u1FT6QRt6" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-u1FT6QRt6 text-output cparagraph-u1FT6QRt6 none noBorder radius0 none">
<div>
<p><strong>26.</strong> Any amendment or modification of this Agreement or additional obligation assumed by either Party in connection with this Agreement will only be binding if evidenced in writing signed by each Party or an authorized representative of each Party.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-T5ezG7FUy" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-T5ezG7FUy text-output csub-heading-T5ezG7FUy none noBorder radius0 none">
<div>
<h2><strong>TIME OF THE ESSENCE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-FbcvhjDHP" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-FbcvhjDHP text-output cparagraph-FbcvhjDHP none noBorder radius0 none">
<div>
<p><strong>27.</strong> Time is of the essence in this Agreement. No extension or variation of this Agreement will operate as a waiver of this provision.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-81Tv32Le9" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-81Tv32Le9 text-output csub-heading-81Tv32Le9 none noBorder radius0 none">
<div>
<h2><strong>ASSIGNMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-xzQo-Q1ul" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-xzQo-Q1ul text-output cparagraph-xzQo-Q1ul none noBorder radius0 none">
<div>
<p><strong>28. </strong>The Contractor will not voluntarily, or by operation of law, assign or otherwise transfer its obligations under this Agreement without the prior written consent of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-7RRxqNz9K" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-7RRxqNz9K text-output csub-heading-7RRxqNz9K none noBorder radius0 none">
<div>
<h2><strong>ENTIRE AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-MKSvDr7jS" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-MKSvDr7jS text-output cparagraph-MKSvDr7jS none noBorder radius0 none">
<div>
<p>It is agreed that there is no representation, warranty, collateral agreement or condition affecting this Agreement except as expressly provided in this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-bwCttZpw3" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-bwCttZpw3 text-output csub-heading-bwCttZpw3 none noBorder radius0 none">
<div>
<h2><strong>ENUREMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-6KI3tkw9m" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-6KI3tkw9m text-output cparagraph-6KI3tkw9m none noBorder radius0 none">
<div>
<p><strong>30. </strong>This Agreement will enure to the benefit of and be binding on the Parties and their respective heirs, executors, administrators, and permitted successors and assigns.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-pM_Fe_Mwp" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-pM_Fe_Mwp text-output csub-heading-pM_Fe_Mwp none noBorder radius0 none">
<div>
<h2><strong>TITLES/HEADINGS</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-AZx8fafTt" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-AZx8fafTt text-output cparagraph-AZx8fafTt none noBorder radius0 none">
<div>
<p><strong>31.</strong> Headings are inserted for the convenience of the Parties only and are not to be considered when interpreting this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-N7RXonrp5" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-N7RXonrp5 text-output csub-heading-N7RXonrp5 none noBorder radius0 none">
<div>
<h2><strong>GENDER</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-oDoe0lrQe" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-oDoe0lrQe text-output cparagraph-oDoe0lrQe none noBorder radius0 none">
<div>
<p><strong>32.</strong> Words in the singular mean and include the plural and vice versa. Words in the masculine mean and include the feminine and vice versa.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-iuL9fMzXc" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-iuL9fMzXc text-output csub-heading-iuL9fMzXc none noBorder radius0 none">
<div>
<h2><strong>GOVERNING LAW</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-OKCVkaKUf" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-OKCVkaKUf text-output cparagraph-OKCVkaKUf none noBorder radius0 none">
<div>
<p><strong>33.</strong> This Agreement will be governed by and construed in accordance with the laws of the State of Georgia.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-nqurZugby" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-nqurZugby text-output csub-heading-nqurZugby none noBorder radius0 none">
<div>
<h2><strong>SEVERABILITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-0vqlz2rOT" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-0vqlz2rOT text-output cparagraph-0vqlz2rOT none noBorder radius0 none">
<div>
<p><strong>34</strong>. In the event that any of the provisions of this Agreement are held to be invalid or unenforceable in whole or in part, all other provisions will nevertheless continue to be valid and enforceable with the invalid or unenforceable parts severed from the remainder of this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-gdDoxiIOk" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-gdDoxiIOk text-output csub-heading-gdDoxiIOk none noBorder radius0 none">
<div>
<h2><strong>WAIVER</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-oxuLb7uzY" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-oxuLb7uzY text-output cparagraph-oxuLb7uzY none noBorder radius0 none">
<div>
<p><strong>35</strong>. The waiver by either Party of a breach, default, delay or omission of any of the provisions of this Agreement by the other Party will not be construed as a waiver of any subsequent breach of the same or other provisions.</p>
</div>
</div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
</div>
<!--]--></div>
</div>`
            
            }else{

              this.html = `<p>&nbsp;</p>
<div id="__nuxt">
<div>
<div class="bgCover bg-fixed">&nbsp;</div>
<!--[-->
<div>
<div>
<div id="nav-menu-popup" class="hide" style="display: none;">
<div class="nav-menu-body">
<ul class="nav-menu"><!--[--><!--]--></ul>
</div>
</div>
</div>
<div id="overlay" class="hide" style="display: none; background-color: rgba(0, 0, 0, 0.5);"></div>
<div id="preview-container" class="preview-container hl_page-preview--content">
<div data-v-0bad29cf=""><!--[--><!--[-->
<div id="section-vBbNog7qm" class="fullSection none noBorder radius0 none c-section c-wrapper section-vBbNog7qm" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="row-rMZ02OgOgw" class="row-align-center none noBorder radius0 none c-row c-wrapper row-rMZ02OgOgw" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-XpfmHWfw04" class="c-column c-wrapper col-XpfmHWfw04" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-OS0Z2LcqUu" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-OS0Z2LcqUu text-output cheading-OS0Z2LcqUu none noBorder radius0 none">
<div>
<h1><strong>TERMS AND CONDITIONS</strong></h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="divider-gC6AJZLS0" class="c-divider c-wrapper divider-gC6AJZLS0" data-v-0bad29cf="">
<div id="divider-gC6AJZLS0" class="divider-element cdivider-gC6AJZLS0"></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--[-->
<div id="row-MslS67eTG3" class="row-align-center none noBorder radius0 none c-row c-wrapper row-MslS67eTG3" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-rtGjwWF9Mb" class="c-column c-wrapper col-rtGjwWF9Mb" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-MnPTHJF6HW" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-MnPTHJF6HW text-output cheading-MnPTHJF6HW none noBorder radius0 none">
<div>
<p><strong><u>Last Revised: March 27, 2022</u></strong></p>
<h1>&nbsp;</h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="heading-4NMhoydBW" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-4NMhoydBW text-output cheading-4NMhoydBW none noBorder radius0 none">
<div>
<h1><strong>E-SIGN, the Electronic Signatures in Global and National Commerce Act (15 U.S.C. &sect; 7001, et seq.), requires that you consent to entering into an electronic agreement with us before an online version of our software Please read the following information carefully.</strong></h1>
<p>&nbsp;</p>
<p><strong>Should you enter into an online Member Agreement (&ldquo;the Agreement&rdquo;), you will not be required to submit a paper application. The entire agreement between you and us will be evidenced by an electronic record. To enter into the Agreement, you must additionally electronically acknowledge that you agree to the Agreement Terms and Conditions, and the General Service Agreement. To access these documents and enter into the Agreement, you will need the following hardware and software: A Personal Computer (&ldquo;PC&rdquo;) with a modem or other Internet access device, operational Internet browser software (e.g., Mozilla Firefox, Google Chrome, Safari, or Internet Explorer), and Adobe Acrobat Reader. Should there ever be a change in the equipment or software necessary to access the terms of the Agreement, we will advise you of the same and will provide you with a list of the equipment and software that is necessary. Upon such an event, you may withdraw your consent.</strong></p>
</div>
</div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--[-->
<div id="row-Fbj37cn7-I" class="row-align-center none noBorder radius0 none c-row c-wrapper row-Fbj37cn7-I" data-v-0bad29cf="">
<div class="inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="col-eFRSEzZQz" class="c-column c-wrapper col-eFRSEzZQz" data-v-0bad29cf="">
<div class="none noBorder radius0 none bg bgCover vertical inner" data-v-0bad29cf=""><!--[--><!--[-->
<div id="heading-3KhsG4GtC" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-3KhsG4GtC text-output cheading-3KhsG4GtC none noBorder radius0 none">
<div>
<h1><strong>General Service Agreement</strong></h1>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="heading-9YJcrbxU_" class="c-heading c-wrapper" data-v-0bad29cf="">
<div class="heading-9YJcrbxU_ text-output cheading-9YJcrbxU_ none noBorder radius0 none">
<div>
<p>This <strong>General Service Agreement</strong> (the "Agreement") is in full agreement with YOU. When YOU agree and sign, we mach your date and time stap with this aggrent.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-5d21GLi8k" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-5d21GLi8k text-output csub-heading-5d21GLi8k none noBorder radius0 none">
<div>
<h2><strong>BACKGROUND</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-adDn1WpnH" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-adDn1WpnH text-output cparagraph-adDn1WpnH none noBorder radius0 none">
<div>
<p><strong>A.</strong> The Client is of the opinion that the Contractor has the necessary qualifications, experience, and abilities to provide services to the Client.</p>
<p>&nbsp;</p>
<p><strong>B.</strong> The Contractor is agreeable to providing such services to the Client on the terms and conditions set out in this Agreement.</p>
<p>&nbsp;</p>
<p><strong>IN CONSIDERATION OF</strong> the matters described above and of the mutual benefits and obligations set forth in this Agreement, the receipt and sufficiency of which consideration is hereby acknowledged, the Client and the Contractor (individually the "Party" and collectively the "Parties" to this Agreement) agree as follows:</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-J8hadJfNU" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-J8hadJfNU text-output csub-heading-J8hadJfNU none noBorder radius0 none">
<div>
<h2><strong>SERVICES PROVIDED</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-PJG3Kavyp" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-PJG3Kavyp text-output cparagraph-PJG3Kavyp none noBorder radius0 none">
<div>
<p><strong>1</strong>. The client hereby agrees to engage the contractor to provide the client with the following services (the "Services"):</p>
<ul>
<li><strong>Grant Writing Services.</strong></li>
<li><strong>Grant Portal to track your grants</strong></li>
</ul>
<p>&nbsp;</p>
<p><strong>2.</strong> The Services will also include any other tasks which the Parties may agree on. The Contractor hereby agrees to provide such Services to the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-oBJiXdJ8e" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-oBJiXdJ8e text-output csub-heading-oBJiXdJ8e none noBorder radius0 none">
<div>
<h2><strong>TERM OF AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-DK5xA7dFi" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-DK5xA7dFi text-output cparagraph-DK5xA7dFi none noBorder radius0 none">
<div>
<p><strong>3.</strong> The term of this Agreement (the "Term") will begin on the date of this Agreement and will remain in full force and effect until the completion of the Services, subject to earlier termination as provided in this Agreement. The Term may be extended with the written consent of the Parties.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-wWXsgrbi6" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-wWXsgrbi6 text-output csub-heading-wWXsgrbi6 none noBorder radius0 none">
<div>
<h2><strong>PERFORMANCE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-78PcXc7yi" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-78PcXc7yi text-output cparagraph-78PcXc7yi none noBorder radius0 none">
<div>
<p><strong>4. </strong>The Parties agree to do everything necessary to ensure that the terms of this Agreement take effect.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-c6O7nzqCV" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-c6O7nzqCV text-output csub-heading-c6O7nzqCV none noBorder radius0 none">
<div>
<h2><strong>CURRENCY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-Vtl1H1PGY" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-Vtl1H1PGY text-output cparagraph-Vtl1H1PGY none noBorder radius0 none">
<div>
<p>5. Except as otherwise provided in this Agreement, all monetary amounts referred to in this Agreement are in USD (US Dollars).</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-C3zlfbcgZ" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-C3zlfbcgZ text-output csub-heading-C3zlfbcgZ none noBorder radius0 none">
<div>
<h2><strong>COMPENSATION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-WyYtY-IAS" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-WyYtY-IAS text-output cparagraph-WyYtY-IAS none noBorder radius0 none">
<div>
<p><strong>6.</strong> The Contractor will charge the client a flat fee of <strong>$350.00</strong> for portal access and tracking of grant services Services (the "Compensation").</p>
<p>&nbsp;</p>
<p><strong>7.</strong> The Contractor will invoice the Client when the full portal access.</p>
<p>&nbsp;</p>
<p><strong>8.</strong> Invoices submitted by the Contractor to the Client are due upon receipt.</p>
<p>&nbsp;</p>
<p><strong>9.</strong> The Contractor will not be reimbursed for any expenses incurred in connection with providing the Services under this Agreement.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-V72eIPNZ2" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-V72eIPNZ2 text-output csub-heading-V72eIPNZ2 none noBorder radius0 none">
<div>
<h2><strong>CONFIDENTIALITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-kQFnDX1uP" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-kQFnDX1uP text-output cparagraph-kQFnDX1uP none noBorder radius0 none">
<div>
<p><strong>10.</strong> Confidential information (the "Confidential Information") refers to any data or information relating to the Client, whether business or personal, which would reasonably be considered to be private or proprietary to the Client and that is not generally known and where the release of that Confidential Information could reasonably be expected to cause harm to the Client.</p>
<p>&nbsp;</p>
<p><strong>11</strong>. The Contractor agrees that they will not disclose, divulge, reveal, report or use, for any purpose, any Confidential Information which the Contractor has obtained, except as authorized by the Client or as required by law. The obligations of confidentiality will apply during the Term and will survive indefinitely upon termination of this Agreement.</p>
<p>&nbsp;</p>
<p><strong>12.</strong> All written and oral information and material disclosed or provided by the Client to the Contractor under this Agreement is Confidential Information regardless of whether it was provided before or after the date of this Agreement or how it was provided to the Contractor.</p>
<p>&nbsp;</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-nuPNi9r_b" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-nuPNi9r_b text-output csub-heading-nuPNi9r_b none noBorder radius0 none">
<div>
<h2><strong>OWNERSHIP OF INTELLECTUAL PROPERTY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-C55u7slOp" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-C55u7slOp text-output cparagraph-C55u7slOp none noBorder radius0 none">
<div>
<p><strong>13</strong>. All intellectual property and related material (the "Intellectual Property") that is developed or produced under this Agreement will be the property of the Contractor. The Client is granted a non-exclusive limited-use license of this Intellectual Property.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p><strong>14.</strong> Title, copyright, intellectual property rights and distribution rights of the Intellectual Property remain exclusively with the Contractor.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-kTSAHBrzk" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-kTSAHBrzk text-output csub-heading-kTSAHBrzk none noBorder radius0 none">
<div>
<h2><strong>RETURN OF PROPERTY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-_4NChGxW5" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-_4NChGxW5 text-output cparagraph-_4NChGxW5 none noBorder radius0 none">
<div>
<p><strong>15.</strong> Upon the expiration or termination of this Agreement, the Contractor will return to the Client any property, documentation, records, or Confidential Information which is the property of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-iVuWtaVSU" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-iVuWtaVSU text-output csub-heading-iVuWtaVSU none noBorder radius0 none">
<div>
<h2><strong>CAPACITY/INDEPENDENT CONTRACTOR</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-2hUd_mzVz" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-2hUd_mzVz text-output cparagraph-2hUd_mzVz none noBorder radius0 none">
<div>
<p><strong>16.</strong> In providing the Services under this Agreement it is expressly agreed that the Contractor is acting as an independent contractor and not as an employee. The Contractor and the Client acknowledge that this Agreement does not create a partnership or joint venture between them, and is exclusively a contract for service. The Client is not required to pay, or make any contributions to, any social security, local, state or federal tax, unemployment compensation, workers' compensation, insurance premium, profit-sharing, pension or any other employee benefit for the Contractor during the Term. The Contractor is responsible for paying, and complying with reporting requirements for, all local, state and federal taxes related to payments made to the Contractor under this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Twtv_2TbH" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Twtv_2TbH text-output csub-heading-Twtv_2TbH none noBorder radius0 none">
<div>
<h2><strong>RIGHT OF SUBSTITUTION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-Q0vj2w3U_" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-Q0vj2w3U_ text-output cparagraph-Q0vj2w3U_ none noBorder radius0 none">
<div>
<p><strong>17. </strong>Except as otherwise provided in this Agreement, the Contractor may, at the Contractor's absolute discretion, engage a third party sub-contractor to perform some or all of the obligations of the Contractor under this Agreement and the Client will not hire or engage any third parties to assist with the provision of the Services.</p>
<p>-</p>
<p><strong>18.</strong> In the event that the Contractor hires a sub-contractor:</p>
<p>&nbsp;</p>
<p>✔The Contractor will pay the sub-contractor for its services and the Compensation will remain payable by the Client to the</p>
<p>contractor.</p>
<p>&nbsp;</p>
<p>✔For the purposes of the indemnification clause of this Agreement, the sub-contractor is an agent of the Contractor.contractor.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Ruq5XocbT" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Ruq5XocbT text-output csub-heading-Ruq5XocbT none noBorder radius0 none">
<div>
<h2><strong>AUTONOMY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-VlxUesti6" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-VlxUesti6 text-output cparagraph-VlxUesti6 none noBorder radius0 none">
<div>
<p><strong>19.</strong> Except as otherwise provided in this Agreement, the Contractor will have full control over working time, methods, and decision making in relation to provision of the Services in accordance with the Agreement. The Contractor will work autonomously and not at the direction of the Client. However, the Contractor will be responsive to the reasonable needs and concerns of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-BPXXGx9lH" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-BPXXGx9lH text-output csub-heading-BPXXGx9lH none noBorder radius0 none">
<div>
<h2><strong>EQUIPMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-8_LonhAsI" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-8_LonhAsI text-output cparagraph-8_LonhAsI none noBorder radius0 none">
<div>
<p><strong>20</strong>. Except as otherwise provided in this Agreement, the Contractor will provide at the Contractor &rsquo;s own expense, any and all tools, machinery, equipment, raw materials, supplies, workwear and any other items or parts necessary to deliver the Services in accordance with the Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-zdnl8xk_m" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-zdnl8xk_m text-output csub-heading-zdnl8xk_m none noBorder radius0 none">
<div>
<h2><strong>NO EXCLUSIVITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-ZO1KpLI3h" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-ZO1KpLI3h text-output cparagraph-ZO1KpLI3h none noBorder radius0 none">
<div>
<p><strong>21.</strong> The Parties acknowledge that this Agreement is non-exclusive and that either Party will be free, during and after the Term, to engage or contract with third parties for the provision of services similar to the Services.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-O9nZqmWW5" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-O9nZqmWW5 text-output csub-heading-O9nZqmWW5 none noBorder radius0 none">
<div>
<h2><strong>NOTICE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-snkDLAREe" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-snkDLAREe text-output cparagraph-snkDLAREe none noBorder radius0 none">
<div>
<p><strong>22</strong>. All notices, requests, demands, or other communications required or permitted by the terms of this agreement will be given through Email and delivered to the Parties at the following Email addresse: <strong>Support@superiorsolutions.com</strong></p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-Euj4y75Hs" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-Euj4y75Hs text-output csub-heading-Euj4y75Hs none noBorder radius0 none">
<div>
<h2><strong>INDEMNIFICATION</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-pqQRS_kN1" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-pqQRS_kN1 text-output cparagraph-pqQRS_kN1 none noBorder radius0 none">
<div>
<p><strong>23.</strong> Except to the extent paid in settlement from any applicable insurance policies, and to the extent permitted by applicable law, each Party agrees to indemnify and hold harmless the other Party, and its respective affiliates, officers, agents, employees, and permitted successors and assigns against any and all claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees and costs of any kind or amount whatsoever, which result from or arise out of any act or omission of the indemnifying party, its respective affiliates, officers, agents, employees, and permitted successors and assigns that occurs in connection with this Agreement. This indemnification will survive the termination of this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-9B0oI0puM" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-9B0oI0puM text-output csub-heading-9B0oI0puM none noBorder radius0 none">
<div>
<p><strong>ADDITIONAL CLAUSES</strong></p>
<h2><strong>24. </strong>No Refunds.<strong> </strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-KgmhkJVnc" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-KgmhkJVnc text-output cparagraph-KgmhkJVnc none noBorder radius0 none">
<div>
<p><strong>25</strong>. Grant writing services include:</p>
<p>✔ Grant Searching</p>
<p>✔ {National/Local} Grant applying</p>
<p>✔ Grant data entry (Remember, this is the information you give. (The more informative you are, the better.)</p>
<p>✔ Grant maintaining (upload docs and send requested info needed for approval.) Deadlines of each Grant.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-j3viZnkr3" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-j3viZnkr3 text-output csub-heading-j3viZnkr3 none noBorder radius0 none">
<div>
<h2><strong>MODIFICATION OF THE AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-u1FT6QRt6" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-u1FT6QRt6 text-output cparagraph-u1FT6QRt6 none noBorder radius0 none">
<div>
<p><strong>26.</strong> Any amendment or modification of this Agreement or additional obligation assumed by either Party in connection with this Agreement will only be binding if evidenced in writing signed by each Party or an authorized representative of each Party.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-T5ezG7FUy" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-T5ezG7FUy text-output csub-heading-T5ezG7FUy none noBorder radius0 none">
<div>
<h2><strong>TIME OF THE ESSENCE</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-FbcvhjDHP" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-FbcvhjDHP text-output cparagraph-FbcvhjDHP none noBorder radius0 none">
<div>
<p><strong>27.</strong> Time is of the essence in this Agreement. No extension or variation of this Agreement will operate as a waiver of this provision.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-81Tv32Le9" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-81Tv32Le9 text-output csub-heading-81Tv32Le9 none noBorder radius0 none">
<div>
<h2><strong>ASSIGNMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-xzQo-Q1ul" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-xzQo-Q1ul text-output cparagraph-xzQo-Q1ul none noBorder radius0 none">
<div>
<p><strong>28. </strong>The Contractor will not voluntarily, or by operation of law, assign or otherwise transfer its obligations under this Agreement without the prior written consent of the Client.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-7RRxqNz9K" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-7RRxqNz9K text-output csub-heading-7RRxqNz9K none noBorder radius0 none">
<div>
<h2><strong>ENTIRE AGREEMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-MKSvDr7jS" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-MKSvDr7jS text-output cparagraph-MKSvDr7jS none noBorder radius0 none">
<div>
<p>It is agreed that there is no representation, warranty, collateral agreement or condition affecting this Agreement except as expressly provided in this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-bwCttZpw3" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-bwCttZpw3 text-output csub-heading-bwCttZpw3 none noBorder radius0 none">
<div>
<h2><strong>ENUREMENT</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-6KI3tkw9m" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-6KI3tkw9m text-output cparagraph-6KI3tkw9m none noBorder radius0 none">
<div>
<p><strong>30. </strong>This Agreement will enure to the benefit of and be binding on the Parties and their respective heirs, executors, administrators, and permitted successors and assigns.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-pM_Fe_Mwp" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-pM_Fe_Mwp text-output csub-heading-pM_Fe_Mwp none noBorder radius0 none">
<div>
<h2><strong>TITLES/HEADINGS</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-AZx8fafTt" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-AZx8fafTt text-output cparagraph-AZx8fafTt none noBorder radius0 none">
<div>
<p><strong>31.</strong> Headings are inserted for the convenience of the Parties only and are not to be considered when interpreting this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-N7RXonrp5" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-N7RXonrp5 text-output csub-heading-N7RXonrp5 none noBorder radius0 none">
<div>
<h2><strong>GENDER</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-oDoe0lrQe" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-oDoe0lrQe text-output cparagraph-oDoe0lrQe none noBorder radius0 none">
<div>
<p><strong>32.</strong> Words in the singular mean and include the plural and vice versa. Words in the masculine mean and include the feminine and vice versa.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-iuL9fMzXc" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-iuL9fMzXc text-output csub-heading-iuL9fMzXc none noBorder radius0 none">
<div>
<h2><strong>GOVERNING LAW</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-OKCVkaKUf" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-OKCVkaKUf text-output cparagraph-OKCVkaKUf none noBorder radius0 none">
<div>
<p><strong>33.</strong> This Agreement will be governed by and construed in accordance with the laws of the State of Georgia.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-nqurZugby" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-nqurZugby text-output csub-heading-nqurZugby none noBorder radius0 none">
<div>
<h2><strong>SEVERABILITY</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-0vqlz2rOT" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-0vqlz2rOT text-output cparagraph-0vqlz2rOT none noBorder radius0 none">
<div>
<p><strong>34</strong>. In the event that any of the provisions of this Agreement are held to be invalid or unenforceable in whole or in part, all other provisions will nevertheless continue to be valid and enforceable with the invalid or unenforceable parts severed from the remainder of this Agreement.</p>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="sub-heading-gdDoxiIOk" class="c-sub-heading c-wrapper" data-v-0bad29cf="">
<div class="sub-heading-gdDoxiIOk text-output csub-heading-gdDoxiIOk none noBorder radius0 none">
<div>
<h2><strong>WAIVER</strong></h2>
</div>
</div>
</div>
<!--]--><!--[-->
<div id="paragraph-oxuLb7uzY" class="c-paragraph c-wrapper" data-v-0bad29cf="">
<div class="paragraph-oxuLb7uzY text-output cparagraph-oxuLb7uzY none noBorder radius0 none">
<div>
<p><strong>35</strong>. The waiver by either Party of a breach, default, delay or omission of any of the provisions of this Agreement by the other Party will not be construed as a waiver of any subsequent breach of the same or other provisions.</p>
</div>
</div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
<!--]--><!--]--></div>
</div>
</div>
<!--]--></div>
</div>`
            
} 
          this.loadingFlag = true;
          })

},
    addPhone(){
        this.phoneFlag = 1;
    },
    disableinput($event){
       $event.preventDefault();
    },
    downloadFile(key){
      let self = this;
            this.isDisableDownload = true;
            this.isDarkDownload = false;
                axios({
                    method: "POST",
                    url: "/api/download-file",
                    responseType: 'blob',
                    headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
                    data: {key : key}
                    })
                    .then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                   var fileLink = document.createElement('a');

                   fileLink.href = fileURL;
                   fileLink.setAttribute('download', response.headers['file-name']);
                   document.body.appendChild(fileLink);

                   fileLink.click();
                    
                    self.isDisableDownload = false;
                    self.isDarkDownload = true;
                    })
                    .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                    });
    },
    uploadFile(id){
      let self = this;
      let flag = 1;
      if(id =='agreement' && self.files.agreement.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else if(id =='id' && self.files.id.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='bill' && self.files.bill.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_a' && self.files.letter_a.length == 0){
         Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_b' && self.files.letter_b.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }else if(id =='letter_c' && self.files.letter_c.length == 0){
          Vue.$toast.error('Choose file to upload', {
          position: 'top-right'
          });
          flag = 0;
      }
      else{
          const data = new FormData();
          if(id == 'agreement')
            data.append('file_agreement', self.files.agreement);
          if(id == 'id')
            data.append('file_id', self.files.id);
          if(id == 'bill')
            data.append('file_bill', self.files.bill);
          if(id == 'letter_a')
            data.append('file_letter_a', self.files.letter_a);
          if(id == 'letter_b')
            data.append('file_letter_b',self.files.letter_b);
          if(id == 'letter_c')
            data.append('file_letter_c',self.files.letter_c);
          data.append('flag',id);
          axios({
          method: "POST",
          url: "/api/upload-file",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          console.log(response);
          Vue.$toast.success("File uploaded", {
              position: 'top-right'
              });
          self.getUserProfile();
          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });
      }
    },
    deleteChip(index){
      if(index == 'agreement')
        this.file_flag.agreement.name = ''
      if(index == 'id')
        this.file_flag.id.name = '';
      if(index == 'bill')
        this.file_flag.bill.name = '';
      if(index == 'letter_a')
        this.file_flag.letter_a.name = '';
      if(index == 'letter_b')
        this.file_flag.letter_b.name = '';
      if(index == 'letter_c')
        this.file_flag.letter_c.name = '';
    },
    addEmail(){
        this.emailFlag = 1;
    },
    countryChanged(country) {
        this.country = '+' + country.dialCode
    },
      changePassword: function () {
          let flag = 1;
          if (!this.password) {
            Vue.$toast.error('Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }

          if (!this.confpassword) {
            Vue.$toast.error('Confirm Password is required', {
                position: 'top-right'
            });
            flag = 0;
          }
          if (this.confpassword != this.password && flag == 1) {
            Vue.$toast.error('Confirm password did not match', {
                position: 'top-right'
            });
            self.$refs['conf_password'].focus()
            flag = 0;
        }

          if (flag == 1) {

            axios({
            method: "POST",
             
            url: "/api/passwordchange",
            data: {
            password: this.password,
            password_confirmation: this.confpassword,
          }
        })
          .then(response => {
            let data = response.data;
            if (data.data == 1) {
              Vue.$toast.success('Password has been changed', {
                position: 'top-right'
                });
            this.password = '';
            this.confpassword = '';

            } else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          });
      } else {

      }

    },

    add: function () {
         // for setting tab
    },
   handleUploaded(cropper) {
          let image = cropper.getCroppedCanvas().toDataURL(this.cropperOutputMime);
          let img = this.dataURLtoFile(image,'asdasd.jpg');
          let preview_url =process.env.VUE_APP_WASBI_URL;
          const data = new FormData();
        data.append('file', img);
        data.append('client_id', this.user.user_id);
        this.loadingUpload = true;
        axios({
          method: "POST",
          url: "/api/upload-avatar",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
          data: data
          })
          .then(response => {
          Vue.$toast.success("Profile Picture uploaded", {
              position: 'top-right'
              });
              this.preview = preview_url+response.data.data;
              this.loadingUpload = false;

          })
          .catch(err => {
              Vue.$toast.error("Invalid file to upload", {
              position: 'top-right'
              });
              this.isDisableUpload = false;
              this.isDarkUpload = true;
          });

        },
        dataURLtoFile(dataurl, filename) {
 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    },
    getUserProfile() {
    let flag = 1;
    let self = this;
    let avatar_url = process.env.VUE_APP_WASBI_URL
    let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
      axios
        .get("/api/user",header)
        .then(response => {
            let data = response.data;

           if (flag == 1) {
              this.user = data.user;
              this.preview = data.user.avatar ? avatar_url+data.user.avatar : ''
              if(this.user.smart_credit_password)
                this.user.smart_credit_password = atob(this.user.smart_credit_password);
              if(data.docs.length != 0){
                data.docs.forEach(element => {
                  console.log(element);
                  if(element.file_name.includes("ID")){
                    self.file_flag.id.name = element.file_old_name;
                    self.file_flag.id.id = element.file_name;
                  }
                  if(element.file_name.includes("Agrement")){
                    self.file_flag.agreement.name = element.file_old_name;
                    self.file_flag.agreement.id = element.file_name;
                  }
                  if(element.file_name.includes("Bill")){
                    self.file_flag.bill.name = element.file_old_name;
                    self.file_flag.bill.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_A")){
                    self.file_flag.letter_a.name = element.file_old_name;
                    self.file_flag.letter_a.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_B")){
                    self.file_flag.letter_b.name = element.file_old_name;
                    self.file_flag.letter_b.id = element.file_name;
                  }
                  if(element.file_name.includes("Letter_C")){
                    self.file_flag.letter_c.name = element.file_old_name;
                    self.file_flag.letter_c.id = element.file_name;
                  }
                });
              }
              console.log(self.files);
            }else {
                Vue.$toast.error(data.message, {
                position: 'top-right'
                });
            }

         })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });

           });

     },




    saveProfile: function(e) {
      let self = this;
      let flag = 1;
      let header = { headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                }
        }
        if (!this.user.first_name) {
            Vue.$toast.error('First Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.last_name && flag == 1) {
            Vue.$toast.error('Last Name is required', {
                position: 'top-right'
            });
            flag = 0;
        }
        if (!this.user.phone_m && flag == 1) {
            Vue.$toast.error('phone is required', {
                position: 'top-right'
            });
            // this.$refs['username'].focus()
            flag = 0;
        }
        if (!this.user.email && flag == 1) {
            Vue.$toast.error('Email is required', {
                position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }
        
        let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (reg.test(this.user.email) == false && flag == 1 ) {
            Vue.$toast.error('Invalid Email format', {
            position: 'top-right'
            });
            this.$refs['email'].focus()
            flag = 0;
        }

        if (flag == 1) {
          this.loading = true;
        axios({
          method: "POST",
         headers : {
                Authorization:"Bearer " + localStorage.getItem('token')
                },
           
          url: "/api/updateprofile",
          data: self.user
        })
          .then(response => {
            let data = response.data;
            if (response.data) {
                  Vue.$toast.success('Profile updated', {
                    position: 'top-right'
                  })

            } else {
                Vue.$toast.error('Failed To Add', {
                position: 'top-right'
                });
            }
            this.loading = false;
          })
          .catch(err => {
            console.log(err.response);
            
              if(err.response.data.errors.email)
                Vue.$toast.error(err.response.data.errors.email[0], {
                position: 'top-right'
                });
              if(err.response.data.errors.user_name)
                Vue.$toast.error(err.response.data.errors.user_name[0], {
                position: 'top-right'
                });            
          });
        }
    },

 },

created() {
  this.url = this.$route.params.url ? this.$route.params.url : '';
        if(this.url)
            this.getCompanyInfo(this.url)
        else
            this.loadingFlag = true;
    },

}
</script>

