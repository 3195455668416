<template>
  <v-app class="bg-white">
    <v-overlay :value="loader">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-main class="auth-pages" v-if="company" >
    
          <div class="footer" id="myHeader" :style="getStyle('footer')">
            <div class="elementor-container elementor-column-gap-default" style="width: 100%;">
              <v-row style="margin:0px!important;">
      <v-col style="padding: 1px;">
					<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-a828a20" data-id="a828a20" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-88f37c7 elementor-view-default elementor-widget elementor-widget-icon" data-id="88f37c7" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="'/'">
			<i aria-hidden="true" class="fa fa-id-card" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-f383dda elementor-widget elementor-widget-heading" data-id="f383dda" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default" ><a :href="'/'" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Card</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-1eed04c" data-id="1eed04c" data-element_type="column" id="share-my-card" @click="overlay = true">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-61dd731 elementor-view-default elementor-widget elementor-widget-icon" data-id="61dd731" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" href="#">
			<i aria-hidden="true" class="fa fa-qrcode" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-08fcb6e elementor-widget elementor-widget-heading" data-id="08fcb6e" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a href="#" @click="overlay = true" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Scan</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col style="padding: 1px;">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d7c2c7a" data-id="d7c2c7a" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-5f80995 elementor-view-default elementor-widget elementor-widget-icon" data-id="5f80995" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="`tel:${company.phone_m}`">
			<i aria-hidden="true" class="fas fa-phone-alt" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-2432e95 elementor-widget elementor-widget-heading" data-id="2432e95" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="`tel:${company.phone_m}`" :style="vcard.header_color=='Light Text' ? 'color:black!important' : 'color:white!important'">Direct Call</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    <v-col v-if="event_value">
				<div class="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-5b2a404" data-id="5b2a404" data-element_type="column">
			<div class="elementor-widget-wrap elementor-element-populated">
								<div class="elementor-element elementor-element-57f7405 elementor-view-default elementor-widget elementor-widget-icon" data-id="57f7405" data-element_type="widget" data-widget_type="icon.default">
				<div class="elementor-widget-container">
					<div class="elementor-icon-wrapper">
			<a class="elementor-icon" :href="event_value" target="_blank">
			<i aria-hidden="true" class="fas fa-calendar-check"></i>			</a>
		</div>
				</div>
				</div>
				<div class="elementor-element elementor-element-eb196fc elementor-widget elementor-widget-heading" data-id="eb196fc" data-element_type="widget" data-widget_type="heading.default">
				<div class="elementor-widget-container">
			<h6 class="elementor-heading-title-card elementor-size-default"><a :href="event_value" target="_blank">Events</a></h6>		</div>
				</div>
					</div>
		</div>
    </v-col>
    </v-row>
							</div>
          </div>
                <v-row style="display: flex; justify-content: space-around;margin:0px!important;" >
                  <v-col cols="12" style="display: flex; justify-content: space-around; margin-top: -15px; " >
                    <img :height="this.$vuetify.breakpoint.xs ? 100 : 150" class="image-logo2" src="@/assets/img/logo.png">
                    </v-col>
            
              </v-row>
              <v-row style="margin:0px!important;">
                <v-col lg="12" class="mx-auto">
                  <h3
                    class="text-h4 text-typo font-weight-bold mt-lg-0 "
                  >
                  {{ vehicle.year }} {{vehicle.make}} {{vehicle.model}}
                  </h3>
                  </v-col>
                <v-col lg="12" md="12" cols="12" class="text-center">


                    <VueSlickCarousel
                      ref="c1"
                      v-if="vehicle.images"
                      :asNavFor="$refs.c2"
                      :focusOnSelect="true">
                      <v-img
                      height="250"
                      v-for="(item,i) in vehicle.images"
                          class="w-100 shadow-lg mx-auto"
                          :src="item.image"
                        ></v-img>
                    </VueSlickCarousel>

                    <VueSlickCarousel
                      ref="c2"
                      :asNavFor="$refs.c1"
                      :slidesToShow="4"
                      class="sub-slide"
                      v-if="vehicle.images"
                      :focusOnSelect="true">
                        <v-img
                        height="50"
                        style="margin-right: 5px;"
                      v-for="(item,i) in vehicle.images"
                          class="w-101 shadow-lg mr-2"
                          :src="item.image"
                        ></v-img>
                    </VueSlickCarousel>


                  
                </v-col>
                <v-col lg="12" cols="12">
                 
                  <v-row>
                    <v-col cols="6">
                      <!-- <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="tripStart.date" label="Trip start" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                      </v-menu> -->
                      <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="tripStartDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                 v-model="formattedStartDate"
                                label="Trip Start"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="tripStartDate"
                              no-title
                              color="blue"
                              :allowed-dates="disableBookedDates"
                              :min="minDate"
                              :max="maxDate"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="blue"
                                @click="menu = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="blue"
                                @click="$refs.menu.save(tripStartDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="tripStartTime" :items="times" label="Time"></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="tripEndDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formattedEndDate"
                                label="Trip End"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="tripEndDate"
                              no-title
                              :min="minDate"
                              :max="maxDate"
                              :allowed-dates="disableBookedDates"
                              color="blue"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="blue"
                                @click="menu2 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                text
                                color="blue"
                                @click="$refs.menu2.save(tripEndDate)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                    </v-col>
                    <v-col cols="6">
                      <v-select v-model="tripEndTime" :items="times" label="Time"></v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3"></v-divider>
                <GmapMap
                    :center="{ lat: parseFloat(vehicle.latitude), lng: parseFloat(vehicle.longitude) }"
                    :zoom="18"
                    map-type-id="terrain"
                    style="width: auto; height: 300px"
                  >
                    <GmapMarker
                      :position="{ lat: parseFloat(vehicle.latitude), lng: parseFloat(vehicle.longitude) }"
                      :clickable="true"
                      :draggable="true"
                    />
                  </GmapMap>
                  <v-divider class="my-3"></v-divider>      
                  <v-alert color="green lighten-4" class="mt-3">
                    <strong>3-day discount</strong> <span class="float-right">$20</span>
                  </v-alert>
                  <v-divider class="my-3"></v-divider>
                  <v-alert color="blue lighten-4">
                    <strong>Free cancellation</strong>
                    <p class="text-caption">Full refund before Feb 16, 10:00 AM</p>
                  </v-alert>
                  <v-divider class="my-3"></v-divider>
                  <h3 class="text-h6 font-weight-bold">Booking Price</h3>
                  <div class="pa-2 d-flex justify-space-between align-center" outlined>
                    <div>
                      <p class="text-h5 font-weight-bold" style="padding-top: 15px;" >
                        <!-- <del class="text-grey">$120/day</del> -->
                        <span class="" > ${{ vehicle.rent_price_per_day }}/day</span>
                      </p>
                      <!-- <p class="text-caption text-grey">${{ car.totalPrice }} excl. taxes & fees</p> -->
                    </div>
                    <v-btn color="blue" dark class="px-10 py-4" @click="validateBooking">Book</v-btn>
                  </div>
                  <v-divider class="my-3"></v-divider>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>Distance included</v-list-item-content>
                      <v-list-item-action>{{distance}} mi</v-list-item-action>
                    </v-list-item>
                    <v-divider class="my-3"></v-divider>
                    <v-list-item>
                      <v-list-item-content>Insurance via Travelers</v-list-item-content>
                      <v-list-item-action>
                        <v-icon>mdi-help-circle</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider class="my-3"></v-divider>
                  </v-list>
                   
                    <v-row class="text-center">
                      <v-col cols="3">
                        <img :height="this.$vuetify.breakpoint.xs ? 40 : 40" class="image-logo2" src="@/assets/img/mileage.png">
                        <p>{{ vehicle.mileage }} MPG</p>
                      </v-col>
                      <v-col cols="3">
                        <img :height="this.$vuetify.breakpoint.xs ? 40 : 40" class="image-logo2" src="@/assets/img/fuel.png">
                        <p>{{ vehicle.fuel_type }}</p>
                      </v-col>
                      <v-col cols="3">
                        <img :height="this.$vuetify.breakpoint.xs ? 40 : 40" class="image-logo2 " src="@/assets/img/door.png">
                        <p>{{ vehicle.doors }} doors</p>
                      </v-col>
                      <v-col cols="3">
                        <img :height="this.$vuetify.breakpoint.xs ? 40 : 40" class="image-logo2" src="@/assets/img/seat.png">
                        <p>{{ vehicle.seats }} seats</p>
                      </v-col>
                    </v-row>
                  <v-divider class="my-0"></v-divider>
                  <h3 class="text-h6 font-weight-bold mt-4">More Details</h3>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>Odometer</v-list-item-content>
                      <v-list-item-action>{{ vehicle.odometer }} miles</v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>VIN</v-list-item-content>
                      <v-list-item-action>{{ vehicle.vin }}</v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Transmission</v-list-item-content>
                      <v-list-item-action>{{ vehicle.transmission }}</v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>Wheeldrive</v-list-item-content>
                      <v-list-item-action>{{ vehicle.wheel_drive }}</v-list-item-action>
                    </v-list-item>
                  </v-list>
                
                </v-col>
                <!-- <div class="mt-8 ml-3">
                    <Photoswipe>
                      <draggable style="padding: 10px" v-model="vehicle.images" class="v-tabs__container row mt-2" v-if="vehicle.images.length > 0" >
                      <img
                      v-for="(file,n ) in vehicle.images"
                        class="me-2 mb-2"
                        :src="file.image"
                        @click="openGallery(file)"
                        
                      />
                     
                      </draggable>
                     
                                  
                    </Photoswipe>
                  </div>
                  -->
              </v-row>
          <v-row style="justify-content: center;margin-bottom: 150px;margin: 0;">
            <!-- <v-col cols="12" md="12" style="">
              <ProfileCards :users="filteredUsers" />
            </v-col> -->
          <v-col cols="12" md="12" style="margin-bottom: 40px !important;">
            <h1 class="text-h6 mt-3 text-center">Powered by <strong>AutoSync</strong></h1>
          </v-col>
          </v-row>
            <v-row style="justify-content: center;margin-bottom:50px!important;"></v-row>

                  <v-dialog
                      v-model="overlay"
                      width="500"
                    >
                    <img v-if="company"  class="" :src="base_url_img+'vcard/'+company.user_id+'-QR.png'" width="50">
                  
                  
                  </v-dialog>
                  <v-dialog
                      v-model="bookingConfirmDialog"
                      width="500"
                    >
                    <div>
                      <v-card-title class="headline" style="display: block;text-align: center;">🎉 Congratulations!</v-card-title>
                      <v-card-text style="text-align: center;">
                        Thank you for your booking. <br />
                        Check your email for more details.
                      </v-card-text>
                      <v-card-actions style="justify-content: center;">
                        <div style="width: 100%;justify-content: center;display: flex">
                        <v-btn  text @click="bookingConfirmDialog = false">OK</v-btn>
                      </div>
                      </v-card-actions>
                    </div>
                  
                  
                  </v-dialog>
                  <v-dialog v-model="bookingDialog" max-width="500px">
                    <div>
                      <v-card-title class="text-h6">Confirm Your Booking</v-card-title>
                      <v-card-text>
                        <p><strong>Car:</strong> {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}</p>
                        <p><strong>Booking Period:</strong> {{ tripStartDate }} {{ tripStartTime }} - {{ tripEndDate }} {{ tripEndTime }}</p>
                        <p><strong>Total Amount:</strong> <span class="text-h5 font-weight-bold">${{ totalAmount }}</span></p>
                        <v-text-field v-model="user.first_name" label="First Name" required></v-text-field>
                        <v-text-field v-model="user.last_name" label="Last Name" required></v-text-field>
                        <v-text-field v-model="user.email" label="Email" type="email" required ></v-text-field>
                        <v-text-field v-model="user.phone" label="Phone" type="tel" required v-mask="'(###) ###-####'"></v-text-field>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  text @click="bookingDialog = false">Cancel</v-btn>
                        <v-btn color="green"  dark @click="bookCar" :loading="loadingSaveButton">Book Now</v-btn>
                      </v-card-actions>
                    </div>
                  </v-dialog>
           
    </v-main>    
  </v-app>
</template>
<script>
var slides = document.querySelectorAll(".slide");
var currentSlide = 0;


import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import VCard from 'vcard-creator'
import { FadeTransition } from "vue2-transitions";
import Query from "@/components/Forms/Query.vue";
import ProfileCards from "@/components/Forms/VehicleCardList.vue";
import VueSlickCarousel from 'vue-slick-carousel'
// import { GmapMap, GmapMarker } from 'vue2-google-maps';
import Chart from "chart.js/auto";
  // optional style for arrows & dots
   import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { max, min } from "moment";
export default {
  
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ProfileCards,
    VCard,
    Query,
    VueSlickCarousel ,
    // GmapMap,
    // GmapMarker
  },
  data() {
    return {
       checkbox: false,
       showpassword : false,
       email : '',
      password : '',
      menu: false,
      menu2: false,
      sales_agent_count: 0,
      bookingDialog: false,
      search_rep: '',
      booked_dates: [],
      minDate : new Date().toISOString().substr(0, 10),
      maxDate :new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString().substr(0, 10),
      ref_agent_count: 0,
      distance: 0,
      user:{
        first_name: "",
        last_name: "",
        email: "",
        phone: ""
      },
      calculator: {
        down_payment: 0,
        total_amount: 0,
        trade_in: 0,
        credit_range: '781 - 850',
        interest_rate: 0,
        tenure: 3
      },
      tripStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tripStartTime: "10:00 AM",
      formattedStartDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tripEndDate: (new Date(Date.now() + 3 * 24 * 60 * 60 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      formattedEndDate: (new Date(Date.now() + 3 * 24 * 60 * 60 * 1000 - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tripEndTime: "10:00 AM",
      pickupLocation: "New York Stewart International Airport",
      locations: ["New York Stewart International Airport", "JFK Airport", "LAX Airport"],
      times: ["12 AM","12:30 AM","1:00 AM","1:30 AM","2:00 AM","2:30 AM","3:00 AM","3:30 AM","4:00 AM","4:30 AM","5:00 AM","5:30 AM","6:00 AM","6:30 AM","7:00 AM","7:30 AM","8:00 AM","8:30 AM","9:00 AM","9:30 AM", "10:00 AM","10:30 AM", "11:00 AM", "11:30 AM", "12:00 PM", "12:30 PM", "1:00 PM", "1:30 PM", "2:00 PM", "2:30 PM", "3:00 PM", "3:30 PM", "4:00 PM", "4:30 PM", "5:00 PM", "5:30 PM", "6:00 PM", "6:30 PM", "7:00 PM", "7:30 PM", "8:00 PM","8:30 PM","9:00 PM","9:30 PM","10:00 PM","10:30 PM","11:00 PM","11:30 PM"],
      phone : '',
      loader : true,
      background: '',
      tab_card:'',
      vimeo_account: '',
      vimeo_id : '',
      reps_flag : false,
      company_settings : '',
      total_loan_amount: 0,
      monthly_payment: 0,
      total_interest: 0,
      reps_users : [],
      overlay : false,
      index: 0,
      showPassword : false,
      zoom_value : '',
      base64Image :'',
      avatar_mimeType: '',
      event_value :'',
      dialog: false,
      bookingConfirmDialog : false,
      color : '',
      videos : [],
      loadingSaveButton : false,
      tab: 'tab-1',
      vehicle: {},
      settings: { "centerPadding": "20px",  arrows: true,
      dots: true,},
      pageFlag: 'init',
      backgroundLogo: localStorage.getItem('admin_bg'),
      isDark :true,
      buttonFlag   : false,
      loadingLogo : false,
      url : '',
      items: [
        ],
      company : '',
      vcard : '',
      loadingFlag : false,
      base_url_img : process.env.VUE_APP_WASBI_URL,
      pageTitle: ''
    };
  },
  beforeCreate: function () {
    
},
created: function () {
      const domain = this.getSubdomain();
      this.getSettings()
      if(domain){
        this.getCompanyInfo(domain)
      }else
        this.$router.push('/login');
        

},
mounted : function () {
  this.getUserLocation();
  this.formatDate(this.tripStartDate);
  this.formatDate2(this.tripEndDate);
  
},
methods: {
  prevSlide(index) {
  if(index !=0 )
    this.index = index - 1;
  else
    this.index = this.videos.length - 1;

},
getDatesBetween(start, end) {
  let dates = [];
  let currentDate = new Date(start);
  let endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(currentDate.toISOString().split("T")[0]); // Format: YYYY-MM-DD
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
},
validateBooking() {
  const startDate = new Date(this.tripStartDate);
  const endDate = new Date(this.tripEndDate);

  if (endDate <= startDate) {
    Vue.$toast.error("End date must be after start date!", {
      position: "top-right",
    });
    return;
  }

  // ✅ Convert date range into an array of dates
  const selectedDates = this.getDatesBetween(this.tripStartDate, this.tripEndDate);

  // ✅ Check if any selected date is already booked
  const isOverlapping = selectedDates.some(date => this.booked_dates.includes(date));

  if (isOverlapping) {
    Vue.$toast.error("Selected dates overlap with an existing booking!", {
      position: "top-right",
    });
    return;
  }

  this.bookingDialog = true;
},
  bookCar(){
    // here write api call to book car
    let self = this;
            let flag = 1;            
            if (!this.user.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.user.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.user.email && this.user.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            

           
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.user.email) == false && this.user.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            if (!this.user.phone && this.user.is_phone == false && flag == 1) {
                Vue.$toast.error('Phone is required', {
                    position: 'top-right'
                });
                flag = 0;
            }

            
            if (flag == 1) {
              this.user.startDate = this.tripStartDate;
              this.user.endDate = this.tripEndDate;
              this.user.startTime = this.tripStartTime;
              this.user.endTime = this.tripEndTime;
              this.user.vehicle_id = this.vehicle.id;
              this.user.company_id = this.company.user_id;
                this.loadingSaveButton = true;
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
      
                axios({
                method: "POST",
                url: "/api/auth/book-rental-vehicle",
                data:this.user,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    if(response.data.flag){

                            this.isDark = true;
                            this.isDisable = false;
                            this.loadingSaveButton = false;
                            this.user = {
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone: ""
                              };
                              this.bookingDialog = false;
                              this.bookingConfirmDialog = true;
                              
                      }
                })
                .catch(err => {
                  console.log(err);
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    
                });

            }
  },
nextSlide(index) {
  if(index != this.videos.length - 1 )
    this.index = index + 1
  else
    this.index = 0
},
gotopayemnt: function () {
    let self = this;
    self.$router.push('/card-payment/'+this.$route.params.id);
},
getUserLocation() {
      if (localStorage.getItem('userLocation')) {
        const storedLocation = JSON.parse(localStorage.getItem('userLocation'));
        this.calculateDistance(parseFloat(storedLocation.latitude), parseFloat(storedLocation.longitude));
      } else if (window.isSecureContext && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;
            localStorage.setItem('userLocation', JSON.stringify({ lat: userLat, lng: userLng }));
            this.calculateDistance(userLat, userLng);
          },
          error => {
            this.geoError = "Geolocation request denied. Please enable location access.";
          }
        );
      } else {
        this.geoError = "Geolocation requires HTTPS. Please access the site securely.";
      }
  },
  calculateDistance(lat, lng) {
      const toRad = (angle) => (angle * Math.PI) / 180;
      const R = 3958.8; // Radius of the Earth in miles
      const lat1 = parseFloat(this.vehicle.latitude);
      const lon1 = parseFloat(this.vehicle.longitude);
      const lat2 = lat;
      const lon2 = lng;
      
      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) *
          Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      this.distance = (R * c).toFixed(2);
    },
checkSocial(social){
  if(!this.company.social_flags)
    return true;
  let social_flags = JSON.parse(this.company.social_flags);
  if(social_flags[social] == 1)
    return true;
  return false;
},
formatDate(date) {
      if (date) {
        const [year, month, day] = date.split('-');
        this.formattedStartDate = `${month}/${day}/${year}`;
      }
    },
  formatDate2(date) {
      if (date) {
        const [year, month, day] = date.split('-');
        this.formattedEndDate = `${month}/${day}/${year}`;
      }
  },
getSubdomain() {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    
    // Assuming the domain is of the form subdomain.example.com
    if (domainParts.length > 2) {
        return domainParts[0];
    } else {
        return null; // No subdomain
    }
}
,
 getInventory(id){
  
  axios({
          method: "POST",
           
          url: "/api/get-inventory-user",
          data: {
            id: id
          }
   
        })
          .then(async(response) => {
            if(response.data.flag == 1){
                this.reps_users = response.data.user;
                this.reps_flag = true;
                this.loader = false

            }else{
              this.$router.push('/login');
            }
          })

},
async copyURL() {
  console.log(window.location);
      try {
        await navigator.clipboard.writeText(window.location);
        alert('URL Copied, Now you can share the URL');
      } catch($e) {
        alert('Cannot copy');
      }
    },
checkFormSignup(){
  let flag = 1;
      if (!this.email) {
        Vue.$toast.error('Email is required', {
            position: 'top-right'
        });
        this.$refs['email'].focus()
        flag = 0;
      }
      if (!this.password && flag == 1) {
        Vue.$toast.error('Password is required', {
            position: 'top-right'
        });
        this.$refs['password'].focus()
        flag = 0;
      }

      if (flag == 1) {
        localStorage.setItem('temp_email',this.email)
        localStorage.setItem('temp_pass',this.password)
        localStorage.setItem('email_verified',0)
        if(this.company)
          this.$router.push('/signup/'+this.url)
        else
          this.$router.push('/signup')
      }
},
getStyle(flag){
  let self = this;
  let color = this.vcard.color;
  let font = this.vcard.header_color;
  let color_code = '';
  const colorArray = [
  { name: 'Orange', code: '#FD7E14' },
  { name: 'Green', code: '#28A745' },
  { name: 'Purple', code: '#6F42C1' },
  { name: 'Red', code: '#DC3545' },
  { name: 'Pink', code: '#E83E8C' },
  { name: 'Blue', code: '#007BFF' },
  { name: 'Dark Blue', code: '#0033cc' },
  { name: 'Black', code: '#000000' },
  { name: 'Silver', code: '#C0C0C0' },
  { name: 'Hot Pink', code: '#FF69B4' },
  { name: 'Flat Black', code: '#2D3436' }
];
colorArray.forEach(element => {
  if(element.name == color){
    color_code = element.code;
    self.color = color_code;
  }
});
if(flag == 'footer'){
  return 'background-color:'+color_code;
}
if(flag == 'button'){
  return color_code;
}

},
forgotPass(){
    this.buttonFlag =true;
    this.isDark = false;
    axios({
          method: "POST",
           
          url: "/api/auth/forgot-password",
          data: {
            email: this.email,
          }
        })
          .then(response => {
                this.sendFlag = 1;
                Vue.$toast.success('Forgot Password Mail sent', {
                position: 'top-right'
                });
                this.buttonFlag =false;
                this.isDark = true;
                this.pageFlag = 'login';
          })
          .catch(err => {
            Vue.$toast.error(err, {
            position: 'top-right'
            });
          })
},
gotosignup: function () {
    let self = this;
    self.$router.push('/signup');
},gotoGoogle(){
      let self = this;
        this.$router.push('/redirect/google');
        location.reload();
  },
  gotoMs(){
      let self = this;
        this.$router.push('/redirect/microsoft');
        location.reload();
  },
  gotoYahoo(){
      let self = this;
        this.$router.push('/redirect/yahoo');
        location.reload();
  },
  callNumber(phoneNumber) {
      window.location.href = `tel:${phoneNumber}`;
    },
    sendEmail(email) {
      window.location.href = `mailto:${email}`;
    },
    gotoLinks(link){
      if(!link)
      link = '#'
      const newTab = window.open('', '_blank');
      newTab.location.href = link;
    },
    getCompanyInfo(id){
  
  axios({
          method: "POST",
           
          url: "/api/get-card",
          data: {
            id: id
          }
   
        })
          .then(async(response) => {
            if(response.data.flag == 1){
              if(id == 'reps'){
                
                this.reps_users = response.data.user;
                this.reps_flag = true;

              }else{
                this.company = response.data.user;
                this.vcard = response.data.vcard[0];
                this.sales_agent_count = response.data.sales_agents
                this.ref_agent_count = response.data.ref_agents
                // this.background =   this.base_url_img+'vcard/'+this.company.background
                // this.company.items = JSON.parse(JSON.parse(this.company.items))
                if(this.company.video_background){
                  let video_back = this.company.video_background.split("/");
                  this.vimeo_id = video_back[0]
                  this.vimeo_account = video_back[1]
                }
                let url = process.env.VUE_APP_WASBI_URL.replace('/logo','');
                const{base64Image, mimeType} = await this.convertImageToBase64(this.base_url_img+this.company.avatar);
                this.base64Image = base64Image
                this.avatar_mimeType = mimeType
              }
            }else{
              this.$router.push('/login');
            }
          })

},
renderChart() {
  let self = this;
      const ctx = document.getElementById('chart-interest').getContext('2d');
       const innerLabel = {
        id: 'innerLabel',
        afterDatasetDraw(chart, args, pluginOptions) {
          const { ctx } = chart;
          const meta = args.meta;
          const xCoor = meta.data[0].x;
          const yCoor = meta.data[0].y;
          const perc = chart.data.datasets[0].data[0] / meta.total * 100;
          ctx.save();
          ctx.textAlign = 'center';
          ctx.font = '22px sans-serif';
          ctx.fillText('$'+self.monthly_payment+'/month', xCoor, yCoor);
          ctx.restore();
        },
      };
      this.chartInstance = new Chart(ctx, {
        type: 'doughnut',
        plugins: [innerLabel],
        data: {
          labels: ['Principal','Interest Paid'],
          datasets: [
            {
              data: [self.total_loan_amount.toFixed(0),self.total_interest],
              backgroundColor: ['#005CB2','#36A2EB'],
              hoverBackgroundColor: [ '#005CB2','#36A2EB'],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: `${chart.data.labels[i]} $${data}`,
              fillStyle: datasets[0].backgroundColor[i],
              index: i
            }))
          }
        }
      }
          },
          centerText: 'aaaa',
        },
      });
      // var text = "82%",
      //       textX = Math.round((width - this.chart.ctx.measureText(text).width) / 2),
      //       textY = height / 2;

      //   this.chartInstance.ctx.fillText(text, textX, textY);
    },
getCardDetail() {
  
        const domain = window.location.host
        let flag = 1;
        let header = { headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }
            }
            //this.queryAndIndeterminate();
            const id = this.$route.params.id;
          axios
            .post("/api/get-vehicle/"+id,{domain: domain},header)
            .then(response => {
                let data = response.data;
    
               if (flag == 1) {
                  this.vehicle = data.data;   
                  this.booked_dates = data.bookedDates;               
                  this.calculator.total_amount = this.vehicle.amount;
                  this.calculator.down_payment = this.vehicle.amount ? (this.vehicle.amount * 0.1).toFixed(2) : 0;
                  this.calculator.credit_range = '781 - 850';
                  this.setCreditRange()
                  this.getUserLocation();
                  this.loader = false
                  // this.renderChart();
                  //this.loaderOff();
                  //this.getGHLChat()
                }else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
    
         },
    addContact() {
      const contact = {
        name: "John Doe",
        phone: "123-456-7890",
        email: "johndoe@example.com",
        address: "1234 Example St, Anytown, USA",
      };

      if ('contacts' in navigator && 'ContactsManager' in window) {
        navigator.contacts.select(
          ['name', 'email', 'tel', 'address'],
          { multiple: false }
        ).then((contacts) => {
          const newContact = new Contact({
            name: [contact.name],
            tel: [{ value: contact.phone }],
            email: [{ value: contact.email }],
            address: [{ value: contact.address }],
          });
          newContact.save();
        }).catch((error) => {
          console.error('Error adding contact:', error);
        });
      } else {
        alert(`Name: ${contact.name}\nPhone: ${contact.phone}\nEmail: ${contact.email}\nAddress: ${contact.address}`);
      }
    },
    currencyFormat(value){
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(value);
          },
          openGallery(item){
            this.dialogPreviewFile = true
            this.selectedFile = item
          },
      getPreApproved() {
      this.$router.push('/agreement');
    },
    setCreditRange(){
      if(this.calculator.credit_range == '< 500')
        this.calculator.interest_rate = this.company_settings.Interest_rate_1
      if(this.calculator.credit_range == '501 - 600')
        this.calculator.interest_rate = this.company_settings.Interest_rate_2
      if(this.calculator.credit_range == '601 - 660')
        this.calculator.interest_rate = this.company_settings.Interest_rate_3
      if(this.calculator.credit_range == '661 - 780')
        this.calculator.interest_rate = this.company_settings.Interest_rate_4
      if(this.calculator.credit_range == '781 - 850')
        this.calculator.interest_rate = this.company_settings.Interest_rate_5
      
    },
    disableBookedDates(date) {
      return !this.booked_dates.includes(date); // Disable if date exists in bookedDates array
    },
    getSettings() {
    let flag = 1;
    let url = process.env.VUE_APP_WASBI_URL
    var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
      axios({
          method: "POST",
          url: "/api/get-company-settings",
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.company_settings = data.settings
               localStorage.setItem('settings', JSON.stringify(data.settings));
               if(data.settings.backgroung_logo)
                localStorage.setItem('admin_bg', JSON.stringify(url+data.settings.backgroung_logo));
              else
                localStorage.setItem('admin_bg','');
              this.getCardDetail();
            }


         })
          .catch(err => {
            // Vue.$toast.error(err, {
            // position: 'top-right'
            // });

           });

     },
     checkPermission(flag) {
     
      if(flag == 'sales'){
        let status = 0;
        if(this.company_settings.sales_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.sales_rep_flag == 'All'){
          return true
        }else{
          if(this.company_settings.sales_rep_accounts.length != 0){
          this.company_settings.sales_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status = 1;
            }
            
          });
        }
          return status
        }
      }
      if(flag == 'ref'){
        let status_ref = 0;
        if(this.company_settings.ref_rep_flag == 'None'){
          return false
        }
        else if(this.company_settings.ref_rep_flag == 'All'){
          return true
        }else{
          this.company_settings.ref_rep_accounts.forEach(val => {
            if(val == this.company.user_id){
              status_ref = 1;
            }
            
          });
          return status_ref
        }
      }
     },
  exportToFile(card, name='contact', force=true) {
            const a = document.createElement('a');
            const file = new Blob([card], { type: 'text/vcard'});

            a.href = URL.createObjectURL(file);
            a.download = name;
            a.click();

            URL.revokeObjectURL(a.href);
  },
  getIcon(id,value){
    if(id == 'Phone'){
      this.phone = value
      return 'fa fa-phone';
    }
    if(id == 'Zoom'){
      this.zoom_value = value
      return 'fas fa-video';
    }
    if(id == 'Event'){
      this.event_value = value
      return 'fas fa-calendar-check';
    }
    if(id == 'Email')
      return 'fas fa-envelope';
    if(id == 'Whatsapp')
      return 'fab fa-whatsapp';
    if(id == 'SMS/Text')
      return 'fas fa-comments';
    if(id == 'Website')
      return 'fas fa-link';
    if(id == 'Instagram')
      return 'fab fa-instagram';
    if(id == 'Snapchat')
      return 'fab fa-snapchat';
    if(id == 'Twitter')
      return 'fab fa-twitter';
    if(id == 'Facebook')
      return 'fab fa-facebook';
    if(id == 'LinkedIN')
      return 'fab fa-linkedin';
    else
      return 'fas fa-link';
  },
      // Function to convert image URL to Base64
  async convertImageToBase64(imageUrl) {
    let self = this;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const mimeType = blob.type; // Get the MIME type of the image
    // this.avatar_mimeType = mimeType;
    // this.base64Image = blob
    // const reader = new FileReader();
    //     reader.onloadend = () => { self.base64Image = reader.result.split(',')[1]; self.avatar_mimeType = mimeType }; // Get Base64 string without the prefix
    //     reader.onerror = reject;
    //     reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve({ base64Image: reader.result.split(',')[1], mimeType }); // Get Base64 string without the prefix
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
},
calculateTotalLoan(){
  this.total_loan_amount = this.calculator.total_amount - this.calculator.down_payment - this.calculator.trade_in;
  const monthlyInterestRate = this.calculator.interest_rate / 100 / 12;

  // The number of payments is now directly passed as `tenureMonths`
  let tenureMonths = 0;
  if(this.calculator.tenure == 0)
    tenureMonths = 24;
  if(this.calculator.tenure == 1)
    tenureMonths = 36;
  if(this.calculator.tenure == 2)
    tenureMonths = 48;
  if(this.calculator.tenure == 3)
    tenureMonths = 60;
  if(this.calculator.tenure == 4)
    tenureMonths = 72;
  if(this.calculator.tenure == 5)
    tenureMonths = 84;
  const totalPayments = tenureMonths;

  // Calculate the monthly payment using the formula
  const monthlyPayment = this.total_loan_amount * (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
      (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

  // Calculate total amount paid over the loan term
  const totalPaid = monthlyPayment.toFixed(0) * totalPayments;

  // Calculate total interest paid
  const totalInterest = totalPaid - this.total_loan_amount;
  this.total_interest = totalInterest.toFixed(0);
  this.monthly_payment = monthlyPayment.toFixed(0);
  if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  this.renderChart()
  
  
},
/**
 * checkForm
 * login the user with valid email/pass
 */
async addCard(value) {
            const myVCard = new VCard()
            myVCard.addName(this.company.first_name+' '+this.company.last_name)
            if(this.company.business_name)
              myVCard.addCompany(this.company.business_name)
            myVCard.addEmail(this.company.email)
            myVCard.addPhoneNumber(this.company.phone_m)     
            
            // Add social media links if available
            if (this.company.facebook) {
                myVCard.addSocial(this.company.facebook, 'Facebook');
            }
            if (this.company.instagram) {
                myVCard.addSocial(this.company.instagram, 'Instagram');
            }
            if (this.company.linkedin) {
                myVCard.addSocial(this.company.linkedin, 'TikTok');
            }
            if (this.company.website) {
                myVCard.addSocial(this.company.website, 'Website');
            }
            if (this.company.youtube) {
                myVCard.addSocial(this.company.youtube, 'YouTube');
            }
            if (this.company.avatar) {
              if (this.avatar_mimeType == 'image/png') {
                myVCard.addPhoto(`${this.base64Image}`, 'PNG');
              }
              else if (this.avatar_mimeType == 'image/jpeg') {
                myVCard.addPhoto(`${this.base64Image}`, 'JPEG');
              }
              else if (this.avatar_mimeType == 'image/gif') {
                myVCard.addPhoto(`${this.base64Image}`, 'GIF');
              }
            }
            const output = myVCard.toString()
            const preElement = document.querySelector('#output')
            const outputNode = document.createTextNode(output)
            this.exportToFile(output,'contact',true)
          
    }
  },

  computed: {
    filteredUsers() {
      const query = this.search_rep.toLowerCase();
      return this.reps_users.filter(user => {
        const fullName = `${user.year} ${user.make} ${user.model}`.toLowerCase();
        return fullName.includes(query);
      });
    },
    totalAmount() {
      if (!this.tripStartDate || !this.tripEndDate) return 0; // Prevents errors if dates are empty

      const startDate = new Date(this.tripStartDate);
      const endDate = new Date(this.tripEndDate);

      // Ensure endDate is after startDate
      if (endDate < startDate) return 0;

      // Calculate total days (including both start and end dates)
      const totalDays = Math.round((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;

      return totalDays * this.vehicle.rent_price_per_day;
    }

  },
  watch: {
    pageTitle() {
      this.$meta().refresh();
    },
    tripStartDate(val) {
      this.formatDate(val);
    },
    tripEndDate(val) {
      this.formatDate2(val);
    }
  },
    
};
</script>
<style>
  .check-login .v-label {
    color: white;
  }
  .check-login .v-icon {
    color: white;
  }
.input-login .v-icon{
    color: #FDE038!important;
}
.input-login .v-label{
  color: #FDE038!important;
}
.input-login #input-6{
  color: #FDE038!important;
}
.input-login #input-10{
  color: #FDE038!important;
}
.header-card{
  overflow: hidden;
  position: relative;
  z-index: 3;
  border-bottom: 1px solid rgb(217, 218, 220);
  color: rgb(20, 20, 20);
  background: linear-gradient(45deg, rgb(255, 117, 140), rgb(255, 126, 179));
}
.cards{
  box-sizing: border-box;
  position: relative;
  display: block;
  padding: 80px 10px 30px;
  background-color: rgb(230, 230, 230);
  border-bottom: 1px solid rgb(217, 218, 220);
  text-align: center;
  cursor: pointer;
  user-select: none;
  color: rgb(20, 20, 20);
}
.cards:nth-child(2n+1) {
  border-right: 1px solid rgb(217, 218, 220);
}
.cards:hover {
  color: skyblue!important;
  background: #D9E6F4;
}
.header {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  border: solid #fff;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
}
.footer {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  border: 0px;
  border-width: 0 1px;
  z-index: 1;
  background: white;
  font-weight: 700;
  width: 100%;
}
.elementor-container {
text-align: center;
}
.elementor-column {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
}
.elementor-column-header {
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: center;
  margin-bottom: -170px;
  margin-top: 50px;
}
.elementor-heading-title-card{
  color: #FFFFFF;
  font-family: "Montserrat", Sans-serif;
  font-weight: 800;
  text-shadow: 4px 4px 10px rgba(0,0,0,0.3);
}
.elementor-icon-wrapper{
  font-size: 25px;
  color: #000000;
}
.elementor-element.elementor-element-09cffa4 img {
  width: 200px;
  height: 200px;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
  border-color: #FFFFFF;
  border-radius: 300px 300px 300px 300px;
  
}
.elementor-element.elementor-element-a14cd74:not(.elementor-motion-effects-element-type-background), .elementor-581 .elementor-element.elementor-element-a14cd74 > .elementor-motion-effects-container > .elementor-motion-effects-layer {
  background: url("https://s3.us-central-1.wasabisys.com/quantumresolution/logo/david-bg.jpg") 50% 50%;
    background-size: auto;
  background-size: cover;
  padding: 60px 0px 70px 0px;
  position: relative;
}
.elementor-shape-bottom {
  bottom: -1px;
}
.elementor-shape {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  line-height: 0;
  direction: ltr;
}
.elementor-shape .elementor-shape-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}
.elementor-heading-title2 {
  color: #303030;
  font-family: "Lora", Sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}
 .elementor-social-icon {
  background-color: #021033;
}
.elementor-icon.elementor-social-icon {
  border-radius: 50%;
  font-size: 15px;
  line-height: 25px;
  width: 25px;
  display: inline-block;
  
  height: 25px;
}
.elementor-screen-only, .screen-reader-text, .screen-reader-text span, .ui-helper-hidden-accessible {
  position: absolute;
  top: -10000em;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.elementor-widget-container {
  text-align: center;
}
.elementor-social-icon i {
  color: #FFFFFF;
}
.elementor-grid-item{
  margin: 5px;
}
.slider {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slide.active {
  visibility: visible;
  opacity: 1;
}

iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container1 {
  display: flex;
  align-items: center;
  gap: 8px;
}
 .elementor-button {
  display: block;
  font-family: "Poppins", Sans-serif;
  font-size: 17px;
  font-weight: 400;
  text-transform: capitalize;
  fill: var( --e-global-color-6aef213 );
  color: var( --e-global-color-6aef213 );
  background-color: #FEFEFE;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: var( --e-global-color-6aef213 );
  border-radius: 50px 50px 50px 50px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.32);
  padding: 14px 50px 14px 50px;
}

.elementor-background-video-container {

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    transition: opacity 1s;
    pointer-events: none;

}
 .elementor-background-video-hosted {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.sub-slider .slick-list .slick-slide{
  padding-right: 5px;
}
.w-101{
  width: 98% !important;
}
.vehicle-details-module__content {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.25);
}
.v-btn-toggle .v-btn--active {
  color: #fff !important;
  background-color: #005CB2 !important;
}
</style>