<template>

          <div>
          
          <v-row class="no-gutters elevation-4">
            <v-col
            v-if="currentUserId == 1"
                          cols="12"
                          md="12"
                        >
                        <h5 class="text-h4 font-weight-bold text-typo px-5 py-5">Conversations <v-btn
                          color="blue"
                          dark
                          fab
                          bottom
                          style="float: right;"
                          small
                        >
                          <v-icon class="material-icons-round" @click="dialog = true">add</v-icon>
                        </v-btn></h5>
                        
                        </v-col>
                        <!-- Default Message When No Chats Exist -->
    <v-container  class="text-center py-10" v-if="chatData.length == 0">
        <p class="text-subtitle1 grey--text">No chats yet. Start a conversation!</p>
      </v-container>
                         
            <v-col
              cols="12" sm="3"
              class="flex-grow-1 flex-shrink-0"
              style="border-right: 1px solid #0000001f;"
              v-if="chatData.length > 0"
            >
              <v-responsive
                class="overflow-y-auto fill-height"
                height="100%"
              >
                <v-list subheader>
                  <v-list-item-group v-model="activeChatTab" v-if="chatData.length > 0">
                    <template v-for="(item, index) in chatData">
                      <v-list-item
                        :key="`parent${index}`"
                        :value="item.id"
                        @click="activeChat = item;fetchMessages(item.id)"
                      >
                        <!-- <v-list-item-avatar color="grey lighten-1 white--text">
                          <v-icon>
                            chat_bubble
                          </v-icon>
                        </v-list-item-avatar> -->
                        <v-avatar size="50" class="my-3 ms-2 mr-3" :color="item.type == 'Individual' ? 'blue' : 'green'">
                          <img v-if="item.users[0].avatar && item.type == 'Individual'" :src="IMAGE_URL+item.users[0].avatar" alt="Brooklyn" />
                          <span  class="white--text text-h5" v-else-if="item.type == 'Individual'">{{getShortName(getChatDisplayName(item))}}</span>
                          <v-icon v-else class="material-icons-round" color="white" size="30">
                            groups
                          </v-icon>
                          <!-- <img v-else :src="avatar" alt="Brooklyn" /> -->
                        </v-avatar>
                        <v-list-item-content>
                          <v-list-item-title v-text="getChatDisplayName(item)" />
                          <v-list-item-subtitle v-text="getChatDisplayEmail(item)" />
                        </v-list-item-content>
                        <v-list-item-icon style="margin-top: 28px;" v-if="item.unseen_count">
                         
                         <v-badge
                         bottom
              color="green"
              :content="item.unseen_count ? item.unseen_count : '0'"
            >
            </v-badge>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-divider
                        :key="`chatDivider${index}`"
                        class="my-0"
                      />
                    </template>
                    
                  </v-list-item-group>
                </v-list>
                
              </v-responsive>
            </v-col>
            <v-col
              cols="auto"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-responsive
                v-if="activeChat"
                class="overflow-y-hidden fill-height"
                height="100%"
              >
                <v-card
                  flat
                  class="d-flex flex-column fill-height"
                >
                  <v-card-title class="text-h5 text-bold">
                    {{getChatDisplayName(activeChat)}}
                  </v-card-title>
                  <p v-if="activeChat.type == 'Group'" class="ml-4">{{activeChat.users.map(user => user.first_name+" "+user.last_name).join(", ")}}</p>
                  <v-card-text class="flex-grow-1 overflow-y-auto">
                    <template v-for="(msg, i) in messages">
                        <div v-if="activeChat.type === 'Group' && msg.user_id !== currentUserId && shouldShowUserName(i)">
                            <strong class="ml-2 text-caption">{{ getUserName(msg.user_id) }}</strong>
                          </div>
                  
                      <div
                        :class="{ 'd-flex flex-row-reverse': msg.user_id == currentUserId }"
                      >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on }">
                            <v-hover
                              v-slot:default="{ hover }"
                            >
                              <v-chip
                                :color="msg.user_id == currentUserId ? 'blue' : 'green'"
                                dark
                                style="height:auto;white-space: normal;"
                                class="pa-4 mb-2"
                                v-on="on"
                              >
                                {{ msg.message }}
                                <sub
                                  class="ml-2"
                                  style="font-size: 0.5rem;"
                                >{{ formatMessageDateTime(msg.created_at) }}</sub>
                                <!-- <v-icon
                                  v-if="hover"
                                  small
                                >
                                  expand_more
                                </v-icon> -->
                              </v-chip>
                            </v-hover>
                          </template>
                          <!-- <v-list>
                            <v-list-item>
                              <v-list-item-title>delete</v-list-item-title>
                            </v-list-item>
                          </v-list> -->
                        </v-menu>
                      </div>
                    </template>
                  </v-card-text>
                  <v-card-text class="flex-shrink-1" v-if="chatData.length > 0">
                      <v-text-field
                      v-model="messageForm.content"
                      placeholder="Type a message"
                      type="text"
                      no-details
                      outlined
                      dense
                     
                      :append-outer-icon="loadingSendChat ? '' : 'fa-paper-plane'"
                      @keyup.enter="sendMessage(messageForm)"
                      @click:append-outer="sendMessage(messageForm)"
                      hide-details
                    ><template v-slot:append-outer>
                      <v-progress-circular
                        v-if="loadingSendChat"
                        indeterminate
                        size="20"
                        width="2"
                      ></v-progress-circular>
                    </template></v-text-field>
                  </v-card-text>
                </v-card>
              </v-responsive>
            </v-col>
          </v-row>
    
    
    
    
    
    
    
    
    
    
          
               <v-dialog
            persistent
          v-model="dialogAssign"
          width="500"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" v-if="!product.id">
              Add new Product
            </v-card-title>
            <v-card-title class="text-h5 grey lighten-2" v-else>
              Edit Product
            </v-card-title>
            <v-card-text style="margin-top:30px;"> 
              <v-text-field
                    v-model="product.name"
                    label="Name"
                    outlined
                    dense
              ></v-text-field>
              <v-divider class="mb-5"></v-divider>
              <div v-for="(link, i) in product.items" :key="i">
              <v-text-field
                    v-model="link.price"
                    label="Price"
                    prefix="$"
                    outlined
                    dense
                    v-mask="'###########################'"
              ></v-text-field>
                  <v-select
                  label="Type"
                  outlined
                    dense
                v-model="link.type"
                :items="['Subscription','Onetime']"
              > </v-select>
                              <v-select
                                label="Billing Period"
                                v-show="link.type=='Subscription'"
                                outlined
                                  dense
                              v-model="link.period"
                              :items="['Monthly','Every 3 months','Every 6 months','Yearly','Custom']"
                            ><template v-slot:append-outer>
                              <v-btn icon="fa-close" @click="remove(i)" v-if="i != 0">
                               
                                <v-icon  style="color:red!important;margin-bottom: 15px;" class="text-typo">fas fa-times-circle</v-icon>
                              </v-btn>
                              </template> </v-select>
    
                              <v-text-field
                                v-model="link.interval_length"
                                prefix="every"
                                dense
                                outlined
                                v-if="link.type=='Subscription' && link.period =='Custom'"
                                v-mask="'##'"
                          ><template v-slot:append-outer>
                            <v-select
                            outlined
                                v-show="link.type=='Subscription'"
                                  dense
                                  style="margin-top:-8px"
                              v-model="link.interval"
                              :items="['day','week','month']"
                            ></v-select>
                          </template></v-text-field>
              <v-divider class="mb-5"></v-divider>
              </div>
                            <v-btn
                          :ripple="false"
                          :elevation="0"
                          class="
                            font-weight-bold
                            text-xs
                            btn-default
                            bg-gradient-primary
                            py-5
                            px-6
                            mt-6
                            mb-5
                            me-2
                          "
                          color="blue"
                          @click="add()"
                        >
                          Add item
                        </v-btn>
                        <v-divider class="mb-5"></v-divider>
                        <v-checkbox
                              v-model="product.trial_flag"
                              :label="`Add free trial`"
                              style="margin-top: -15px;"
                            ></v-checkbox>
                            <v-text-field
                                v-model="product.trial"
                                label="Trial days"
                                v-show="product.trial_flag"
                                suffix="days"
                                outlined
                                dense
                                v-mask="'###########################'"
                          ></v-text-field>
                          <v-text-field
                                v-model="product.success_url"
                                label="Success URL"
                                outlined
                                dense
                          ></v-text-field>
              <v-file-input
              accept="image/*"
              label="Product Image"
              v-model="product.image"
              outlined
                    dense
            ></v-file-input>
              <v-switch
              label="Show to all client"
                v-model="product.all_account"
                inset
              ></v-switch>
              <v-select
              v-if="product.all_account == 0"
                v-model="product.list_account"
                :items="clients"
                chips
                item-text="name"
                item-value="user_id"
                label="Select users"
                multiple
                solo
              >
              <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            </v-select>
          
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              v-if="!product.id"
                color="#4caf50"
                text
                :disabled="loadingAssign"
                @click="addPromo()"
              >
                Submit
              </v-btn><v-btn
              v-else
                color="primary"
                text
                :disabled="loadingAssign"
                @click="editPromo()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                :disabled="loadingAssign"
                @click="dialogAssign = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
                                    v-model="dialog_basic"
                                    max-width="500px"
                                  >
                                    <v-card>
                                      <v-card-title>
                                        <span class="text-h5">{{ formTitle }}</span>
                                      </v-card-title>
    
                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-col
                                              cols="12"
                                              sm="12"
                                              md="12"
                                            >
                                              <v-text-field
                                                v-model="editedItemBasic.item"
                                                label="Enter Item"
                                              ></v-text-field>
                                            </v-col>
                                            
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
    
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="closeBasic"
                                        >
                                          Cancel
                                        </v-btn>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="saveBasic(selected_item)"
                                        >
                                          Save
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                  <v-dialog
                                    persistent
                                  v-model="dialogAssign2"
                                  width="500"
                                >
    
                                  <v-card>
                                    <v-card-title class="text-h5 grey lighten-2">
                                      Assign to client
                                    </v-card-title>
    
                                    <v-card-text style="margin-top:30px;"> 
                                      <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                                            <span style="color:#5D6167;">Clients</span>
                                            <!-- <v-select
                                                        item-text="name"
                                                        placeholder="Select clients"
                                                        item-value="id"
                                                        :disabled="loadingAssign"
                                                        v-model="selectedCompany"
                                                        :items="clients"
                                                        ></v-select> -->
                                                        <v-select
                                                          v-model="selectedCompany"
                                                          :items="clients"
                                                          chips
                                                          item-text="name"
                                                          item-value="user_id"
                                                          label="Select users"
                                                          multiple
                                                          solo
                                                        >
                                                        <template v-slot:prepend-item>
                                                        <v-list-item>
                                                          <v-list-item-content>
                                                            <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                                                          </v-list-item-content>
                                                        </v-list-item>
                                                        <v-divider class="mt-2"></v-divider>
                                                      </template>
                                                      </v-select>
                                          
                                    </v-card-text>
    
                                    <v-divider></v-divider>
    
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="primary"
                                        text
                                        :disabled="loadingAssign"
                                        @click="changeCompany()"
                                      >
                                        Submit
                                      </v-btn>
                                      <v-btn
                                        color="primary"
                                        text
                                        :disabled="loadingAssign2"
                                        @click="dialogAssign = false"
                                      >
                                        Close
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                                <v-dialog
                                    persistent
                                  v-model="dialogAssign3"
                                  width="500"
                                >
    
                                  <v-card>
                                    <v-card-title class="text-h5 grey lighten-2">
                                      Thank you page
                                    </v-card-title>
    
                                    <v-card-text style="margin-top:30px;"> 
                                      <rotate-loader class="mt-3 mb-3" :loading="loadingAssign" color="#1D75F4"></rotate-loader>
                                            <v-text-field
                                              v-model="success_url"
                                              label="Page URL"
                                              outlined
                                              dense
                                        ></v-text-field>
                                        <!-- <v-checkbox
                                        v-model="success_url_existing"
                                        :label="`Update existing Payment links`"
                                        style="margin-top: -15px;"
                                      ></v-checkbox> -->
                                    </v-card-text>
    
                                    <v-divider></v-divider>
    
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        color="primary"
                                        text
                                        :disabled="loadingAssign"
                                        @click="changeSuccessURL()"
                                      >
                                        Submit
                                      </v-btn>
                                      <v-btn
                                        color="primary"
                                        text
                                        :disabled="loadingAssign2"
                                        @click="dialogAssign3 = false"
                                      >
                                        Close
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
    
    
        <v-dialog
            persistent
          v-model="dialogAddFA"
          width="500"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" v-if="!fa.id">
              Add new Focus Area
            </v-card-title>
            <v-card-title class="text-h5 grey lighten-2" v-else>
              Edit Focus Area
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
              <v-text-field
                    label="Name"
                    v-model="fa.name"
              ></v-text-field>
            
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              v-if="!fa.id"
                color="#4caf50"
                text
                :disabled="loadingAssign"
                @click="addFocusArea()"
              >
                Submit
              </v-btn> <v-btn
              v-else
                color="primary"
                text
                :disabled="loadingAssign"
                @click="editFocusArea()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                :disabled="loadingAssign"
                @click="dialogAddFA = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            persistent
          v-model="dialogAddL"
          width="500"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" v-if="!location.id">
              Add new Location
            </v-card-title>
            <v-card-title class="text-h5 grey lighten-2" v-else>
              Edit Location
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
    
              <vuetify-google-autocomplete
                                        id="map"
                                        v-model="location.name"
                                        label="Location"
                                        color="#e91e63"
                                         :rules="rules"
                                        class="font-size-input input-style"
                                        placeholder="Enter a location"
                                        v-on:placechanged="getAddressData"
                                        country="us"
                                    ></vuetify-google-autocomplete>
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              v-if="!location.id"
                color="#4caf50"
                text
                :disabled="loadingAssign"
                @click="addLocation()"
              >
                Submit
              </v-btn><v-btn
              v-else
                color="primary"
                text
                :disabled="loadingAssign"
                @click="editLocation()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                :disabled="loadingAssign"
                @click="dialogAddL = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            persistent
          v-model="dialogCustomLink"
          width="500"
        >
    
          <v-card>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
            <v-card-title class="text-h5 grey lighten-2" v-if="editFlag">
              Edit custom menu link 
            </v-card-title>
            <v-card-title class="text-h5 grey lighten-2" v-else>
              Create a custom menu link 
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
              <span style="color:#5D6167;">Link title</span>
              <v-text-field
                    v-model="link.title"
                    required
                    :rules="nameRules"
                    solo
              ></v-text-field>
              <!-- <span style="color:#5D6167;">Link icon</span>
              <v-dialog
                v-model="showDialog"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="700px"
                max-height="850px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="icon"
                    :prepend-inner-icon="selectedIcon"
                    :label="selectedIcon"
                    v-bind="attrs"
                    v-on="on"
                    color="deep-purple"            
                    readonly
                    solo  
                  >
                  </v-text-field>
                </template>
                <v-card>
                  <v-app-bar
                    color="white"
                    scroll-target="#scrolling-techniques-7"
                    elevation="0"
                  >
                    <v-text-field
                      v-model="searchIcon"
                      placeholder="Search icon"
                      prepend-icon="mdi-magnify"
                      type="text"
                      onautocomplete="off"
                      dense
                    />
                  </v-app-bar>
                  <v-sheet
                    id="scrolling-techniques-7"
                    class="overflow-y-auto"
                    max-height="600"
                  >
                    <v-col cols="12">
                      <v-btn
                        v-for="(item, i) in allIcons"
                        :key="i"
                        @click="saveIcon(item)"
                        color="white"
                        class="mr-2 mb-2 "
                        fab
                        small
                        depressed
                      >
                        <v-icon color="grey darken-3"> mdi-{{ item }} </v-icon>
                      </v-btn>
                    </v-col>
                    
                  </v-sheet>
                </v-card>
              </v-dialog> -->
              <span style="color:#5D6167;">URL</span>
              <v-text-field
                    v-model="link.url"
                    required
                    :rules="nameRules"
                    solo
              ></v-text-field>
              <v-switch
              label="Show link on sidebar"
                v-model="link.sidebar"
                inset
              ></v-switch>
              <v-switch
              label="Show to all accounts"
                v-model="link.all_account"
                inset
              ></v-switch>
              <v-select
              v-if="link.all_account == 0"
                v-model="link.list_account"
                :items="companies"
                chips
                item-text="name"
                item-value="id"
                label="Chips"
                multiple
                solo
              ></v-select>
              <v-switch
              label="Show to Dashboard"
                v-model="link.dashboard"
                inset
              ></v-switch>
              <v-switch
              label="Show to Client Profile"
                v-model="link.client"
                inset
              ></v-switch>
              <v-radio-group
                v-model="link.is_iframe"
                row
              >
                <v-radio
                  label="Open in iFrame"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="Open in a New Tab"
                  :value="0"
                ></v-radio>
              </v-radio-group>
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-if="!editFlag"
                :disabled="loadingCustomLink"
                @click="addLink()"
              >
                Submit
              </v-btn>
              <v-btn
                color="primary"
                text
                v-if="editFlag"
                :disabled="loadingCustomLink"
                @click="editLink()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                :disabled="loadingCustomLink"
                @click="dialogCustomLink = false"
              >
                Close
              </v-btn>
            </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDeleteBasic" max-width="500px">
                                    <v-card>
                                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteBasic">Cancel</v-btn>
                                        <v-btn color="blue darken-1" text @click="deleteItemConfirmBasic(selected_item)">OK</v-btn>
                                        <v-spacer></v-spacer>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
        <v-dialog
            persistent
          v-model="dialogCoupon"
          width="500"
        >
    
          <v-card>
            <v-card-title class="text-h5 grey lighten-2" v-if="!promo.id">
              Add new Coupon code
            </v-card-title>
            <v-card-title class="text-h5 grey lighten-2" v-else>
              Edit Coupon code
            </v-card-title>
    
            <v-card-text style="margin-top:30px;"> 
              <v-text-field
              label="Name"
                    v-model="promo.name"
                    :disabled="promo.id ? true : false"
              ></v-text-field>
                     <v-select
                                label="Type"
                                placeholder="Select type"
                                :disabled="loadingAssign"
                                v-model="promo.type"
                                :items="['Percentage','Fixed']"
                                ></v-select>
              <v-text-field
              label="Discount"
              v-if="promo.type == 'Fixed'"
                    v-model="promo.value"
                    prefix="$"
                    v-mask="'###########################'"
              ></v-text-field>
                  <v-text-field
                  label="Discount"
              v-if="promo.type == 'Percentage'"
                    v-model="promo.value"
                    suffix="%"
                    v-mask="'###'"
              ></v-text-field>
                     <v-select
                     label="Usage Limit"
                                placeholder="Select Limit"
                                v-model="promo.count"
                                :items="['Unlimited','1','2','5','10','15']"
                                ></v-select>
            </v-card-text>
    
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                v-if="!promo.id"
                :disabled="loadingAssign"
                @click="addCoupon()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                v-else
                :disabled="loadingAssign"
                @click="editCoupon()"
              >
                Submit
              </v-btn>
               <v-btn
                color="primary"
                text
                :disabled="loadingAssign"
                @click="dialogCoupon = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Chat Type Selection Dialog -->
        <v-dialog v-model="dialog" max-width="400">
          <v-card>
            <v-card-title class="text-h6">Start a new chat</v-card-title>
            <v-card-text>
              <v-btn block color="blue" dark class="mb-2" @click="openUserSelection('Individual')">
                <v-icon left>mdi-account</v-icon> Individual Chat
              </v-btn>
              <v-btn block color="green" dark @click="openUserSelection('Group')">
                <v-icon left>mdi-account-multiple</v-icon> Group Chat
              </v-btn>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="userDialog" max-width="500">
          <v-card>
            <v-card-title class="text-h6">
              Select {{ chatType === 'Individual' ? 'a user to initiate chat' : 'multiple users to initiate chat' }}
            </v-card-title>
            <v-card-text>
              <v-select
                    v-model="selectedUsers"
                    :items="clients"
                    chips
                    item-text="name"
                    placeholder="Select User"
                    item-value="user_id"
                    :multiple="chatType === 'Group'"
                    outlined
                dense
                  >
                  <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field v-model="searchTerm" placeholder="Search" @input="searchFruits"></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                </v-select>
              <!-- Dropdown for Single/Multiple User Selection -->
             
    
              <!-- Group Name Input (Only for Group Chat) -->
              <v-text-field
                v-if="chatType === 'Group'"
                v-model="groupName"
                label="Enter Group Name"
                outlined
                dense
                required
              ></v-text-field>
            </v-card-text>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="userDialog = false">Cancel</v-btn>
              <v-btn
                color="blue"
                dark
                @click="startChat"
              >
                Start Chat
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
    
    </template>
    
    <script>
    import { VueTelInput } from 'vue-tel-input'
    import HtmlEditor from "../HtmlEditor.vue";
    import Sortable from 'sortablejs';
    export default {
    components: {
          VueTelInput,HtmlEditor
      },
      data () {
    
          return {
            fname : '',
            lname : '',
            email : '',
            id: '',
            name:'',
            IMAGE_URL: process.env.VUE_APP_WASBI_URL,
            editedItemBasic: {
              item: '',
            },
            chatData : [],
            payment_gateway_options: [],
            phone:'',
            password : '',
            users : [],
            nameRules: [
            v => !!v || 'Field is required'
          ],
            valid: true,
            page:1,
            dialog: false,
          userDialog: false,
          chatType: "",
          searchQuery: "",
          loadingSendChat: false,
          groupName: "",
          selectedUsers: [],
          chats: [
            { name: "John Doe", type: "Individual" },
            { name: "Project Team", type: "Group" },
          ],
          users: [
            { name: "John Doe" },
            { name: "Jane Smith" },
            { name: "Alice Johnson" },
            { name: "Michael Brown" },
            { name: "David Wilson" },
            { name: "Sarah Lee" },
          ],
            promo: {
              name : '',
              type : 'Percentage',
              value : '',
              count : 1,
            },
            itemsPerPage:10,
            confpassword : '',
            dialogCoupon : false,
            activeChat: 1,
        parents: [
          {
            id: 1,
            title: "john doe",
            active: true
          },
          {
            id: 3,
            title: "scarlett",
            active: false
          },
          {
            id: 4,
            title: "scarlett",
            active: false
          },
          {
            id: 5,
            title: "scarlett",
            active: false
          },
          {
            id: 6,
            title: "scarlett",
            active: false
          },
          {
            id: 7,
            title: "scarlett",
            active: false
          },
          {
            id: 8,
            title: "scarlett",
            active: false
          },
          {
            id: 9,
            title: "scarlett",
            active: false
          },
          {
            id: 10,
            title: "scarlett",
            active: false
          },
          {
            id: 11,
            title: "scarlett",
            active: false
          },
          {
            id: 12,
            title: "scarlett",
            active: false
          },
          {
            id: 13,
            title: "scarlett",
            active: false
          },
          {
            id: 14,
            title: "scarlett",
            active: false
          }
        ],
        messages: [
          
        ],
        messageForm: {
          content: "",
          me: true,
          created_at: "11:11am"
        },
            role : '',
            searchTerm : '',
            headers_letter : [
               { text: 'Title', value: 'title',
                sortable: true },
              { text: 'Category', value: 'category',
                sortable: true },
              { text: 'Staus', value: 'status',
                sortable: true },
              { text: 'Created at', value: 'created_at',
                sortable: true,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: true,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: true,width: '200' },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },
            ],
            headers_fa : [
               { text: 'Name', value: 'name',
                sortable: true },
              { text: 'Created at', value: 'created_at',
                sortable: true,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: true,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: true,width: '200' },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },
            ],
             headers_l : [
               { text: 'Name', value: 'name',
                sortable: true },
              { text: 'Created at', value: 'created_at',
                sortable: true,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: true,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: true,width: '200' },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },
            ],
            datatable_options_letter: {},
            datatable_options_fa: {},
            datatable_options_l: {},
            dialogAddFA : false,
            
            dialogAddL : false,
            headers_coupons : [  { text: 'Name', value: 'name',
                sortable: true },
              { text: 'Type', value: 'type',
                sortable: true },
              { text: 'Value', value: 'value',
                sortable: true },
                 { text: 'Usage Limit', value: 'count',
                sortable: true },
              { text: 'Created at', value: 'created_at',
                sortable: true,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: true,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: true,width: '200' },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },],
            datatable_options_coupons : {},
            coupons : [],
            page_coupons : 1,
            page_fa : 1,
            page_l : 1,
            itemsPerPage_coupons :10,
            selectedCompany: [],
            currentUserId: localStorage.getItem('user_id'),
            dialogAssign3: false,
            page_letter : 1,
            itemsPerPage_letter: 10,
            dialogAssign: false,
            pageCount: 1,
            fa : {
              name : '',
              id : ''
            },
            location : {
              name : '',
              id : ''
            },
            pageCountLetter : 1,
            pageCountCoupons : 1,
            pageCountFA : 1,
            activeChatTab: 0,
            pageCountL : 1,
            pageLink:1,
            clients : [],
            pageCountLink: 1,
            dialogAssign2 : false,
            totalCount: 0,
            totalCountLink: 0,
            companyname : '',
            success_url : '',
            success_url_existing : false,
            previewbg:'',
            dialogDeleteBasic :  false,
            focus_area : [],
            headers_basic: [
              { text: 'Item', value: 'item' },
              { text: 'Actions', value: 'actions', sortable: false },
            ],
            dialog_basic: false,
            locations : [],
            sector : '',
            no_of_employee : '',
            showPassword : false,
            loadingAssign: false,
            limit: 10,
            limit_letter: 10,
            totalCountLetter : 0,
     
            totalCountFA : 0,
            totalCountL : 0,
            clientCopy : [],
            letters : [],
            dialogCustomLink: false,
            loadingCustomLink: false,
            phoneFlag:0,
            emailFlag:0,
            link: {
              title:'',
              icon: 'mdi-web',
              url: '',
              is_iframe: 1,
              sidebar:1,
              all_account: 1,
              list_account:[],
              dashboard : 0,
              client: 0
            },
            product: {
              price: 0,
              type :'Subscription',
              name : '',
              list_account : [],
              all_account : 0,
              image : [],
              trial_flag : 0,
              success_url : 'https://10kpages.com/',
              trial : 3,
              items :[
                {
                  price: 0,
                  type :'Subscription',
                  period : 'Monthly',
                  trial_flag : 0,
                  trial : 3,
                  interval_length : 1,
                  interval: 'month'
                }
              ]
            },
            country :'',
            datatable_options:{},
            otp_email:'',
            otp_phone:'',
            links: [],
            otp_phone_input:"",
            otp_email_input:"",
            user_phone:'',
            selectedItems: [],
            editFlag: false,
            companies: [],
            user_email:'',
            headers_jobs: [
            { text: 'Name', value: 'name',
                sortable: true },
              // { text: 'Price', value: 'price',
              //   sortable: true },
              
              { text: 'Type', value: 'type',
                sortable: true },
              { text: 'Created at', value: 'created_at',
                sortable: true,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: true,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: false },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },
            ],
            headers_links: [
              { text: 'Title', value: 'title',
                sortable: false },
              { text: 'URL', value: 'url',
                sortable: false },
              { text: 'Open Mode', value: 'is_iframe',
                sortable: false },
              { text: 'Created at', value: 'created_at',
                sortable: false,width: '200' },
              { text: 'Updated at', value: 'updated_at',
                sortable: false,width: '200' },
                { text: 'Edit', value: 'edit',
                sortable: false,width: '200' },
              { text: 'Delete', value: 'id',
                sortable: false,width: '50' },
            ],
            status:0,
            settings :{
                total_share:0,
                share_price: 0,
                percentage_increase: 0,
                total_shares_before_price_increase: 0
    
            },
            tab: null,
            showDialog: false,
          icon: '',
          searchIcon: '',
          selectedIcon: 'mdi-check-outline',
          allIcons: [
            "account",
            "account-alert",
            "account-box",
            "account-box-outline",
            "account-check",
            "account-circle",
            "account-key",
            "tooltip-account",
            "account-minus",
            "web",
            "account-multiple",
            "account-multiple-outline",
            "account-multiple-plus",
            "account-network",
            "account-outline",
            "account-plus",
            "account-remove",
            "account-search",
            "account-star",
            "account-switch",
            "airballoon",
            "airplane",
            "airplane-off",
            "alarm",
            "alarm-check",
            "alarm-multiple",
            "alarm-off",
            "alarm-plus",
            "album",
            "alert",
            "alert-box",
            "alert-circle",
            "alert-octagon",
            "alpha",
            "alphabetical",
            "amazon",
            "google-cloud",
            "ambulance",
            "android",
            "android-debug-bridge",
            "android-studio",
            "apple",
            "apple-finder",
            "apple-ios",
            "apple-safari",
            "apps",
            "archive",
            "arrange-bring-forward",
            "arrange-bring-to-front",
            "arrange-send-backward",
            "arrange-send-to-back",
            "arrow-all",
            "arrow-bottom-left",
            "arrow-bottom-right",
            "arrow-collapse",
            "arrow-down",
            "arrow-down-bold",
            "arrow-down-bold-circle",
            "arrow-down-bold-circle-outline",
            "arrow-down-bold-hexagon-outline",
            "arrow-expand",
            "arrow-left",
            "arrow-left-bold",
            "arrow-left-bold-circle",
            "arrow-left-bold-circle-outline",
            "arrow-left-bold-hexagon-outline",
            "arrow-right",
            "arrow-right-bold",
            "arrow-right-bold-circle",
            "arrow-right-bold-circle-outline",
            "arrow-right-bold-hexagon-outline",
            "arrow-top-left",
            "arrow-top-right",
            "arrow-up",
            "arrow-up-bold",
            "arrow-up-bold-circle",
            "arrow-up-bold-circle-outline",
            "arrow-up-bold-hexagon-outline",
            "at",
            "attachment",
            "auto-fix",
            "auto-upload",
            "baby",
            "backburger",
            "backup-restore",
            "bank",
            "barcode",
            "barley",
            "barrel",
            "basket",
            "basket-fill",
            "basket-unfill",
            "battery",
            "battery-10",
            "battery-20",
            "battery-30",
            "battery-40",
            "battery-50",
            "battery-60",
            "battery-70",
            "battery-80",
            "battery-90",
            "battery-alert",
            "battery-charging-100",
            "battery-charging-20",
            "battery-charging-30",
            "battery-charging-40",
            "battery-charging-60",
            "battery-charging-80",
            "battery-charging-90",
            "battery-minus",
            "battery-negative",
            "battery-outline",
            "battery-plus",
            "battery-positive",
            "battery-unknown",
            "beach",
            "beaker",
            "beaker-outline",
            "beer",
            "bell",
            "bell-off",
            "bell-outline",
            "bell-ring",
            "bell-ring-outline",
            "bell-sleep",
            "beta",
            "bike",
            "binoculars",
            "bio",
            "biohazard",
            "bitbucket",
            "black-mesa",
            "blinds",
            "block-helper",
            "blogger",
            "bluetooth",
            "bluetooth-audio",
            "bluetooth-connect",
            "bluetooth-settings",
            "bluetooth-transfer",
            "blur",
            "blur-linear",
            "blur-off",
            "blur-radial",
            "bone",
            "book",
            "book-multiple",
            "book-open",
            "book-variant",
            "bookmark",
            "bookmark-check",
            "bookmark-music",
            "bookmark-outline",
            "bookmark-plus",
            "bookmark-remove",
            "border-all",
            "border-bottom",
            "border-color",
            "border-horizontal",
            "border-inside",
            "border-left",
            "border-none",
            "border-outside",
            "border-right",
            "border-top",
            "border-vertical",
            "bowling",
            "box",
            "briefcase",
            "briefcase-check",
            "briefcase-download",
            "briefcase-upload",
            "brightness-1",
            "brightness-2",
            "brightness-3",
            "brightness-4",
            "brightness-5",
            "brightness-6",
            "brightness-7",
            "brightness-auto",
            "broom",
            "brush",
            "bug",
            "bulletin-board",
            "bullhorn",
            "bus",
            "cake",
            "cake-variant",
            "calculator",
            "calendar",
            "calendar-blank",
            "calendar-check",
            "calendar-clock",
            "calendar-multiple",
            "calendar-multiple-check",
            "calendar-plus",
            "calendar-remove",
            "calendar-text",
            "calendar-today",
            "camcorder",
            "camcorder-off",
            "camera",
            "camera-front",
            "camera-front-variant",
            "camera-iris",
            "camera-party-mode",
            "camera-rear",
            "camera-rear-variant",
            "camera-switch",
            "camera-timer",
            "candycane",
            "car",
            "car-wash",
            "carrot",
            "cart",
            "cart-outline",
            "cash",
            "cash-100",
            "cash-multiple",
            "cash-usd",
            "cast",
            "cast-connected",
            "castle",
            "cat",
            "cellphone",
            "cellphone-android",
            "cellphone-dock",
            "cellphone-iphone",
            "cellphone-link",
            "cellphone-link-off",
            "cellphone-settings",
            "chair-school",
            "chart-arc",
            "chart-areaspline",
            "chart-bar",
            "chart-histogram",
            "chart-line",
            "chart-pie",
            "check",
            "check-all",
            "checkbox-blank",
            "checkbox-blank-circle",
            "checkbox-blank-circle-outline",
            "checkbox-blank-outline",
            "checkbox-marked",
            "checkbox-marked-circle",
            "checkbox-marked-circle-outline",
            "checkbox-marked-outline",
            "checkbox-multiple-blank",
            "checkbox-multiple-blank-outline",
            "checkbox-multiple-marked",
            "checkbox-multiple-marked-outline",
            "checkerboard",
            "chevron-double-down",
            "chevron-double-left",
            "chevron-double-right",
            "chevron-double-up",
            "chevron-down",
            "chevron-left",
            "chevron-right",
            "chevron-up",
            "church",
            "city",
            "clipboard",
            "clipboard-account",
            "clipboard-alert",
            "clipboard-arrow-down",
            "clipboard-arrow-left",
            "clipboard-check",
            "clipboard-outline",
            "clipboard-text",
            "clippy",
            "clock",
            "clock-fast",
            "close",
            "close-box",
            "close-box-outline",
            "close-circle",
            "close-circle-outline",
            "close-network",
            "closed-caption",
            "cloud",
            "apple-icloud",
            "cloud-check",
            "cloud-circle",
            "cloud-download",
            "cloud-outline",
            "cloud-off-outline",
            "cloud-upload",
            "cloud-refresh",
            "cloud-lock",
            "cloud-lock-outline",
            "cloud-question",
            "cloud-tags",
            "cloud-print",
            "cloud-print-outline",
            "cloud-search",
            "cloud-search-outline",
            "code-array",
            "code-braces",
            "code-equal",
            "code-greater-than",
            "code-less-than",
            "code-less-than-or-equal",
            "code-not-equal",
            "code-not-equal-variant",
            "code-string",
            "code-tags",
            "codepen",
            "coffee",
            "coffee-to-go",
            "color-helper",
            "comment",
            "comment-account",
            "comment-account-outline",
            "comment-alert",
            "comment-alert-outline",
            "comment-check",
            "comment-check-outline",
            "comment-multiple-outline",
            "comment-outline",
            "comment-plus-outline",
            "comment-processing",
            "comment-processing-outline",
            "comment-remove-outline",
            "comment-text",
            "comment-text-outline",
            "compare",
            "compass",
            "compass-outline",
            "console",
            "content-copy",
            "content-cut",
            "content-duplicate",
            "content-paste",
            "content-save",
            "content-save-all",
            "contrast",
            "contrast-box",
            "contrast-circle",
            "cow",
            "credit-card",
            "credit-card-multiple",
            "crop",
            "crop-free",
            "crop-landscape",
            "crop-portrait",
            "crop-square",
            "crosshairs",
            "crosshairs-gps",
            "crown",
            "cube",
            "cube-outline",
            "cube-unfolded",
            "cup",
            "cup-water",
            "currency-btc",
            "currency-eur",
            "currency-gbp",
            "currency-inr",
            "currency-rub",
            "currency-try",
            "currency-usd",
            "cursor-default",
            "cursor-default-outline",
            "cursor-move",
            "cursor-pointer",
            "database",
            "database-minus",
            "database-outline",
            "database-plus",
            "debug-step-into",
            "debug-step-out",
            "debug-step-over",
            "decimal-decrease",
            "decimal-increase",
            "delete",
            "delete-variant",
            "deskphone",
            "desktop-mac",
            "desktop-tower",
            "details",
            "deviantart",
            "diamond",
            "dice-1",
            "dice-2",
            "dice-3",
            "dice-4",
            "dice-5",
            "dice-6",
            "directions",
            "disqus",
            "division",
            "division-box",
            "dns",
            "domain",
            "dots-horizontal",
            "dots-vertical",
            "download",
            "drag",
            "drag-horizontal",
            "drag-vertical",
            "drawing",
            "drawing-box",
            "drone",
            "dropbox",
            "drupal",
            "duck",
            "dumbbell",
            "earth",
            "earth-off",
            "eject",
            "elevation-decline",
            "elevation-rise",
            "elevator",
            "email",
            "email-open",
            "email-outline",
            "emoticon",
            "emoticon-cool",
            "emoticon-devil",
            "emoticon-happy",
            "emoticon-neutral",
            "emoticon-poop",
            "emoticon-sad",
            "emoticon-tongue",
            "engine",
            "engine-outline",
            "equal",
            "equal-box",
            "eraser",
            "escalator",
            "evernote",
            "exclamation",
            "exit-to-app",
            "export",
            "eye",
            "eye-off",
            "eyedropper",
            "eyedropper-variant",
            "facebook",
            "facebook-messenger",
            "factory",
            "fan",
            "fast-forward",
            "ferry",
            "file",
            "file-cloud",
            "file-delimited",
            "file-document",
            "file-excel-box",
            "file-find",
            "file-image",
            "file-multiple",
            "file-music",
            "file-outline",
            "file-pdf",
            "file-pdf-box",
            "file-powerpoint",
            "file-powerpoint-box",
            "file-presentation-box",
            "file-video",
            "file-word",
            "file-word-box",
            "film",
            "filmstrip",
            "filmstrip-off",
            "filter",
            "filter-outline",
            "filter-remove-outline",
            "filter-variant",
            "fire",
            "firefox",
            "fish",
            "flag",
            "flag-checkered",
            "flag-outline",
            "flag-triangle",
            "flag-variant",
            "flash",
            "flash-auto",
            "flash-off",
            "flashlight",
            "flashlight-off",
            "flip-to-back",
            "flip-to-front",
            "floppy",
            "flower",
            "folder",
            "folder-account",
            "folder-download",
            "folder-google-drive",
            "folder-image",
            "folder-lock",
            "folder-lock-open",
            "folder-move",
            "folder-multiple",
            "folder-multiple-image",
            "folder-multiple-outline",
            "folder-outline",
            "folder-plus",
            "folder-remove",
            "folder-upload",
            "food",
            "food-apple",
            "food-variant",
            "football",
            "football-helmet",
            "format-align-center",
            "format-align-justify",
            "format-align-left",
            "format-align-right",
            "format-bold",
            "format-clear",
            "format-color-fill",
            "format-float-center",
            "format-float-left",
            "format-float-none",
            "format-float-right",
            "format-header-1",
            "format-header-2",
            "format-header-3",
            "format-header-4",
            "format-header-5",
            "format-header-6",
            "format-header-decrease",
            "format-header-equal",
            "format-header-increase",
            "format-header-pound",
            "format-indent-decrease",
            "format-indent-increase",
            "format-italic",
            "format-line-spacing",
            "format-list-bulleted",
    
            "format-paint",
            "format-paragraph",
    
            "format-size",
            "format-strikethrough",
            "format-subscript",
            "format-superscript",
            "format-text",
            "format-textdirection-l-to-r",
            "format-textdirection-r-to-l",
            "format-underline",
            "format-wrap-inline",
            "format-wrap-square",
            "format-wrap-tight",
            "format-wrap-top-bottom",
            "forum",
            "forward",
            "fridge",
            "fullscreen",
            "fullscreen-exit",
            "function",
            "gamepad",
            "gamepad-variant",
            "gas-station",
            "gavel",
            "gender-female",
            "gender-male",
            "gender-male-female",
            "gender-transgender",
            "gift",
            "git",
            "github",
            "glass-flute",
            "glass-mug",
            "glass-stange",
            "glass-tulip",
            "glasses",
            "gmail",
            "google",
            "google-chrome",
            "google-circles",
            "google-circles-communities",
            "google-circles-extended",
            "google-circles-group",
            "google-controller",
            "google-controller-off",
            "google-drive",
            "google-earth",
            "google-glass",
            "google-maps",
    
            "google-play",
            "google-plus",
            "google-hangouts",
            "grid",
            "grid-off",
            "group",
    
            "guitar-pick",
            "guitar-pick-outline",
            "hand-pointing-right",
            "hanger",
    
            "harddisk",
            "headphones",
            "headphones-box",
            "headphones-settings"
          ],
    
    
          }
        },
        directives: {
        sortableDataTable: {
          bind (el, binding, vnode) {
            const options = {
              animation: 150,
              onUpdate: function (event) {
                vnode.child.$emit('sorted', event)
              }
            }
            Sortable.create(el.getElementsByTagName('tbody')[0], options)
          }
        }
      },
        methods: {
          saveOrder (event) {
          const movedItem = this.users.splice(event.oldIndex, 1)[0];
          this.users.splice(event.newIndex, 0, movedItem);
           axios({
              method: "POST",
               
              url: "/api/save-product-order",
              data : this.users,
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
                console.log('product order saved');
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
    
        startChat() {
          if(this.selectedUsers.length == 0){
                    Vue.$toast.error("Select atleast one user to initiate chat", {
                      position: 'top-right'
                      });
    
          }else{
          const chat_users = localStorage.getItem("user_id")
          
          const data = {
            user_ids: this.chatType === "Group" ? this.selectedUsers : [this.selectedUsers],
            type: this.chatType,
            group_name: this.groupName
          }
          data.user_ids.push(chat_users)
          axios({
              method: "POST",
               
              url: "/api/chats",
              data : data,
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
              this.dialog = false
              this.userDialog = false
              this.fetchChats()
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
              }
        },
        changeCompany(){
              this.loading = true
                  var r = confirm("Are you sure, You went to asign company?");
                  if(this.selectedItems.length == 0){
                    Vue.$toast.error("Select atleast one item", {
                      position: 'top-right'
                      });
                      r = false
                      this.loading = false
                  }
                if (r == true) {
                  if(!this.selectedCompany){
                          Vue.$toast.error('Select Company', {
                          position: 'top-right'
                          });
                      }else{
                        this.loadingAssign = true;
                        axios
                    .post("/api/product-client-assign",{ids : this.selectedItems, selectedCompany : this.selectedCompany},this.header)
                    .then(response => {
                        let data = response.data;
    
                        if (response.data) {
                            Vue.$toast.success('Client assigned to the product successfully', {
                                position: 'top-right'
                                });
                                this.dialogAssign2 = false;
                                this.loadingAssign = false;
                                this.getProducts();
                            }else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
    
                        })
                        .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                        })
                      }
                    
                }else{
                    return false;
                }
            },
            changeSuccessURL(){
              this.loading = true
                  var r = confirm("Are you sure, You went to change?");
                  if(this.selectedItems.length == 0){
                    Vue.$toast.error("Select atleast one item", {
                      position: 'top-right'
                      });
                      r = false
                      this.loading = false
                  }
                if (r == true) {
                  if(!this.success_url){
                          Vue.$toast.error('Select URL', {
                          position: 'top-right'
                          });
                      }else{
                        this.loadingAssign = true;
                        axios
                    .post("/api/change-success-url",{ids : this.selectedItems, success_url : this.success_url, success_url_existing : this.success_url_existing ? 1 : 0},this.header)
                    .then(response => {
                        let data = response.data;
    
                        if (response.data) {
                            Vue.$toast.success('Thank you page changed to the product successfully', {
                                position: 'top-right'
                                });
                                this.dialogAssign3 = false;
                                this.loadingAssign = false;
                                this.getProducts();
                            }else {
                                Vue.$toast.error(data.message, {
                                position: 'top-right'
                                });
                            }
    
                        })
                        .catch(err => {
                        Vue.$toast.error(err, {
                        position: 'top-right'
                        });
                        })
                      }
                    
                }else{
                    return false;
                }
            },
        saveOrderLink (event) {
          const movedItem = this.links.splice(event.oldIndex, 1)[0];
          this.links.splice(event.newIndex, 0, movedItem);
           axios({
              method: "POST",
               
              url: "/api/save-link-order",
              data : this.links,
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
                console.log('product order saved');
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        openAdd(){
          this.link = {
              title:'',
              icon: 'mdi-web',
              url: '',
              is_iframe: 1,
              sidebar:1,
              all_account: 1,
              list_account:[],
            };
          this.dialogCustomLink = true;
        },
        openEdit(item){
          
          this.editFlag = true;
          this.link = item;
          this.link.list_account = Array.isArray(this.link.list_account) ? this.link.list_account : JSON.parse(this.link.list_account)
          console.log(this.link);
          this.selectedIcon = item.icon;
          this.dialogCustomLink = true;
        },
        getSettings() {
        let flag = 1;
        var config = {headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache'}};
          axios({
              method: "POST",
               
              url: "/api/get-share-settings",
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
            })
            .then(response => {
                let data = response.data.data;
                if(data){
                  this.id = data.id;
                  this.settings = JSON.parse(data.settings);
                }
    
    
             })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
    
         },
         getShortName(name){
          return name
            .split(' ') // Split the name by spaces
            .map(word => word.charAt(0).toUpperCase()) // Get the first letter of each word and capitalize
            .join(''); // Join them together
         },
         productEditOpen(item){
          this.product = ''
          this.product = item
          this.product.all_account = this.product.all_account == 'all' ? true : false
          this.product.list_account = this.product.all_account != 'all' && this.product.list_account != '' ? JSON.parse(this.product.list_account)  : []
          this.product.image = this.product.image != '' ? this.product.image.name ? this.product.image : new File([this.product.image], this.product.image, {
                      type: "text/plain",
                    }) : '';
          console.log(this.product.items)
        this.product.items =  typeof this.product.items === 'object' ? this.product.items != null ? this.product.items : [] :  JSON.parse(this.product.items)
          this.dialogAssign=true
          console.log(this.product);
         },
         previewImageBG: function(event) {
          var input = event.target;
          console.log(input);
          if (input.files) {
            this.settings.backgroung_logo = input.files[0];
            var reader = new FileReader();
            reader.onload = (e) => {
              this.previewbg = e.target.result;
            }
            this.image=input.files[0];
            reader.readAsDataURL(input.files[0]);
          }
        },
         getAllCompanies(){
                let headers = {
                    Authorization: "Bearer " + localStorage.getItem('token')
                }
                axios
                .get("/api/get-all-company", {headers: headers})
                .then(response => {
                    this.companies = response.data.data;
                    this.companies.push({name: 'None',id:'None'})
                })
            },
        deleteItem(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .get("/api/delete-product-payment-link/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getProducts();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            deleteItemLocation(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .get("/api/delete-location/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getL();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            deleteItemConfirmBasic (plan) {
            this.product.items.splice(this.editedIndexBasic, 1)
            this.closeDeleteBasic()
          },
            deleteItemFA(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .get("/api/delete-focus-area/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getFA();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            deleteItemLetter(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .post("/api/delete-letter/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getLetters();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            searchFruits(e) {
              if (!this.searchTerm) {
                this.clients = this.clientCopy;
              }
              console.log(this.clientCopy);
              this.clients = this.clientCopy.filter((fruit) => {
                return fruit.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
              });
            },
            add () {
            this.product.items.push({
              price: 0,
              type :'Subscription',
              period : 'Monthly',
              trial_flag : 0,
              trial : 3,
                })
          },
          
          remove (index) {
              this.product.items.splice(index, 1)
          },
            deleteItemLink(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .get("/api/delete-link/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getLinks();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            deleteItemBasic (flag,item) {
            this.editedIndexBasic = this.product.items.indexOf(item)
            this.editedItemBasic = Object.assign({}, item)
            this.dialogDeleteBasic = true
          },
            closeBasic () {
            this.dialog_basic = false
            this.$nextTick(() => {
              this.editedItemBasic = Object.assign({}, {item : ''})
              this.editedIndexBasic = -1
            })
          },
            getAddressData: function (addressData, placeResultData, id) {
                this.location.name = addressData.name;
            },
            editItemBasic (flag,item) {
            this.editedIndexBasic = this.product.items.indexOf(item)
            this.editedItemBasic = Object.assign({}, item)
            this.dialog_basic = true
          },
            saveBasic (item) {
            if (this.editedIndexBasic > -1) {
              Object.assign(this.product.items[this.editedIndexBasic], this.editedItemBasic)
            } else {
              this.product.items.push(this.editedItemBasic)
            }
            this.closeBasic()
          },
          openUserSelection(type) {
          this.chatType = type;
          this.selectedUsers = [];
          this.groupName = "";
          this.dialog = false;
          this.userDialog = true;
        },
        toggleSelection(user) {
          if (this.chatType === "Individual") {
            this.selectedUsers = [user]; // Only one user allowed
          } else {
            const index = this.selectedUsers.findIndex(u => u.name === user.name);
            if (index === -1) {
              this.selectedUsers.push(user);
            } else {
              this.selectedUsers.splice(index, 1);
            }
          }
        },
            deleteItemCoupon(id){
           
              var r = confirm("Are you sure, You went to delete?");
              if (r == true) {
                axios
                  .get("/api/delete-coupon/"+id,this.header)
                  .then(response => {
                      let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Deleted successfully', {
                          position: 'top-right'
                          });
                        this.getCoupons();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                })
              }
            },
            getAllClients() {
              let headers = {
                  Authorization: "Bearer " + localStorage.getItem('token')
              }
              axios
             .get("/api/get-all-client", {headers: headers})
             .then(response => {
                  this.clients = response.data.data;
                  this.clientCopy = [...this.clients];
              })
            },
            fetchChats() {
              let self = this;
              let headers = {
                  Authorization: "Bearer " + localStorage.getItem('token')
              }
              axios
             .get("/api/chats/"+this.currentUserId, {headers: headers})
             .then(response => {          
                  self.chatData = response.data.data;
                  if(self.chatData.length > 0)
                    self.selectChat(self.chatData[0]);
              })
            },
        getPayments(){
                this.loading = true;
               
                axios({
              method: "GET",
               
              url: "/api/get-all-payment-gateway",
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                this.payment_gateway_options = response.data.data
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
            },
            getUserName(userId) {
            const user = this.activeChat.users.find((u) => u.user_id === userId);
            return user ? user.first_name+' '+user.last_name : "Unknown";
            },
            shouldShowUserName(index) {
            if (index === 0) return true;
            return this.messages[index].user_id !== this.messages[index - 1].user_id;
            },
        addPromo(){
            this.loadingAssign = true;
            if(!this.product.name){
              Vue.$toast.error('Name is required', {
                position: 'top-right'
                });
            }else if(!this.product.success_url){
              Vue.$toast.error('Success URL is required', {
                position: 'top-right'
                });
            }else{
              let flag = true;
              this.product.items.forEach(element => {
                if(element.type =='Subscription' && element.period =='Custom' && element.interval == 'month' && element.interval_length > 12){
                  flag = false
                  Vue.$toast.error('Billing period must be between 1 day and 1 year', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                }
              });
              if(flag){
            let form  = new FormData();
            form.append('name', this.product.name);
            form.append('price', this.product.price);
            form.append('image', this.product.image);
            form.append('type', this.product.type);
            form.append('trial', this.product.trial);
            form.append('success_url', this.product.success_url);
            form.append('trial_flag', this.product.trial_flag ? 1 : 0);
            form.append('items', JSON.stringify(this.product.items));
            form.append('list_account', JSON.stringify(this.product.list_account));
            form.append('all_account', this.product.all_account ? 'all' : '');
    
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/add-product-payment-link",
              data: form
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Product added successfully', {
                          position: 'top-right'
                          });
                          this.dialogAssign = false;
                          this.loadingAssign = false;
                          this.getProducts();
                          this.product.price = '';
                          this.product.credit = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('Unable to save', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
                }
              }
              
                
          },
          addFocusArea(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/add-focus-area",
              data: this.fa
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Focus Area added successfully', {
                          position: 'top-right'
                          });
                          this.dialogAddFA = false;
                          this.loadingAssign = false;
                          this.getFA();
                          this.fa.name = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          addLocation(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/add-location",
              data: this.location
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Location added successfully', {
                          position: 'top-right'
                          });
                          this.dialogAddL = false;
                          this.loadingAssign = false;
                          this.getL();
                          this.location.name = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          addCoupon(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/add-coupon-codes",
              data: this.promo
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Coupon added successfully', {
                          position: 'top-right'
                          });
                          this.dialogCoupon = false;
                          this.loadingAssign = false;
                          this.getCoupons();
                          this.promo = {
                                name : '',
                                type : 'Percentage',
                                value : '',
                                count : 1
                              };
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          editCoupon(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/edit-coupon/"+this.promo.id,
              data: this.promo
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Coupon edited successfully', {
                          position: 'top-right'
                          });
                          this.dialogCoupon = false;
                          this.loadingAssign = false;
                          this.getCoupons();
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active Coupon code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          editPromo(){
            this.loadingAssign = true;
            let form  = new FormData();
            form.append('name', this.product.name);
            form.append('price', this.product.price);
            if(this.product.image.length !=0 && this.product.image.type != 'text/plain')
              form.append('image', this.product.image);
            form.append('old_image', this.product.image.length!= 0 ? this.product.image.name : '');
            form.append('type', this.product.type);
            form.append('trial', this.product.trial);
            form.append('success_url', this.product.success_url);
            form.append('trial_flag', this.product.trial_flag ? 1 : 0);
            form.append('items', JSON.stringify(this.product.items));
            form.append('list_account', JSON.stringify(this.product.list_account));
            form.append('all_account', this.product.all_account ? 'all' : '');
    
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/edit-product-payment-link/"+this.product.id,
              data: form
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Product edited successfully', {
                          position: 'top-right'
                          });
                          this.dialogAssign = false;
                          this.loadingAssign = false;
                          this.getProducts();
                          this.product.price = '';
                          this.product.credit = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          editFocusArea(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/edit-focus-area/"+this.fa.id,
              data: this.fa
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Focus Area edited successfully', {
                          position: 'top-right'
                          });
                          this.dialogAddFA = false;
                          this.loadingAssign = false;
                          this.getFA();
                          this.fa.name = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          uploadLogoBG(){
          const data = new FormData();
          let url =process.env.VUE_APP_WASBI_URL
            data.append('file_logo', this.settings.backgroung_logo);
            axios({
              method: "POST",
              url: "/api/upload-logo-background-admin",
              headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')},
              data: data
              })
              .then(response => {
              Vue.$toast.success("Logo uploaded", {
                  position: 'top-right'
                  });
                  console.log();
                  this.settings.backgroung_logo = response.data.data
                  this.saveSettings()
                  localStorage.setItem('admin_bg',url+response.data.data)
              this.loadingUpload = false;
    
              })
              .catch(err => {
                  Vue.$toast.error("Invalid file to upload", {
                  position: 'top-right'
                  });
                  this.isDisableUpload = false;
                  this.isDarkUpload = true;
              });
          
        },
          editLocation(){
            this.loadingAssign = true;
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/edit-location/"+this.location.id,
              data: this.location
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Location edited successfully', {
                          position: 'top-right'
                          });
                          this.dialogAddL = false;
                          this.loadingAssign = false;
                          this.getL();
                          this.location.name = '';
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error('An active promotion code with `code: '+this.promo.name+'` already exists.', {
                  position: 'top-right'
                  });
                  this.loadingAssign = false;
                  })
              
                
          },
          getChatDisplayName(chat) {
          if (chat.type === "Individual") {
            const otherUser = chat.users.find(user => user.user_id !== this.currentUserId);        
            return otherUser ? otherUser.first_name + " " + otherUser.last_name : "Unknown User";
          }
          return chat.group_name;
        },
        fetchMessages(chatId) {
          axios.get(`/api/messages/${chatId}`,{headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }}).then(response => {
                      this.messages = response.data.data;
                      this.markSeenChat(chatId);
                    });
                    
          
        },
        async sendMessage() {
          if (!this.messageForm.content.trim()) return;
          this.loadingSendChat = true;
          await axios.post('/api/messages', {
            chat_id: this.activeChat.id,
            user_id: this.currentUserId,
            message: this.messageForm.content,
          },{headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    }});
    
          this.messageForm.content = '';
          this.fetchMessages(this.activeChat.id);
          this.loadingSendChat = false;
        },
        selectChat(chat) {
          this.activeChat = chat;
          this.activeChatTab = chat.id
          this.fetchMessages(chat.id);
        },
        getChatDisplayEmail(chat) {
          if (chat.type === "Individual") {
            const otherUser = chat.users.find(user => user.user_id !== this.currentUserId);        
            return otherUser ? otherUser.email : "Unknown User";
          }
          return chat.group_name;
        },
          formatMessageDateTime(timestamp) {
            if (!timestamp) return "";
    
            const date = new Date(timestamp);
            const now = new Date();
    
            const options = {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            };
    
            // Check if the message was sent today
            if (
              date.getDate() === now.getDate() &&
              date.getMonth() === now.getMonth() &&
              date.getFullYear() === now.getFullYear()
            ) {
              return `Today ${date.toLocaleTimeString("en-US", options)}`;
            }
    
            // Check if the message was sent yesterday
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            if (
              date.getDate() === yesterday.getDate() &&
              date.getMonth() === yesterday.getMonth() &&
              date.getFullYear() === yesterday.getFullYear()
            ) {
              return `Yesterday ${date.toLocaleTimeString("en-US", options)}`;
            }
    
            // If older, show date + time
            return date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            }) + ` ${date.toLocaleTimeString("en-US", options)}`;
          },
          addLink(){
            if(this.$refs.form.validate()){
            this.loadingCustomLink = true;
            let req = this.link;
            req.icon = this.selectedIcon;
            req.list_account = JSON.stringify(req.list_account)
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/add-cusom-link",
              data: req
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Custom Link added successfully', {
                          position: 'top-right'
                          });
                          this.dialogCustomLink = false;
                          this.loadingCustomLink = false;
                          this.getLinks();
                          this.link.title = '';
                          this.link.url= '';
                          this.link.list_account= [];
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                  this.loadingCustomLink = false;
                  })
            }
              
                
          },
          editLink(){
            if(this.$refs.form.validate()){
            this.loadingCustomLink = true;
            let req = this.link;
            req.icon = this.selectedIcon;
            req.list_account = JSON.stringify(req.list_account)
            axios({
              method: "POST",
             headers : {
                    Authorization:"Bearer " + localStorage.getItem('token')
                    },
               
              url: "/api/edit-custom-link",
              data: req
            })
            
              .then(response => {
                  let data = response.data;
    
                  if (response.data) {
                      Vue.$toast.success('Custom Link edited successfully', {
                          position: 'top-right'
                          });
                          this.dialogCustomLink = false;
                          this.loadingCustomLink = false;
                          this.getLinks();
                          this.link.title = '';
                          this.link.url= '';
                          this.link.list_account= [];
                      }else {
                          Vue.$toast.error(data.message, {
                          position: 'top-right'
                          });
                      }
    
                  })
                  .catch(err => {
                  Vue.$toast.error(err, {
                  position: 'top-right'
                  });
                  this.loadingCustomLink = false;
                  })
            }
              
                
          },
        getProducts(){
          this.loading = true;
                this.offset = (this.page - 1) * this.datatable_options.itemsPerPage
                this.selected_project = name;
                let req = {
                  limit : this.datatable_options.itemsPerPage,
                  offset : this.offset,
                  sort : this.datatable_options.sortBy,
                  sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : this.search
                }
                axios({
              method: "POST",
               
              url: "/api/get-product-payment-link",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                  console.log(data);
                    this.users = data.data;
                    this.pageCount = data.total_page
                    this.totalCount = data.count
                    console.log( this.users);
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        getFA(){
          this.loading = true;
                this.offset = (this.page - 1) * this.datatable_options_fa.itemsPerPage
                this.selected_project = name;
                let req = {
                  limit : this.datatable_options.itemsPerPage,
                  offset : this.offset,
                  sort : this.datatable_options.sortBy,
                  sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : ''
                }
                axios({
              method: "POST",
               
              url: "/api/get-focus-area",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                  console.log(data);
                    this.focus_area = data.data;
                    this.pageCountFA = data.total_page
                    this.totalCountFA = data.count
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        getL(){
          this.loading = true;
                this.offset = (this.page - 1) * this.datatable_options_l.itemsPerPage
                this.selected_project = name;
                let req = {
                  limit : this.datatable_options.itemsPerPage,
                  offset : this.offset,
                  sort : this.datatable_options.sortBy,
                  sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : ''
                }
                axios({
              method: "POST",
               
              url: "/api/get-location",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                  console.log(data);
                    this.locations = data.data;
                    this.pageCountL = data.total_page
                    this.totalCountL = data.count
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        getCoupons(){
          this.loading = true;
                this.offset = (this.page - 1) * this.datatable_options_coupons.itemsPerPage
                this.selected_project = name;
                let req = {
                  limit : this.datatable_options_coupons.itemsPerPage,
                  offset : this.offset,
                  sort : this.datatable_options_coupons.sortBy,
                  sortDesc : this.datatable_options_coupons.sortDesc && this.datatable_options_coupons.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : this.search
                }
                axios({
              method: "POST",
               
              url: "/api/get-coupon-codes",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                  console.log(data);
                    this.coupons = data.data;
                    this.pageCountCoupons = data.total_page
                    this.totalCountCoupons = data.count
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        async markSeenChat(chatId) {
        await axios.post(`api/messages/mark-seen/${chatId}/${this.currentUserId}`,{header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }});
        this.activeChat.unseen_count = 0;
        },
        getLinks(){
          this.loading = true;
                this.offset = (this.pageLink - 1) * 10
                this.selected_project = name;
                let req = {
                  limit : -1,
                  offset : this.offset,
                  sort : this.datatable_options.sortBy,
                  sortDesc : this.datatable_options.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : this.search
                }
                axios({
              method: "POST",
               
              url: "/api/get-custom-links",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                    this.links = data.data;
                    this.pageCountLink = data.total_page
                    this.totalCountLink = data.count
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        getLetters(){
          this.loading = true;
                var offset = (this.page_letter - 1) * 10
                let req = {
                  limit : this.limit_letter,
                  offset : offset,
                  sort : this.datatable_options_letter.sortBy,
                  sortDesc : this.datatable_options_letter.sortDesc && this.datatable_options.sortDesc[0] == false ? 'ASC'  :'DESC',
                  search : this.search_letter
                }
                axios({
              method: "POST",
               
              url: "/api/get-all-letter",
              data: req,
               header : { headers : {
                  Authorization:"Bearer " + localStorage.getItem('token')
                  },
                }
            })
                .then(response => {
                    
                    let data = response.data;
    
                if (response.data) {
                    this.letters = data.data;
                    this.pageCountLetter = data.total_page
                    this.totalCountLetter = data.count
                    this.loading = false;
                    }else {
                        Vue.$toast.error(data.message, {
                        position: 'top-right'
                        });
                    }
    
                })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
    
               });
        },
        closeDeleteBasic () {
            this.dialogDeleteBasic = false
            this.$nextTick(() => {
              this.editedItemBasic = Object.assign({}, {item:''})
              this.editedIndexBasic = -1
            })
          },
        saveSettings: function(e) {
    
            axios({
              method: "POST",
               
              url: "/api/update-setting-shares",
              data: {
                id: this.id,
                settings: this.settings
              }
            })
              .then(response => {
                let data = response.data;
                if (response.data) {
                      Vue.$toast.success('Settings saved', {
                        position: 'top-right'
                      })
                } else {
                    Vue.$toast.error(data.message, {
                    position: 'top-right'
                    });
                }
              })
              .catch(err => {
                Vue.$toast.error(err, {
                position: 'top-right'
                });
              });
        },
        saveIcon: function(icon) {
          this.icon = icon;
          this.selectedIcon = "mdi-" + icon;
          this.showDialog = false;
        }
    
     },
     
    watch: {
      dialogDeleteBasic (val) {
            val || this.closeDeleteBasic()
          },
        searchIcon: function(oldSearchIcon, newSearchIcon) {
        let filteredIcons = [];
        if (this.searchIcon.length === 0) {
          filteredIcons = this.allIcons;
        } else {
          filteredIcons = this.allIcons.filter(i => i.includes(this.searchIcon));
        }
        this.allIcons = filteredIcons;
      },
      datatable_options: {
                    handler () {
                        console.log(this.datatable_options);
                    this.getProducts()
                    },
                    deep: true,
                },
      datatable_options_coupons : {
        handler () {
                    this.getCoupons()
                    },
                    deep: true,
      },
      datatable_options_fa : {
        handler () {
                    this.getFA()
                    },
                    deep: true,
      },
      datatable_options_l : {
        handler () {
                    this.getL()
                    },
                    deep: true,
      }
    },
    created() {
      if(!localStorage.getItem('token')){
        this.$router.push('/login')
        }else{
          this.getSettings()
          this.getAllClients()
          this.fetchChats()
        }
          
        },
    
    }
    </script>
    
    <style>
    .theme--light.v-tabs > .v-tabs-bar {
      background-color: transparent !important;
    }
    
    </style>