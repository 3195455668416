<template>
  <v-card
    class="card-shadow border-radius-xl"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div class="px-6 pt-4 pb-0 d-flex" v-if="!single">
      <v-avatar
        color="bg-gradient-success border-radius-xl mt-n8"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >search</v-icon
        >
      </v-avatar>
     <div class="d-flex align-items-center">
  <p class="font-weight-bold text-h6 mb-0 ms-3" :class="$route.name == 'VrDefault' ? 'text-white' : 'text-typo'">
    Top Searches
  </p>
  <v-select style="margin-top: -15px;margin-left: 15px" item-text="label"
  item-value="value" v-model="selectedFlag" :items="dateRangeOptions" @change="getData"></v-select>
</div>
    </div>
    <v-row>
      <v-col lg="4" md="4" v-if="!single">
        <v-card-text class="py-3" style="height: 400px;overflow-y: scroll">
          <v-list class="py-0 bg-transparent">
            <v-list-item
              v-for="(chat, index) in scans"
              :key="chat.state"
              class="px-4 py-1"
              :class="{ 'border-bottom': index != scans.length - 1 }"
            >
              <!-- <v-img
                :alt="`${chat.title} avatar`"
                :src="chat.avatar"
                max-width="20px"
                class="me-6"
              ></v-img> -->

              <v-list-item-content>
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  {{chat.title}}
                </span>
                <v-list-item-title
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                >{{ selectedFlag == 'States' ? chat.state : chat.ref }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                </span>
                <v-list-item-title
                  v-text="chat.total"
                  @click="getsearchhistoryBycount(chat.title)"
                  class="text-m mb-0 font-weight-bold cursor-pointer color-primary"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content>

              <!-- <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Value:
                </span>
                <v-list-item-title
                  v-text="chat.value"
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-content class="text-center">
                <span
                  class="text-xs font-weight-bold"
                  :class="
                    $route.name == 'VrDefault' ? 'text-white' : 'text-body'
                  "
                >
                  Bounce:
                </span>
                <v-list-item-title
                  v-text="chat.bounce"
                  class="text-sm mb-0"
                  :class="
                    $route.name == 'VrDefault'
                      ? 'text-white opacity-6'
                      : 'text-typo'
                  "
                ></v-list-item-title>
              </v-list-item-content> -->
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-col>
      <v-col :lg="single ? '12' : '8'" :md="single ? '12' : '8'">
        <div id="chartdivSearch" class="mt-5 mb-5 mt-lg-n4 w-100 h-100 overflow-hidden" style="height: 400px;width: 100%"></div>
      </v-col>
    </v-row>
    <v-dialog
        persistent
      v-model="openDialogCount"
      width="1000"
      class="dialog-vehicle-detail"
      style="background-color: #E0E0E0!important;"
    >

      <v-card >
      <v-card-title class="text-h5 grey lighten-2">
          Search Details ({{search_data.length}})
        </v-card-title>

        <v-card-body style="margin-top:30px;"> 
                    <v-simple-table class="table">
                  <template v-slot:default>
                    <thead >
                      <tr>
                        <th class="text-left">
                          Vehicle Name
                        </th>
                        <th class="text-left">
                          User Name
                        </th>
                        <th class="text-left">
                          User Email
                        </th>
                        <th class="text-left">
                          User Phone
                        </th>
                        <th class="text-left">
                          User Age
                        </th>
                        <th class="text-left">
                          Submitted At
                        </th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in search_data"
                        :key="item.category"
                      >
                        <td>{{ item.title }}</td>
                        <td>{{ item.first_name + " " + item.last_name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.age }}</td>
                        <td>{{ dateFormat(item.created_at) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                </v-card-body>
                <v-card-actions>
          <v-spacer></v-spacer>

           <v-btn
            color="primary"
            text
            @click="openDialogCount = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
  
</template>
<script>
import jsVectorMap from "jsvectormap";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/maps/world-merc";
import "jsvectormap/dist/css/jsvectormap.min.css";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import moment from 'moment';

export default {
  name: "sales-country",
  data() {
    return {
      selectedFlag: "this_week",
      countries: [],
      scans: [],
      xAxis: '',
      series: [],
      selected_search: '',
      openDialogCount: false,
      search_data: [],
      dateRangeOptions: [
        { label: 'This Week',      value: 'this_week' },
        { label: 'This Month',     value: 'this_month' },
        { label: 'Last Month',     value: 'last_month' },
        { label: 'Last 3 Months',  value: 'last_3_months' },
        { label: 'This Year',      value: 'this_year' },
        { label: 'Last 360 Days',  value: 'last_360_days' },
      ],
    };
  },
  methods: {
    dateFormat(date){
        return moment(date).format("MM/DD/YYYY");
      },
      getData(){
       axios({
          method: "POST",
          url: "/api/getsearchhistory",
          data: {
            date_range: this.selectedFlag,
          },
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.scans = data
              
              const dataFromApi = data;
              // dataFromApi might look like: [ { title: "2022 CHEVROLET", total: 15 }, ... ]

              // Transform data to amCharts-friendly format
              // Let's map title -> category, total -> value
              const chartData = dataFromApi.map(item => ({
                category: item.title,
                value: item.total
              }));

              // amCharts 5 likes to set data on axis and series
              this.xAxis.data.setAll(chartData);
              this.series.data.setAll(chartData);
            }
            })
    },
    getsearchhistoryBycount(search){
      this.openDialogCount = true
      axios({
          method: "POST",
          url: "/api/getsearchhistorybycount",
          data: {
            date_range: this.selectedFlag,
            search: search
          },
          headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
        })
        .then(response => {
            let data = response.data.data;
            if(data){
              this.search_data = data
            }
            })
    },
    createChart() {
      // Create root element
      this.root = am5.Root.new("chartdivSearch");

      // Set themes
      this.root.setThemes([am5themes_Animated.new(this.root)]);

      // Create an XY chart
      let chart = this.root.container.children.push(
        am5xy.XYChart.new(this.root, {
          panX: true,
          panY: true,
          wheelX: "panX",
          wheelY: "zoomX"
        })
      );

      // Create X-axis (Category)
      this.xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(this.root, {
          categoryField: "category",
          renderer: am5xy.AxisRendererX.new(this.root, {minGridDistance: 1})
        })
      );

      // Create Y-axis (Value)
      this.yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(this.root, {
          renderer: am5xy.AxisRendererY.new(this.root, {})
        })
      );
      this.xAxis.get("renderer").labels.template.setAll({
        rotation: -45,
        centerY: am5.p50,
        centerX: am5.p100
      });
      // Create series (ColumnSeries)
      this.series = chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: "Searches",
          xAxis: this.xAxis,
          yAxis: this.yAxis,
          valueYField: "value",
          categoryXField: "category",
          tooltip: am5.Tooltip.new(this.root, {labelText: "{valueY}"})
        })
      );

      // Optional: add a cursor for panning/zooming
      chart.set("cursor", am5xy.XYCursor.new(this.root, {}));

      // Store chart reference if you need it later
      this.chart = chart;
      this.getData();
    },
  },
  mounted() {
    // Group by state
    
    this.createChart();

    

  },
};
</script>
<style>
#chartdivSearch {
  max-width: 100%;
  height: 500px;
}
</style>
