<template>
  <v-card class="card-shadow border-radius-xl text-center" elevation="8">
    <v-card-text >
    <h1 class=" text-h6 text-typo mt-5" >Tell Us What Kind of Vehicle You're Looking For!</h1>
    <div class="mt-5">
        <div v-show="step == 1">
            <v-text-field   
                label="Year"
                color="black"
                outlined
                rounded
                v-mask="'####'"
                v-model="partner.year"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            <v-text-field   
                label="Make"
                color="black"
                outlined
                rounded
                style="margin-top: -15px;"
                v-model="partner.make"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            <v-text-field   
                label="Model"
                color="black"
                outlined
                rounded
                style="margin-top: -15px;"
                v-model="partner.model"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            <v-text-field   
                label="Trim "
                color="black"
                outlined
                rounded
                style="margin-top: -15px;"
                v-model="partner.trim"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            </div>
            <div v-show="step == 2">
            <v-text-field   
                label="Your First Name"
                color="black"
                outlined
                rounded
                v-model="partner.first_name"
                dense
                class="font-size-input input-style py-0 "
            ></v-text-field>
            <v-text-field
                label="Your Last Name"
                outlined
                rounded
                v-model="partner.last_name"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            <v-text-field
                label="Specify your Email, So we can contact"
                outlined
                rounded
                v-model="partner.email"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            <v-text-field
                label="Phone"
                outlined
                rounded
                v-mask="'(###) ###-####'"
                v-model="partner.phone"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field> 
            <v-text-field
                label="Your Age"
                outlined
                rounded
                v-mask="'##'"
                v-model="partner.age"
                dense
                style="margin-top: -15px;"
                class="font-size-input input-style py-0"
            ></v-text-field>
            
            <v-checkbox   style="margin-top: -15px;">
                <template v-slot:label>
                    <div>
                        I agree to
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <a
                            target="_blank"
                            href="/terms-and-conditions"
                            @click.stop
                            style="color:#FFCC00FF ;"
                            v-on="on"
                        >
                        terms & conditions
                        </a>
                        </template>
                        Opens in new window
                    </v-tooltip>
                    provided by the company. By providing my phone number, I agree to receive text messages from the business.
                    </div>
                </template>
                </v-checkbox>
                </div>
   </div>
        <v-btn
        v-show="step == 2"
                elevation="0"
                :ripple="false"
                height="43"
                rounded
                outlined
                :loading="isDisable"
                class="
                font-weight-bold
                text-uppercase
                btn-primary
                
                py-2
                px-6
                me-2
                mb-2
                w-100
                "
                :color="color"
                small
                @click="checkForm()"
                >Submit</v-btn
            >
            <v-btn
        v-show="step == 1"
                elevation="0"
                :ripple="false"
                height="43"
                rounded
                outlined
                :loading="isDisable"
                class="
                font-weight-bold
                text-uppercase
                btn-primary
                
                py-2
                px-6
                me-2
                mb-2
                w-100
                "
                :color="color"
                small
                @click="step = 2"
                >Next</v-btn
            >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
props: ["color","ref_id","id"],
emits: ["closeModalQuery"],
  data: () => ({
    partner: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        search: '',
        age: '',
        make: '',
        model: '',
        trim: '',
        year: '',
    },
    step: 1,
    isDisable: false

  }),
  methods: {
    checkForm(){
            let self = this;
            let flag = 1;            
            if (!this.partner.first_name) {
                Vue.$toast.error('First Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.last_name && flag == 1) {
                Vue.$toast.error('Last Name is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.make && flag == 1) {
                Vue.$toast.error('Make is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.model && flag == 1) {
                Vue.$toast.error('Model is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.year && flag == 1) {
                Vue.$toast.error('Year is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.trim && flag == 1) {
                Vue.$toast.error('Trim is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
            if (!this.partner.email && this.partner.is_email == false && flag == 1) {
                Vue.$toast.error('Email is required', {
                    position: 'top-right'
                });
                flag = 0;
            }
           
            

           
            let reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (reg.test(this.partner.email) == false && this.partner.is_email == false  && flag == 1) {
                Vue.$toast.error('Invalid email format', {
                    position: 'top-right'
                });
                flag = 0;
            }

            
            this.partner.user_name = this.partner.email;
            if (flag == 1) {
                this.loadingSaveButton = true;
                let is_verified = localStorage.getItem('email_verified') ? localStorage.getItem('email_verified') : 0
                this.partner.company_id = this.company ? this.company.id : '';
                this.partner.search = this.partner.year + ' ' + this.partner.make + ' ' + this.partner.model + ' ' + this.partner.trim;
                this.isDark = false;
                this.isDisable = true;
                const data = new FormData();
                for (var key in self.partner) {
                  data.append(key,self.partner[key]);
                }    
                data.append('ref_id', this.ref_id);            
                axios({
                method: "POST",
                url: "/api/auth/register-card-reffer",
                data:data,
                headers: {'Content-Type': 'application/json','Cache-Control' : 'no-cache, no-store, must-revalidate',Authorization:"Bearer " + localStorage.getItem('token')}
                })
                .then(response => {
                    console.log(response);
                    if(response.data.flag && response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                            this.isDark = true;
                            this.isDisable = false;
                            this.loadingSaveButton = false;
                      }else{
                        let result = response.data.data;
                        this.partner = {
                            first_name: '',
                            last_name: '',
                            email: '',
                            phone: '',
                        };
                        this.isDisable = false;
                        Vue.$toast.success("We received your query. We'll contact you soon", {
                            position: 'top-right'
                        });
                        this.$emit("closeModalQuery", 1);
                      }   
                })
                .catch(err => {
                  console.log(err);
                    // console.log(JSON.parse(err.response.data));
                    this.isDark = true;
                    this.isDisable = false;
                    this.loadingSaveButton = false;
                    if(err.response.data.flag){
                        if(err.response.data.flag == 2){
                            Vue.$toast.error('Company exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                        if(err.response.data.flag == 3){
                          Vue.$toast.error('Client exist with same email address!', {
                            position: 'top-right'
                            });
                        }
                    }else{
                      let data = self.isJson(err.response.data) ? JSON.parse(err.response.data) : (err.response.data);
                      if(data.email){
                          Vue.$toast.error(data.email[0], {
                          position: 'top-right'
                          });
                      }else if(data.password){
                          Vue.$toast.error(data.password[0], {
                          position: 'top-right'
                          });
                      }
                      else if(data.user_name){
                          Vue.$toast.error(data.user_name[0], {
                          position: 'top-right'
                          });
                      }
                      else{
                          Vue.$toast.error(data, {
                          position: 'top-right'
                          });
                      }
                  }
                    self.onCaptchaExpired()

                });

            }
        
    }
  }
}
</script>

<style>

</style>